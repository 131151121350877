.mheight-100{
	min-height: 100%;
}

ul {
	margin:0;
	padding:0;
	list-style: none;
}
a,
a:hover,
a:focus,
a:active {
	color: inherit;
	text-decoration: none;
}

body {
	background-color: #f2f4f4;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	padding-top: 90px;
	color: #2B2C33;
	font-size: 14px;
}
.body_home {
	padding-top: 70px;
}
body,
html {
	height: 100%;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
.left {
	float: left;
}
.right {
	float: right;
}
.cabecera-completa {
	margin-top: -20px;
	padding-top: 20px;
}
.cabecera_blanca {
	text-align: center;
	overflow: hidden;
}
hr {
	border-color: #DFE1E1;
}
.overflow_hidden {
	overflow: hidden;
}
.error {
	position: absolute;
	left: 0;
	top: 47px;
	z-index: 99;
	padding: 5px 10px;
	font-size: 11px;
	background: #ff7f7f;
}

.error::after {
	content: '';
	border-bottom: 10px solid #ff7f7f;
	border-right: 10px solid transparent;
	border-left: 10px solid transparent;
	position: absolute;
	left: 10px;
	top: -10px;
}
/*---------Tipografía---------*/
.robotoregular {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
}
.robotoblack {
	font-family: 'robotoblack', Arial, Helvetica, sans-serif;
}
.robotomedium {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
}
.robotobold {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
}
.robotolight {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
.robotolight_italic {
	font-family: 'robotolight_italic', Arial, Helvetica, sans-serif;
}
.roboto_c_light {
	font-family: 'roboto_condensedlight', Arial, Helvetica, sans-serif;
}
.roboto_c_regular {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
}
.roboto_c_bold {
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;

}
/*---------Fin Tipografía---------*/
.fs11 {
	font-size: 11px;
}
.fs12 {
	font-size: 12px;
}
.fs13 {
	font-size: 13px;
}
.fs14 {
	font-size: 14px;
}
.fs15 {
	font-size: 15px;
}
.fs16 {
	font-size: 16px;
}
.fs17 {
	font-size: 17px;
}
.fs18 {
	font-size: 18px;
}
.fs20 {
	font-size: 20px;
}
.fs21 {
	font-size: 21px;
}
.fs22 {
	font-size: 22px;
}
.fs23 {
	font-size: 23px;
}
.fs24 {
	font-size: 24px;
}
.fs25 {
	font-size: 25px;
}
.fs26 {
	font-size: 26px;
}
.fs28 {
	font-size: 28px;
}
.fs30 {
	font-size: 30px;
}
.fs36 {
	font-size: 36px;
}
.fs42 {
	font-size: 42px;
}
.fs50 {
	font-size: 50px;
	line-height: 50px;
}

.margen-especial {
	margin-left: 0;
	margin-right: 0;
	padding-right: 15px;
	padding-left: 15px;
}

.f-blanco {
	background-color: #fff !important;
}
.f-gris {
	background-color: #2B2E33;
}
.f-gris2 {
	background-color: #DFE1E1;
}
.f-gris3 {
	background-color: #CCCCCC;
}
.f-gris4 {
	background-color: #989898;
}
.f-gris5 {
	background-color: #F6F8F8;
}
.f-gris6 {
	background-color: #6D7076;
}
.f-gris7 {
	background-color: #999;
}
.f-gris8 {
	background-color: #ccc;
}

.f-rojo {
	background-color: #EF3724;
}
.f-verde {
	background-color: #00BF56;
}
.f-negro {
	background-color: #000;
}



.text-red {
	color: #EF3724;
}
.text-white {
	color: #fff;
}
.text-black {
	color: #000;
}
.text-gray {
	color: #6C7076;
}
.text-gray2 {
	color: #333;
}
.text-gray3 {
	color: #A5A5A7;
}



.btn-block + .btn-block {
	margin-top: 10px;
}
/*---------padding margin---------*/

.p10 {
	padding: 10px;
}
.p15 {
	padding: 15px;
}
.p20 {
	padding: 20px;
}

.pb5{
	padding-bottom: 5px;
}
.pb10{
	padding-bottom: 10px;
}
.pb20{
	padding-bottom: 20px;
}
.pb30{
	padding-bottom: 30px;
}
.pb40 {
	padding-bottom: 40px;
}


.pt10 {
	padding-top: 10px;
}
.pt20 {
	padding-top: 20px;
}
.pt30 {
	padding-top: 30px;
}


.pl15 {
	padding-left: 15px;
}
.pl20 {
	padding-left: 20px;
}
.pl40 {
	padding-left: 40px;
}
.pl50 {
	padding-left: 50px;
}

.pr20 {
	padding-right: 20px;
}

.pr40 {
	padding-right: 40px;
}

.mt10 {
	margin-top: 10px;
}
.mt15 {
	margin-top: 15px;
}
.mt20 {
	margin-top: 20px;
}
.mt20Im {
	margin-top: 20px !important;
}
.mt30 {
	margin-top: 30px;
}
.mt40 {
	margin-top: 40px;
}
.mt50 {
	margin-top: 50px;
}

.mb5 {
	margin-bottom: 5px;
}
.mb10 {
	margin-bottom: 10px;
}
.mb15 {
	margin-bottom: 15px;
}
.mb20 {
	margin-bottom: 20px;
}
.mb30 {
	margin-bottom: 30px;
}
.mb40 {
	margin-bottom: 40px;
}
.mb50 {
	margin-bottom: 50px;
}
.mb60 {
	margin-bottom: 60px;
}


.ml40 {
	margin-left: 40px;
}
/*---------Fin padding margin---------*/



/*---------Titulos---------*/
.titulo_1 {
	font-size: 28px;
	line-height: 32px;
	font-family: 'roboto_condensedlight', Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}
.titulo_1_2 {
	font-size: 28px;
	line-height: 32px;
	font-family: 'roboto_condensedlight', Arial, Helvetica, sans-serif;
	margin-bottom: 3px;
}

.titulo_2 {
	font-size: 24px;
	line-height: 28px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 20px;
}
.titulo_2_1 {
	font-size: 24px;
	line-height: 28px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}
.titulo_2_2 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
}
.titulo_2_3 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
}
.titulo_2_4 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 21px;
	line-height: 24px;
}
.titulo_3 {
	font-size: 24px;
	line-height: 28px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}
.titulo_4 {
	font-size: 16px;
	line-height: 20px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 10px !important;
}
.titulo_4_1 {
	font-size: 15px;
	line-height: 18px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}
.titulo_4_2 {
	font-size: 18px;
	line-height: 22px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}
.titulo_5 {
	font-size: 16px;
	line-height: 22px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.titulo_6 {
	font-size: 46px;
	line-height: 50px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 20px;
}
.titulo_7 {
	font-size: 46px;
	line-height: 50px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 20px;
}
.titulo_8 {
	font-size: 46px;
	line-height: 50px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 20px;
}
.subtitulo_1 {
	width: 90%;
	margin: 0 auto;
	font-size: 14px;
	line-height: 20px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	margin-bottom: 30px;
}
.subtitulo_1_1 {
	width: 100%;
	margin: 0 auto;
	font-size: 14px;
	line-height: 20px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	margin-bottom: 30px;
}
.subtitulo_2 {
	width: 90%;
	margin: 0 auto;
	font-size: 14px;
	line-height: 20px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 30px;
}
.subtitulo_3 {
	font-size: 18px;
	line-height: 22px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 1px;
}
.subtitulo_3_1 {
	width: 100%;
	margin: 0 auto;
	font-size: 14px;
	line-height: 20px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 40px;
}

/*---------Fin Titulos---------*/




/*---------cookies---------*/
.cookies {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	color: #fff;
	font-size: 14px;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.9);
	position: fixed;
	bottom: 0;
	z-index: 9999;
	padding-top: 60px;
}
.cerrar_cookies {
	position: absolute;
	top: 20px;
	right: 30px;
	text-align: center;
	line-height: 33px;
	font-size: 16px;
	float: right;
	width: 30px;
	height: 30px;
	background-color: #F23514;
	/*border-radius: 50%;*/
}
/*---------Fin cookies---------*/




/*---------Breadcrumb---------*/
.breadcrumb {
	padding: 8px 15px 8px 0;
	margin-bottom: 10px;
	line-height: 20px;
	background-color: inherit;
}
.breadcrumb a {
	color: #0081D5;
	text-decoration: underline;

}
.breadcrumb > .active {
	color: #6C7076;
}
.breadcrumb > li + li::before {
	font-family: 'Nucleo Glyph', Arial, Helvetica, sans-serif;
	padding: 0 5px;
	content: "\ea93";
	font-size: 10px;
}
/*---------Fin Breadcrumb---------*/

/*---------Calificación star---------*/
.star {
	width: 87px;
	margin: 0 auto;
	overflow: hidden;
}
.star a {
	float: right;
	color: #DFE1E1;
	cursor: default;
	background-image: url(../img/star0.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 16px;
	width: 17px;
}
.starmedium a {
	background-size: 17px;
	width: 19px;
}
.starbig a {
	background-size: 20px;
	width: 23px;
}
.star-rating a:hover {
	background-image: url(../img/star1.svg);
	cursor: pointer;

}
.star-rating a:hover ~ a {
	background-image: url(../img/star1.svg);
}

.valuehalf a:nth-child(5) {
	background-image: url(../img/starhalf.svg);
}
.value1 a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value1half a:nth-child(4) {
	background-image: url(../img/starhalf.svg);
}
.value1half a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value2 a:nth-child(4),
.value2 a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value2half a:nth-child(3) {
	background-image: url(../img/starhalf.svg);
}
.value2half a:nth-child(4),
.value2half a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value3 a:nth-child(3),
.value3 a:nth-child(4),
.value3 a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value3half a:nth-child(2) {
	background-image: url(../img/starhalf.svg);
}
.value3half a:nth-child(3),
.value3half a:nth-child(4),
.value3half a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value4 a:nth-child(2),
.value4 a:nth-child(3),
.value4 a:nth-child(4),
.value4 a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value4half a:nth-child(1) {
	background-image: url(../img/starhalf.svg);
}
.value4half a:nth-child(2),
.value4half a:nth-child(3),
.value4half a:nth-child(4),
.value4half a:nth-child(5) {
	background-image: url(../img/star1.svg);
}
.value5 a:nth-child(1),
.value5 a:nth-child(2),
.value5 a:nth-child(3),
.value5 a:nth-child(4),
.value5 a:nth-child(5) {
	background-image: url(../img/star1.svg);
}



/*---------Fin Calificación star---------*/

#tooltip
{
	text-align: center;
	color: #2B2C33;
	background: #FCF3F7;
	border: solid 1px #FBD7D8;
	position: absolute;
	z-index: 100;
	padding: 4px;
	font-size: 12px;
	margin: 0;
}


/*---------Botones---------*/
a:active, a:active * {
	outline: none !important;
	-moz-outline-style: none !important;
	color: inherit !important;
	box-shadow: none !important;
}
a:focus, a:focus * {
	outline: none !important;
	-moz-outline-style: none !important;
	text-decoration: none !important}

.btn {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	padding: 11px 12px;
	border-radius: 3px;
	border-color: #000000;
}
.btn-small {
	padding: 9px 12px !important;
}
.btn-small2 {
	padding: 5px 12px !important;
}
.btn-big {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	padding: 12px 12px !important;
}
.btn-big2 {
	padding: 14px 12px !important;
}
.btn-extra-big {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	padding: 18px 12px !important;
}
.link,
.link:hover,
.link:active,
.link:focus {
	color: #0081D5;
}
.link-underline,
.link-underline:hover,
.link-underline:active,
.link-underline:focus {
	color: #0081D5;
	text-decoration: underline;
}
.link-underline2,
.link-underline2:hover,
.link-underline2:active,
.link-underline2:focus {
	text-decoration: underline;
	display: inline-block;
}

.width120 {
	width: 120px;
}
.width140 {
	width: 140px;
}
.width150 {
	width: 150px;
}
.width170 {
	width: 170px;
}
.width180 {
	width: 180px;
}
.width200 {
	width: 200px;
}
.width220 {
	width: 220px;
}
.width240 {
	width: 240px;
}
.width260 {
	width: 260px;
}
.width280 {
	width: 280px;
	max-width: 280px;
}
.width310 {
	width: 100%;
	max-width: 310px;
}
.width410 {
	width: 100%;
	max-width: 410px;
}

.btn-black {
	color: #fff !important;
	background-color: #000;
	border-color: #000;
}

.btn-black:active,
.btn-black:focus,
.btn-black:hover {
	color: #fff !important;
	background-color: #000;
	border-color: #000;
}
.btn-gray {
	color: #2B2D33 !important;
	background-color: #F2F4F4;
	border-color: #F2F4F4;
}

.btn-gray:active,
.btn-gray:focus,
.btn-gray:hover {
	color: #2B2D33 !important;
	background-color: #F2F4F4;
	border-color: #F2F4F4;
}
.btn-red {
	color: #fff;
	background-color: #EF3724;
	border-color: #EF3724;
}
.btn-red:active,
.btn-red:focus,
.btn-red:hover {
	color: #fff !important;
	background-color: #EF3724;
	border-color: #EF3724;
}
.btn-green {
	color: #fff !important;
	background-color: #9AC100;
	border-color: #9AC100;
}

.btn-green:active,
.btn-green:focus,
.btn-green:hover {
	color: #fff !important;
	background-color: #9AC100;
	border-color: #9AC100;
}
.btn-facebook {

	color: #fff;
	background-color: #3B5997;
	border-color: #3B5997;
}
.btn-facebook img {
	width: 16px;
	margin-right: 10px;
	vertical-align: sub;
}
.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
	color: #fff !important;
	background-color: #3B5997;
	border-color: #3B5997;
}
.btn-white {
	color: #2B2D33;
	background-color: #fff;
	border-color: #DFE1E1;
}
.btn-white:active,
.btn-white:focus,
.btn-white:hover {
	color: #2B2D33 !important;
	background-color: #fff;
	border-color: #2B2D33;
}
.btn-trans {
	color: #2B2D33;
	background-color: transparent;
	border-color: #DFE1E1;
}
.btn-trans:active,
.btn-trans:focus,
.btn-trans:hover {
	color: #2B2D33 !important;
	background-color: transparent;
	border-color: #2B2D33;
}
.btn-trans1 {
	color: #6C7076;
	background-color: transparent;
	border-color: #DFE1E1;
}
.btn-trans1:active,
.btn-trans1:focus,
.btn-trans1:hover {
	color: #6C7076 !important;
	background-color: transparent;
	border-color: #2B2D33;
}
.btn-trans2 {
	color: #2B2D33;
	background-color: transparent;
	border-color: #2B2D33;
}
.btn-trans2:active,
.btn-trans2:focus,
.btn-trans2:hover {
	color: #2B2D33 !important;
	background-color: transparent;
	border-color: #2B2D33;
}
.btn-trans3 {
	color: #fff;
	background-color: transparent;
	border-color: #fff;
}
.btn-trans3:active,
.btn-trans3:focus,
.btn-trans3:hover {
	color: #fff !important;
	background-color: transparent;
	border-color: #fff;
}
.btn-filtra {
	padding: 6px 12px;
	color: #fff;
	background-color: transparent;
	border-color: #fff;
	border-radius: 0;
	width: 100%;
}
.btn-filtra:active,
.btn-filtra:focus,
.btn-filtra:hover {
	color: #fff !important;
	background-color: transparent;
	border-color: #fff;
}
.btn-carrito {
	padding: 0;
	line-height: 45px;
	height: 47px;
	color: #fff !important;
	background-color: #000;
	border-color: #000;
}
.btn-carrito:active,
.btn-carrito:focus,
.btn-carrito:hover {
	color: #fff !important;
}
.btn-carrito-desactivado {
	padding: 0;
	line-height: 45px;
	height: 47px;
	color: #ccc !important;
	background-color: #fff;
	border-color: #ccc;
	cursor: not-allowed;
}
.btn-carrito-desactivado:active,
.btn-carrito-desactivado:focus,
.btn-carrito-desactivado:hover {
	color: #ccc !important;
}
.btn-aviso_activado {
	border-color: #DFE1E1;
	font-size: 13px;
	background-color: #fff;
}
.doble_btn a {
	margin: 0 10px 10px 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
	.btn-aviso_activado {
		font-size: 12px;
	}
}

.btn-ok {
	color: #000;
	background-color: transparent;
	border-color: #000;
	padding: 0;
	height: 44px;
	font-size: 13px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	line-height: 16px;
	position: relative;
	text-align: left;
	padding-top: 4px;
}
.btn-ok:active,
.btn-ok:focus,
.btn-ok:hover {
	color: #000 !important;
	background-color: transparent;
	border-color: #000;
}
.btn-ok img {
	margin-right: 10px;
}
.centrado-ok {
	width: 180px;
	margin: 0 auto;
	background-image: url(../img/ok.svg);
	background-repeat: no-repeat;
	padding-left: 40px;
	background-position: center left;
}

/*---------Fin Botones---------*/

/*---------Paginado---------*/
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	z-index: 2;
	color: #6C7076;
	background-color: #fff;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: #000;
}
.pagination-foxize i {
	font-size: 30px;
	line-height: 16px;
}
.pagination-foxize > li > a,
.pagination-foxize > li > span {
	padding: 17px 0;
	width: 50px;
	height: 50px;
	text-align: center;
	font-size: 15px;
	line-height: 1;
	color: #6C7076;
	border-radius: 6px;
	border: none;
	margin-right: 10px;
}
.pagination-foxize > li:first-child > a,
.pagination-foxize > li:first-child > span {
	border-radius: 6px;

}
.pagination-foxize > li:last-child > a,
.pagination-foxize > li:last-child > span {
	border-radius: 6px;
	margin-right: 0;
}
/*---------Paginado---------*/




/*---------Espacios (margenes)---------*/
.espacio-bottom-bloque1 {
	margin-bottom: 20px;
}
.espacio-bottom-bloque2 {
	margin-bottom: 25px;
}
.espacio-bottom-bloque3 {
	margin-bottom: 20px;
}
.espacio-bottom-bloque4 {
	margin-bottom: 10px;
}
/*---------Espacios (margenes)---------*/



/*---------form-control---------*/
.form-control {
	height: 45px;
	border: 1px solid #dfe1e1;
	background-color: #F6F8F8;
	color: #2B2D33;
	border-radius: 3px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.form-control:focus {
	border-color: #EF3724;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(239, 55, 36, .6);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(239, 55, 36, .6);
}
.form-group ::-webkit-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #2B2D33; }
.form-group ::-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #2B2D33; }
.form-group :-ms-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #2B2D33; }
.form-group input:-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #2B2D33; }

.buscador .form-control,
.cont-menu .form-control {
	height: 37px;
	border: 1px solid #f2f4f4;
}
.buscador .form-control:focus,
.cont-menu .form-control:focus {
	border-color: #EF3724;
}
.bootstrap-select > .dropdown-toggle {
	height: 45px;
	border-color: #dfe1e1;
	background-color: #F6F8F8;
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
}
.form-control .selected {
	display: none;
}

/*---------Fin form-control---------*/


.check label {
	font-weight: normal;
	padding-left: 37px;
	position: relative;
}
.check input[type=checkbox] {
	display: none;
}
.check label:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	position: absolute;
	width: 25px;
	height: 25px;
	left: 0;
	margin-top: -2px;
	background-color: #fff;

	border: solid 1px #dfe1e1;
	border-radius: 3px;
}
.check input[type=checkbox]:checked + label:before {
	content: "\e66f";
	font-size: 25px;
	color: #2B2E33;
	text-align: center;
	line-height: 23px;
}

.check_small label {
	position: relative;
	font-weight: normal;
	padding-left: 28px;
	position: relative;
}
.check_small input[type=checkbox] {
	display: none;
}
.check_small label:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	position: absolute;
	width: 19px;
	height: 19px;
	left: 0;
	margin-top: 1px;
	background-color: #fff;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
}
.check_small input[type=checkbox]:checked + label:before {
	background-image: url(../img/check_black.svg);
	background-repeat: no-repeat;
	background-position: 3px 4px;
	position: absolute;
}


/*---------Slider home---------*/
.slider_home {
	overflow: hidden;
}
#owl-slider-home .item {
	height: 250px;
	color: #fff;
	font-size: 16px;
	text-align: center;
	padding-top: 40px;
}
#owl-slider-home h2 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 24px;
	line-height: 28px;
}
#owl-slider-home .owl-wrapper .owl-item {
	background-repeat: no-repeat;
	background-size: cover;
}
/*#owl-slider-home .owl-wrapper .owl-item:nth-child(1) {*/
	/*background-image: url(../img/sliderFoxize.jpg);*/
/*}*/
/*#owl-slider-home .owl-wrapper .owl-item:nth-child(2) {*/
	/*background-image: url(../img/slider_home2.png);*/
/*}*/
.btn-slider {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	background-color: inherit;
	font-size: 14px;
	padding: 7px 20px;
	border-radius: 6px;
	border: solid 1px #fff;
	margin: 10px 0 15px 0;
}
.btn-slider:hover {
	background-color: inherit;
	color: #fff;
}
.titu-slider {
	display: block;
	font-size: 24px;
	line-height: 24px;
	margin-bottom: 10px;
}
.sub-titu-slider {
	display: block;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 5px;
}
/*---------Fin Slider home---------*/

/*---------Slider home login---------*/
@media (min-width: 700px) {
	#owl-slider-home-login .item {
		height: 250px;
		color: #fff;
		font-size: 14px;
		text-align: center;
		padding-top: 70px;
	}
}
@media (min-width: 700px) {
	#owl-slider-home-login .item {
		color: #fff;
		font-size: 14px;
		text-align: center;
		padding-top: 70px;
	}
}
#owl-slider-home-login h2 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 24px;
	line-height: 28px;
	margin-bottom: 10px;
}
#owl-slider-home-login .owl-wrapper .owl-item {
	background-repeat: no-repeat;
	background-size: cover;
}
/*#owl-slider-home-login .owl-wrapper .owl-item:nth-child(1) {*/
	/*background-image: url(../img/sliderFoxize.jpg);*/
/*}*/
/*#owl-slider-home-login .owl-wrapper .owl-item:nth-child(2) {*/
	/*background-image: url(../img/sliderFoxize.jpg);*/
/*}*/
/*---------Fin Slider home login---------*/





/*---------Menu principal---------*/
.bars {
	position: absolute;
	display: block;
	width: 68px;
	height: 68px;
	border-right: solid 1px #f2f4f4;
	color: #000;
	cursor: pointer;
}
.bars:before {
	font-family: 'Nucleo Glyph', Arial, Helvetica, sans-serif;
	content: "\e6fa";
	font-size: 26px;
	width: 68px;
	height: 68px;
	display: block;
	text-align: center;
	line-height: 58px;
}
.bars-close {
	position: absolute;
	display: block;
	width: 69px;
	height: 69px;
	top: -1px;
	left: -1px;
	background-image:url(../img/simple-remove.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 40px;
	border-right: solid 1px #000;
	background-color: #000;
	cursor: pointer;
}
.busc-menu {
	background-image: url(../img/zoom.svg);
	background-repeat: no-repeat;
	background-position: 10px center;
	background-size: 16px;
	padding-left: 35px;
	margin: 0 15px 20px 15px;
	max-width: 230px;
	font-weight: normal;
}
.buscador ::-webkit-input-placeholder, .cont-menu ::-webkit-input-placeholder {	font-family: 'robotoregular', Arial, Helvetica, sans-serif; font-style:italic; font-size: 14px; color: #A5A5A7; }
.buscador ::-moz-placeholder, .cont-menu ::-moz-placeholder { font-family: 'robotoregular', Arial, Helvetica, sans-serif; font-style:italic; font-size: 14px; color: #A5A5A7; }
.buscador :-ms-input-placeholder, .cont-menu :-ms-input-placeholder { font-family: 'robotoregular', Arial, Helvetica, sans-serif; font-style:italic; font-size: 14px; color: #A5A5A7; }
.buscador input:-moz-placeholder, .cont-menu input:-moz-placeholder { font-family: 'robotoregular', Arial, Helvetica, sans-serif; font-style:italic; font-size: 14px; color: #A5A5A7; }
.cont-menu {
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
	font-size: 15px;
	position: absolute;
	min-width: 261px;
	background-color: #fff;
	top: 69px;
	left: -1px;
	padding: 20px 0 0 0;
}
.cont-menu {
	/*display: none;*/
}
.cont-menu input {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
}
.cont-menu ul {
	border-top: solid 1px #f2f4f4;
	margin-bottom: 20px;
	padding: 0 20px;
}
.cont-menu ul li {
	padding: 16px 0;
	border-bottom: solid 1px #f2f4f4;
}
.cont-menu ul li a {
	display: block;
	line-height: 21px;
	color: #000;
}
.cont-menu ul li a i {
	color: #EF3724;
	/*color: rgb(165,166,168);*/
	line-height: 21px;
	float: right;
}
.cont-menu ul li a.negro {
	color: #000;
}
.cont-menu ul li a.rojo {
	color: #EF3724;
}
.cont-menu ul li a i.gris {
	color: rgb(165,166,168);
}
.cont-menu ul li ul {
	display: none;
	border-top: none;
	margin-bottom: 10px;
	padding: 15px 0 0 20px;
}
.cont-menu ul li ul li {
	width: 221px;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 14px;
	border-bottom: none;
	padding: 8px 0;
}
.espacio {
	margin-top: 40px;
}
.cont-menu li:last-child {
	border-bottom: none;
}
.user-i {
	padding: 0;
	line-height: 38px;
}
.user-i i {
	font-size: 22px;
	font-weight: bold;
	padding-right: 10px;
	vertical-align: text-bottom;
}
.empresa {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	color: #2B2C33;
	font-size: 14px;
	text-align: center;
	margin-top: 40px;
	padding-top: 30px;
	padding-bottom: 10px;
	border-top: solid 1px #f2f4f4;
}
.cont-menu .estilo2 {
	border: none;
	padding: 7px 0;
}
.cont-menu .estilo2 a {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 14px;
}
.cont_btn_formacion {
	background-color: #F2F4F4;
	padding: 30px 20px;
}
/*---------Fin menu principal---------*/




/*---------Cabecera---------*/

.cabecera {
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 70px;
	border: solid 1px #f2f4f4;
	background-color: #fff;
	z-index: 99999;
	margin: 0 !important;
}
.cabecera a {
	text-decoration: none;
}
.menu-desplegable a,
.menu-desplegable a:hover {
	color: #000;
}
.menu-desplegable {
	position: relative;
}
.menu-desplegable:before {
	content: attr(title);
	position: absolute;
	font-size: 12px;
	top: 44px;
	left: 17px;
}

.logo {
	/*float: left;
	width: 156px;
	height: 66px;
	line-height:64px;
	margin-left: 53px;
	text-align: center;*/

	position: absolute;
	line-height:64px;
	text-align: center;
	width: 100%;
	left: 0;
	padding-right: 93px;
	padding-left: 68px;
	z-index: -1;
}
.menu-derecha-xs {
	float: right;
	border-left: solid 1px #f2f4f4;
	padding-left: 1px;
}
.telf-xs {
	font-size: 15px;
	float: left;
	height: 68px;
	line-height: 68px;
	color: #ef3724;
	padding-left: 18px;
	padding-right: 18px;
}
.telf-xs:hover, .telf-xs:focus {
	color: #EF3724;
}
.telf-xs img {
	vertical-align: -4px;
}
.btn-desplegable {
	height: 68px;
	line-height: 66px;
	float: left;
	padding-left: 18px;
	border-left: solid 1px #f2f4f4;
	margin-left: 2px;
}
.desplegable-xs {
	/*display: none;*/
	width: 150px;
	position: absolute;
	right: -160px;
	top: 8px;
	background-color: #fff;
	border: solid 1px #f2f4f4;
	/*-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);*/
}
.desplegable-xs a,
.desplegable-xs a:hover {
	color: #000;
}

.cerrar-desplegable-xs {
	display: block;
	height: 49px;
	line-height: 49px;
	text-align: right;
	/*border-bottom: solid 1px #f2f4f4;*/
	padding-right: 15px;
}
.favoritos-xs,
.carrito-xs,
.notificacion-xs {
	position: relative;
	float: left;
	width: 73px;
	height: 73px;
	line-height: 73px;
	text-align: center;
}

@media (max-width: 768px) {
	.shopping_favorites_shake {
		position: relative;
		float: left;
		width: 73px;
		height: 73px;
		line-height: 73px;
		text-align: center;
		font-size: 24px;
		line-height: 73px;
	}

	.shopping_favorites_shake.lleno{
		background-image: url(../img/favourite_lleno_i2co.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 40% 40%;
		position: relative;
		float: left;
		width: 73px;
		height: 73px;
		line-height: 73px;
		text-align: center;
		font-size: 24px;
	}
	.shopping_favorites_shake.lleno i{
		display: none;
	}
}


.notificacion-xs {
	border-top: solid 1px #f2f4f4;
}
.carrito-xs span,
.notificacion-xs span {
	position: absolute;
	top: 15%;
	left: 50%;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #EF3724;
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #fff;
	text-align: center;
	line-height: 23px;
}
.favoritos-xs i,
.carrito-xs i,
.notificacion-xs {
	font-size: 24px;
	line-height: 73px;
}

.carrito-xs {
	border-bottom: solid 1px #f2f4f4;
}
.img-usuario-xs {
	width: 75px;
	height: 73px;
	float: right;
	line-height: 68px;
	/*border-top: solid 1px #f2f4f4;*/
	text-align: center;
}
.img-usuario-xs img {
	width: 50px;
}
.cerrar-sesion {
	float: left;
	width: 100%;
	height: 40px;
	text-align: center;
}
.cerrar-sesion a {
	display: block;
	width: 110px;
	margin: 0 auto;
	border-top: solid 1px #f2f4f4;
	color: #A5A5A7 !important;
	padding: 5px 0 0 0;
}
.acceso-xs {
	float: left;
	width: 100%;
	text-align: center;
	border-top: solid 1px #f2f4f4;
	color: #2b2e33;
	padding: 10px 0;
}
.acceso-xs a {
	line-height: 41px;
	display: block;
	width: 70%;
	margin: 0 auto;
}
.acceso-xs a:nth-child(1) {
	border-bottom: solid 1px #f2f4f4;
}
.notific {
	margin-top:  -20px;
	border-bottom: solid 1px #DFE1E1;
	box-shadow: 0px 4px 5px -3px rgba(0,0,0,0.1);
	padding-bottom: 30px;
}
.resta_margen {
	margin-left: -15px;
	margin-right: -15px;
}
.desple-notificaciones {
	padding-top: 20px;
}
.desple-notificaciones h4 {
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
	font-size: 17px;
	padding-bottom: 10px;
	padding-top: 30px;
	padding-left: 10px;
}
.desple-notificaciones h4:first-child {
	padding-top: 0px;
}
.desple-notificaciones li {
	position: relative;
	border-bottom: solid 1px #DFE1E1;
	padding: 15px 10px 7px 10px;
}
.desple-notificaciones li:nth-child(2n+1) {
	background-color: #F6F8F8;
}
.desple-notificaciones li > span {
	font-size: 20px;
	position: absolute;
	right: 8px;
	display: block;
	z-index: 9;
}
.espacio-cerrar {
	padding-right: 20px;
}
.desple-notificaciones p {
	position: relative;
	margin-bottom: 8px;
}
.desple-notificaciones .fecha {
	padding-left: 32px;
}
.desple-notificaciones .fecha:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "\e669";
	font-size: 24px;
	color: #A5A6A8;
	position: absolute;
	left: 0;
	top: 6px;
}
/*---------Fin Cabecera---------*/



/*---------Bloque Por donde Empiezo---------*/
.num {
	width: 25px;
	height: 47px;
	margin: 0 auto 10px auto;
	border-bottom: 3px solid #EF3724;
	text-align: center;
	font-family: 'robotoblack', Arial, Helvetica, sans-serif;
	font-size: 42px;
	line-height: 42px;
	color: #EF3724;

}
.empiezo {
	font-size: 15px;
	text-align: center;
	padding-bottom: 30px;
	padding-top: 30px;
	border-bottom: solid 1px #F2F4F4;
}
.sub-titu {
	font-size: 17px;
	font-family: 'robotoblack', Arial, Helvetica, sans-serif;
}

/*---------Fin Bloque Por donde Empiezo---------*/




/*---------Bloque Temáticas---------*/
.tematicas h2 {
	font-size: 18px;
	line-height: 22px
}
.tematicas {
	padding-bottom: 40px;
}

@media (min-width: 800px) {
	.tematicas ul li {
		height: 30px;
		line-height: 30px;
		margin-bottom: 20px;
		display: inline-block; /*añadido*/
	}
	.tematicas ul {
		font-family: 'robotomedium', Arial, Helvetica, sans-serif;
		font-size: 15px;
		margin: 40px auto 60px 40px;
		overflow: hidden;
	}
}
.tematicas ul i {
	font-size: 30px;
	float: left;
	margin-right: 10px;
	color: #EF3724;
}
.tematicas ul li h3 {
	font-size: 15px;
}




@media (min-width: 500px) {

	.tematicas ul li {
		width: 50%;
	}
}
/*---------Fin Bloque Temáticas---------*/




/*---------Bloque Asesoramiento---------*/
.asesoramiento h2 {
	font-size: 24px;
	line-height: 26px;
}

.asesoramiento {
	text-align: center;
	padding-bottom: 40px;
}
.asesoramiento .parrafo {
	width: 90%;
	margin: 0 auto;
	font-size: 15px;
}
.asesoramiento .form-group {
	border-top: solid 1px #f2f4f4;
	margin-top: 10px;
	padding-top: 10px;
	margin-bottom: 20px;
}
.asesoramiento .form-group label {
	display: inherit;
	font-weight: inherit;
	max-width: 270px;
	margin: 0 auto 10px auto;
	position: relative;
}
.asesoramiento .form-group input {
	width: 100%;
}
.asesoramiento hr {
	width: 125px;
	margin: 0 auto 20px auto;
}
.asesoramiento .acepto_politica label {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-weight: normal;
	line-height: 25px;
	height: 25px;
	padding: 0;
}
.asesoramiento .acepto_politica input[type=checkbox] {
	display: none;
}
.asesoramiento .acepto_politica label:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	display: inline-block;
	width: 25px;
	height: 25px;
	margin-right: 10px;
	background-color: #f6f8f8;

	border: solid 1px #dfe1e1;
	border-radius: 3px;
	vertical-align: top;
}
.asesoramiento .acepto_politica input[type=checkbox]:checked + label:before {
	content: "\e66f";
	font-size: 25px;
	color: #2B2E33;
	text-align: center;
	line-height: 25px;
}
.sin_resultado {
	text-align: center;
}
.sin_resultado .busc2 input {
	max-width: 500px;
	margin: 0 auto;
	float: inherit;
	margin-bottom: 30px;
	font-size: 18px;
	background-image: url(../img/zoom.svg);
	background-repeat: no-repeat;
	background-position: 10px center;
	background-size: 16px;
	padding-left: 35px;
}


.sin_resultado .busc2::-webkit-input-placeholder { color: #2B2C33; }
.sin_resultado .busc2 ::-moz-placeholder { color: #2B2C33; }
.sin_resultado .busc2 :-ms-input-placeholder { color: #2B2C33; }
.sin_resultado .busc2 input:-moz-placeholder { color: #2B2C33; }

.asesoramiento_ok {
	display: none;
	margin-top: 10px;
	border-top: solid 1px #f2f4f4;
	padding-top: 150px;
	height: 336px;
	text-align: center;
	background-image: url(../img/ok.svg);
	background-repeat: no-repeat;
	background-position: center 120px;
}
.assesorament_registrat {
	font-size: 15px;
	border-top: solid 1px #f2f4f4;
	margin-top: 10px;
	padding-top: 20px;
}
.assesorament_registrat label {
	position: relative;
}
.column_asse_regis > li {
	width: 100%;
	display: inline-block;
	vertical-align: top;
}
.column_asse_regis img {
	vertical-align: bottom;
}
.column_asse_regis > li:nth-child(1) {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: solid 1px #f2f4f4;
}
.column_asse_regis .bootstrap-select {
	max-width: 320px;
}
.assesorament_registrat label  {
	width: 100%;
	max-width: 320px;
	display: block;
	margin: 0 auto 10px auto;
}
.assesorament_registrat label input {
	font-weight: normal;
}
.assesorament_registrat ::-webkit-input-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #000; font-size: 15px;}
.assesorament_registrat ::-moz-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #000; font-size: 15px;}
.assesorament_registrat :-ms-input-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #000; font-size: 15px;}
.assesorament_registrat input:-moz-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #000; font-size: 15px;}

.assesorament_registrat .filter-option {
	font-family: 'robotolight_italic', Arial, Helvetica, sans-serif;
	color: #000;
	font-size: 15px;
}
.telf2 img {
	margin-bottom: 5px;
}
.mensage_error,
.mensage_ok {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 16px;
	border-top: solid 1px #f2f4f4;
	padding: 60px 0;
	text-align: center;
	display: none;
}
.mensage_error img,
.mensage_ok img {
	vertical-align: top;
	margin-bottom: 20px;
}
/*---------Fin Bloque Asesoramiento---------*/



/*---------Bloque PorQue Foxize---------*/
.PQFoxize {
	overflow: hidden;
	font-size: 15px;
	color: #fff;
	background-color: #2B2E33;
	padding: 30px 15px;
}
.PQFoxize h3 {
	font-size: 17px;
	font-weight: bold;
	color: #EF3724;
}

.line-bottom {
	border-bottom: solid 1px #393C41;
}
.modulo-PQFoxize1, .modulo-PQFoxize2, .modulo-PQFoxize3 {
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;
	padding: 40px 0 10px 0;
}
.modulo-PQFoxize1, .modulo-PQFoxize2 {
	border-bottom: solid 1px #393C41;
}
.modulo-PQFoxize1 img, .modulo-PQFoxize2 img, .modulo-PQFoxize3 img {
	height: 90px;
	padding-bottom: 20px;
}
.PQFoxize .cont-btn {
	margin: 50px 0 0 0;
}
/*---------Fin Bloque PorQue Foxize---------*/




/*---------Footer---------*/
.espacio_before_footer {
	margin-bottom: 45px;
}
.espacio_before_footer2 {
	margin-bottom: 15px;
}
.contacto {
	font-size: 15px;
}
.l-contacto {
	display: block;
	margin-top: 40px;
}
.row-footer {
	margin-left: -15px;
	margin-right: -15px;
	padding: 20px 0 60px 0;
	overflow: hidden;
}
.logo-footer {
	width: 100px;
	margin-bottom: 10px;
}
.contacto .mailto {
	display: block;
	font-family: 'robotoblack', Arial, Helvetica, sans-serif;
}
.newsletter-redes-empresa {
	margin-top: 20px;
}
.newsletter-redes-empresa h4 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	line-height: 20px;
	margin-bottom: 12px;
	text-transform: uppercase;
}
.newsletter-redes-empresa .input-group-addon i {
	font-size: 26px;
	color: #fff;
}
.newsletter-redes-empresa .input-group-addon {
	background-color: #EF3724;
	border: 1px solid #EF3724;
	border-radius: 0 4px 4px 0;
}
.newsletter-redes-empresa ::-webkit-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #6C7076; }
.newsletter-redes-empresa ::-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #6C7076; }
.newsletter-redes-empresa :-ms-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #6C7076; }
.newsletter-redes-empresa input:-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; font-size: 15px; color: #6C7076; }


.newsletter-redes-empresa .form-group {
	max-width: 300px;
	margin-bottom: 40px;
}
.suscrito {
	position: absolute;
	background-color: #F6F8F8;
	top: 6px;
	left: 13px;
	z-index: 999;
	line-height: 16px;
	background-image: url(../img/ok.svg);
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 45px;
}
.cont-redes {
	margin-bottom: 40px;
}
.redes {
	display: inline-block;
	margin-right: 3px;
}
.redes img {
	width: 32px;
}
.l-newsletter-redes-empresa {
	display: block;
	margin-top: 20px;
}
.logo-enisa {
	width: 50px;
	float: left;
	margin-right: 20px;
}
.tarjetas {
	margin-top: 5px;
}
.metodos-pago {
	float: left;
	margin-top: 34px;
}
.txt-aceptamos {
	font-size: 11px;
}
.menu-footer-azul {
	float: left;
	width: 100%;
	margin-top: 30px;
	font-size: 12px;
}
.menu-footer-azul a {
	color: #0081D5;
}
.menu-footer-azul ul {
	margin-bottom: 10px;
	overflow: hidden;
}
.menu-footer-azul li {
	margin-bottom: 10px;
}
.copi {
	display: block;
	width: 100%;
	text-align: center;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
/*---------Fin Footer---------*/




/*---------Login---------*/
.cabecera-login {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 70px;
	line-height: 70px;
	background-color: #fff;
	z-index: 99999;
	text-align: center;
}
.cabecera-login img {
	width: 100px;
}
.nav-login .nav-tabs {
	background-color: #DFE1E1;
}
.nav-login li {
	text-align: center;
	width: 50%;
	margin: 0px;
	margin-bottom: 0px;
}
.nav-login li a {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: #6C7076;
	border-radius: 0;
	margin-right: 0;
	border: none;
	padding-left: 0;
	padding-right: 0;
}
.nav-login .nav-tabs {
	border-bottom: none;;
}
.nav-login .nav-tabs > li.active > a,
.nav-login .nav-tabs > li.active > a:hover,
.nav-login .nav-tabs > li.active > a:focus {
	color: #2B2C33;
	cursor: default;
	background-color: #fff;
	border: none;
}
.nav-login .tab-content {
	text-align: center;
	background-color: #fff;
	padding: 20px 15px;
}
.nav-login .o {
	margin: 48px 0 35px 0;
	text-align: center;
}
.nav-login .o:after {
	position: relative;
	top: -12px;
	content: 'o';
	padding: 0 10px;
	background-color: #fff;
}
.nav-login .form-group input {
	margin-bottom: 15px;
	color: #2B2D33;
}
.olvi {
	font-size: 13px;
	margin-top: 15px;
}
.nav-login .form-group input {
	background-repeat: no-repeat;
	background-position: 10px center;
	padding-left: 40px;
}
.nav-login .form-group label {
	display: inherit;
	max-width: 100%;
	margin-bottom: 0;
	font-weight: inherit;
	position: relative;
}
.nav-login .form-group input[type=email] {
	background-image: url(../img/email.svg);
}
.nav-login .form-group input[type=password] {
	background-image: url(../img/password.svg);
}
.nav-login .form-group input[type=text] {
	 padding-left: 12px;
 }
.soy-nuevo {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 16px;
	margin: 40px 0 0 0;
	text-align: left;
}
.unete {
	margin: 10px 0 30px 0;
}
.unete li {
	color: #EF3724;
	font-size: 16px;
	list-style: disc;
	text-align: left;
	width: 100%;
	margin-left: 20px;
	padding-right: 10px;
}
.unete span {
	color: #2B2C33;
}
.titu_soy_nuevo {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 25px;
	line-height: 25px;
	padding: 0 0 15px 0;
}
/*---------Fin Login---------*/

/*---------Registro---------*/
.registro {
	padding: 15px;
	text-align: center;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
.registro p {
	margin-bottom: 30px;
}
.form-registro .form-group label {
	display: inherit;
	max-width: 100%;
	margin-bottom: 0;
	font-weight: inherit;
	position: relative;
}
.form-registro,
.registro .btn-facebook {
	max-width: 320px;
	margin: 0 auto;
}
.registro .form-group input {
	margin-bottom: 15px;
}
.registro .bootstrap-select {
	margin-bottom: 15px !important;
}
.acepto-politica {
	text-align: left;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	margin-bottom: 30px;
}
.acepto-politica a {
	text-decoration: underline;
}
.acepto-politica input[type=checkbox] {
	display: none;
}
.acepto-politica label:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	float: left;
	width: 25px;
	height: 25px;
	margin-right: 10px;
	margin-top: 4px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
}
.acepto-politica input[type=checkbox]:checked + label:before {
	content: "\e66f";
	font-size: 25px;
	color: #2B2E33;
	text-align: center;
	line-height: 25px;
}
.acepto-politica label {
	font-size: 12px;
	font-weight: normal;
}
.registro footer {
	border-top: solid 1px #f2f4f4;
	margin-top: 30px;
	padding: 20px 0 50px 0;
}
.registro-ok {
	padding: 15px;
	text-align: center;
	font-size: 15px;
	min-height: 400px;
}
.registro-ok h2 {
	line-height: 28px;
	padding-bottom: 20px;
}
.registro-ok h1 {
	line-height: 34px;
	text-transform: none;
	padding-bottom: 20px;
}

/*---------Fin Registro ---------*/




/*---------modulo curso ---------*/
.modulo-curso {
	margin-bottom: 30px;
	text-align: center;
}
.info-curso {
	text-align: left;
	position: relative;
	padding: 0 10px;
	font-size: 15px;
	line-height: 16px;
	height: 190px;
	background-color: #fff;
}
.info-curso p {
	margin-bottom: 5px;
}
.modulo-curso .img-curso {
	width: 100%;
}

.modulo-curso .profesor {
	width: 70px;
	position: absolute;
	left: 10px;
	top: -35px;
	border: solid 1px #DFE1E1;
}
.modulo-curso .lugar-fecha {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 14px;
	height: 40px;
	padding-top: 8px;
	/*padding-left: 80px;*/
	color: #EF3724;
}
.modulo-curso .docente {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
.modulo-curso .nombre-curso {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 5px;
}
.modulo-curso .disponibilidad {
	font-size: 13px;
	color: #6C7076;
}
.modulo-curso .iconografia {
	position: absolute;
	bottom: 8px;
}
.modulo-curso .iconografia img {
	margin-right: 5px;
}
.modulo-curso .iconografia span {
	margin-right: 15px;
}
.tooltip_unlimited,
.tooltip_f5 {
	margin-right: 15px;
	position: relative;
}
.tooltip_unlimited:hover:after,
.tooltip_f5:hover:after {
	left: 0;
	position: absolute;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 12px;
	background-color: #FCF3F7;
	border: solid 1px #FBD7D8;
	left: 0;
	right: 0;
	top: -1px;
	padding: 4px 8px;
	z-index: 99999999;
	width: 230px;
	text-align: center;
}
.tooltip_unlimited:hover:after {
	content: 'Compra este curso con Foxize Unlimited';
	width: 100px;
}
.tooltip_f5:hover:after {
	content: 'Compra este curso con tu Bono F5';
	width: 100px;
}
/* deprecated */
.modulo-curso footer {
	height: 40px;
	line-height: 40px;
	border-top: solid 1px #f2f4f4;
	background-color: #fff;
}
/* deprecated */
.modulo-curso footer p {
	margin-bottom: 0;
}
/* deprecated */
.modulo-curso .precio {
	float: left;
	text-align: center;
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 20px;
}
/* deprecated */
.modulo-curso .cont-stars {
	float: left;
	width: 40%;
	height: 40px;
	line-height: 40px;
	border-left: solid 1px #f2f4f4;
	border-right: solid 1px #f2f4f4;
}
/* deprecated */
.modulo-curso .favorito {
	position: relative;
	float: left;
	text-align: center;
}
/* deprecated */
.modulo-curso .favorito input[type=checkbox] {
	display: none;
}
/* deprecated */
.modulo-curso .favorito label {
	width: 100%;
	cursor: pointer;
}
/* deprecated */
.modulo-curso .favorito label:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
	width: 25px;
	height: 25px;
	background-image: url(../img/favourite.svg);
	background-repeat: no-repeat;
}
/* deprecated */
.modulo-curso .favorito input[type=checkbox]:checked + label:before {
	position: absolute;
	background-image: url(../img/favourite.svg);
	background-repeat: no-repeat;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
}
.eliminar {
	margin-top: 20px !important;
}

.modulo-curso .btn i {
	font-size: 22px;
	vertical-align: sub;
	margin-right: 5px;
}

.btn-extra1 {
	display: block;
	max-width: 290px;
	margin: 20px auto 20px auto;
}
.proximamente {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 0 15px 30px 15px;
	z-index: 99;
}
.proximamente > div {
	width: 100%;
	height: 100%;
	display: block;
	background-color: rgba(255,255,255,.8);
	overflow: hidden;
	position: relative;
}
.proximamente span {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #fff;
	background-color: #EF3724;
	text-align: center;
	padding: 8px;
	display: block;
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	margin: 12% 0 0 -60%;
}
.proximamente .cont-btn {
	width: 100%;
	position: absolute;
	bottom: 50px;
	text-align: center;
}
.sold_aut,
.finalizado {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 0 15px 30px 15px;
	z-index: 99;
}
.sold_aut > div,
.finalizado > div {
	width: 100%;
	height: 100%;
	display: block;
	background-color: rgba(0,0,0,.6);
	overflow: hidden;
}
.sold_aut span,
.finalizado span {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #fff;
	background-color: #EF3724;
	text-align: center;
	padding: 8px;
	display: block;
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	margin: 12% 0 0 -60%;
}

/*---------Fin modulo curso ---------*/


/*---------Modulo programa ---------*/
.modulo-programa {
	margin-bottom: 30px;
	text-align: center;
}
.modulo-programa header {
	padding: 20px 10px 10px 10px;
	background-color: #fff;
	height: 190px;
	border-bottom: solid 1px #ccc;
}
.titulo_programa {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 17px;
	line-height: 20px;
	height: 40px;
}
.img-programa {
	height: 120px;
	text-align: center;
	line-height: 120px;
}
.img-programa img {
	max-width: 80%;
	max-height: 80%;
}
.info-programa {
	text-align: left;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	padding: 10px;
	font-size: 14px;
	line-height: 16px;
	height: 149px;
	background-color: #fff;
	position: relative;
}
.info-programa p {
	margin-bottom: 5px;
}
.modulo-programa .iconografia {
	position: absolute;
	bottom: 8px;
}
.modulo-programa .iconografia img {
	margin-right: 5px;
}
.modulo-programa .iconografia span {
	margin-right: 15px;
}
.modulo-programa footer {
	height: 40px;
	line-height: 40px;
	border-top: solid 1px #f2f4f4;
	background-color: #fff;
}
.modulo-programa footer p {
	margin-bottom: 0px;
}
.modulo-programa .precio {
	float: left;
	width: 30%;
	text-align: center;
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 20px;
}
.modulo-programa .cont-stars {
	float: left;
	width: 40%;
	height: 40px;
	line-height: 40px;
	border-left: solid 1px #f2f4f4;
	border-right: solid 1px #f2f4f4;
}
.modulo-programa .favorito {
	position: relative;
	float: left;
	width: 30%;
	text-align: center;
}
.modulo-programa .favorito input[type=checkbox] {
	display: none;
}
.modulo-programa .favorito label {
	width: 100%;
	cursor: pointer;
}
.modulo-programa .favorito label:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
	width: 25px;
	height: 25px;
	background-image: url(../img/favourite.svg);
	background-repeat: no-repeat;
}
.modulo-programa .favorito input[type=checkbox]:checked + label:before {
	position: absolute;
	background-image: url(../img/favourite.svg);
	background-repeat: no-repeat;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
}
.modulo-programa .btn i {
	font-size: 22px;
	vertical-align: sub;
	margin-right: 5px;
}
/*---------Fin modulo programa ---------*/


/*---------modulo recurso ---------*/
.modulo-recurso {
	display: block;
	height: 274px;
	position: relative;
	background-color: #2B2E33;
	overflow: hidden;
	text-align: left;
}
.modulo-recurso footer {
	position: absolute;
	width: 100%;
	height: 100px;
	color: #fff;
	top: 190px;
	width: 100%;
	padding:10px;
	transition: .7s;
	background-color: #2B2E33;
}
.modulo-recurso footer h5 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 10px;
}
.modulo-recurso footer p {
	line-height: 22px;
	font-size: 16px;
}

/*---------Fin modulo recurso ---------*/


/*---------modulo test ---------*/
.modulo-test {
	text-align: center;
	overflow: hidden;
	margin-bottom: 30px;
}
.modulo-test h3,
.modulo-test h2 {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 20px;
	margin-top: 10px;
}
.modulo-test p {
	float: left;
	width: 50%;
	height: 90px;
	border-top: solid 1px #DFE1E1;
	border-bottom: solid 1px #DFE1E1;
	text-align: center;
	font-size: 12px;
	color: #6C7076;
	margin-bottom: 0;
	padding-top: 10px;
	line-height: 14px;
}
.modulo-test .linea {
	border-right: solid 1px #DFE1E1;
}

.modulo-test p span {
	line-height: 30px;
}
.modulo-test footer {
	float: left;
	width: 100%;
	height: 86px;
	display: table;
	color: #666666;
	font-size: 13px;
	line-height: 30px;
}
.modulo-test footer .centrado {
	display: table-cell;
	vertical-align: middle;
}
.circliful {
	position: relative;
	margin: 0 auto;
}

.circle-text, .circle-info, .circle-text-half, .circle-info-half {
	width: 100%;
	position: absolute;
	text-align: center;
	display: inline-block;
	font-size: 12px;
	padding: 0 40px;
	height: 100%;
}

.circle-info, .circle-info-half {
	color: #EF3724;
}

.circliful .fa {
	margin: -10px 3px 0 3px;
	position: relative;
	bottom: 4px;
}
/*---------Fin modulo test ---------*/

/*---------modulo profesores ---------*/
.modulo-profesores {
	text-align: center;
	overflow: hidden;
	margin-bottom: 30px;
}
.modulo-profesores .img-circle {
	width: 100px;
	height: 100px;
	margin: 20px auto 5px auto;
	background-size: cover;
	background-position: center;
}
.profesor .img-circle {
	width: 100px;
	height: 100px;
	margin: 20px auto 5px auto;
	background-size: cover;
	background-position: center;

}
.borde_circulo {
	position: relative;
}
.borde_circulo:before {
	content: '';
	position: absolute;
	width: 106px;
	height: 106px;
	border: solid 1px #E0E2E2;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	margin-top: -53px;
	margin-left: -53px;
}
.modulo-profesores img {
	width: 100px;
	margin: 20px 0 5px 0;
}
.modulo-profesores .nombre {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 0;
}
.modulo-profesores .cargo {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 18px;
	height: 53px;
	padding: 0 10px;
}
.modulo-profesores .cursos-valoracion {
	float: left;
	width: 100%;
	border-top: solid 1px #DFE1E1;
	border-bottom: solid 1px #DFE1E1;
	text-align: center;
	font-size: 12px;
	color: #6C7076;
	line-height: 14px;
}
.modulo-profesores .cursos,
.modulo-profesores .valoracion {
	padding-top: 10px;
	margin-bottom: 0;
	width: 50%;
	height: 90px;
	float: left;
}
.modulo-profesores .valoracion {
	padding-top: 18px;
}
.modulo-profesores .valoracion p {
	margin-top: 8px;
}
.modulo-profesores .linea {
	border-left: solid 1px #DFE1E1;
}
.modulo-profesores p span {
	line-height: 30px;
}
.modulo-profesores footer {
	float: left;
	width: 100%;
	padding: 23px 0;
}
/*---------Fin modulo profesores ---------*/





/*---------Resultados ---------*/
.text-resultado {
	font-size: 18px;
	padding: 20px;
	line-height: 22px;
}
/*---------Fin Resultados ---------*/



/*---------Buscador cursos ---------*/
.btn_cursos {
	margin-top: 20px;
	overflow: hidden;
	width: 100%;
	background-color: #fff;
}
.btn_cursos li {
	display: table;
	width: 50%;
	height: 40px;
	text-align: center;
	color: #A5A5A7;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 16px;
	float: left;
}

.btn_cursos li a {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	background-color: #fff;
	color: #A5A5A7;
}
.btn_cursos li a:hover,
.btn_cursos li a:focus,
.btn_cursos li a:active {
	color: #A5A5A7 !important;
	background-color: #fff;
}
.btn_cursos .active {
	cursor: inherit;
	background-color: #EF3724;
	color: #fff;
}
.btn_cursos .active:hover {
	cursor: inherit;
	background-color: #EF3724;
	color: #fff !important;
}
.filtro_rapido,
.filtro_rapido2 {
	background-color: #6D7076;
	padding: 10px;
	color: #fff;
}
.titulo_filtro_rapido,
.titulo_filtro_rapido2 {
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
	font-size: 16px;
}
.filtro_rapido .bootstrap-select,
.filtro_rapido2 .bootstrap-select {
	margin-bottom: 10px;
	height: 30px;
}
.filtro_rapido .bootstrap-select > .dropdown-toggle,
.filtro_rapido2 .bootstrap-select > .dropdown-toggle {
	height: 30px;
	border-color: #dfe1e1;
	background-color: #F6F8F8;
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
	font-size: 13px;
	padding-top: 5px;
	padding-left: 8px;
}
.check_cursos_recomendados {
	background-color: #fff;
	margin-bottom: 1px;
	padding: 25px 15px;
}
.check_cursos_recomendados input[type=checkbox] {
	display: none;
}
.check_cursos_recomendados label {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 0;
	padding-left: 30px;
	position: relative;
}
.check_cursos_recomendados label:before {
	position: absolute;
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	display: inline-block;
	width: 21px;
	height: 21px;
	margin-right: 10px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
	vertical-align: top;
	left: 0;
	top: 50%;
	margin-top: -10px;
}
.check_cursos_recomendados input[type=checkbox]:checked + label:before {
	background-image: url(../img/check_black.svg);
	background-repeat: no-repeat;
	background-position: 4px 5px;
	left: 0;
	top: 50%;
	margin-top: -10px;
}

.filtro_avanzado {
	padding: 15px 11px;
	font-size: 13px;
	color: #6C7076;
}
.titulo_filtro {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #000;
	margin-bottom: 0;
}
.titulo_filtro a {
	display: block;
}
.titulo_filtro a:hover,
.titulo_filtro a:focus,
.titulo_filtro a:active {
	color: #000;
}
.titulo_filtro span {
	float: right;
}
.titulo_filtro i {
	font-size: 14px;
}
.desplegable_filtro_avanzado {
	display: none;
	margin-top: 25px;
}
.alineado {
	padding-left: 5px;
	padding-right: 5px;
}
.filtro_avanzado .linea {
	margin: 20px 5px 28px 5px;
}
.filtro_avanzado li {
	min-height: 36px;
}
#desplegables {
	padding-top: 6px;
}
.filtro_avanzado li ul {
	display: none;
	float: left;
	width: 100%;
	margin: 8px 0 20px 0;
	width:100%;
}
.filtro_avanzado li ul li {
	font-size: 13px;
	color: #6C7076;
	margin-left: -5px;
}
.filtro_avanzado li label a {
	position: absolute;
	left: 110px;
	top: 1px;
	color: #fff;
	background-color: #A5A5A7;
	border-radius: 50%;
	float: right;
	width: 20px;
	height: 20px;
	line-height: 19px;
	margin-top: 7px;
	margin-right: 130px;
	text-align: center;
}
.filtro_avanzado input[type=checkbox] {
	display: none;
}
.filtro_avanzado label {
	position: relative;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-weight: normal;
	width: 100%;
	height: 36px;
	display: table;
	padding-left: 25px;
	border: solid 1px #fff;
}
.filtro_avanzado label p {
	display: table-cell;
	vertical-align: middle;
	line-height: 16px;
}
.filtro_avanzado label:before {
	content: "";
	position: absolute;
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
	left: 5px;
	top: 50%;
	margin-top: -8px;

}
.filtro_avanzado input[type=checkbox]:checked + label {
	color: #000;
	background-color: #FEF5F4;
	border: solid 1px #FDD9D4;
}
.filtro_avanzado input[type=checkbox]:checked + label:before {
	position: absolute;
	background-image: url(../img/check_red.svg);
	background-repeat: no-repeat;
	background-position: 1px 2px;
	left: 5px;
	top: 50%;
	margin-top: -8px;
}
.filtro_avanzado .desple {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 15px;
	color: #2B2D33;
	padding: 0 5px;
	overflow: hidden;
}
.filtro_avanzado .desple span {
	float: right;
}
.filtro_avanzado .desple span:first-child {
	float: left;
}

.filtro_avanzado .desple:hover {
	cursor: pointer;
}

.filtro_avanzado .desple .mas:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: '\e707';
	font-size: 15px;
	color: #A5A6A8;
}
.filtro_avanzado .desple .menos:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: '\e709';
	font-size: 15px;
	color: #A5A6A8;
}
.rango {
	padding: 20px 0 10px 0;
	overflow: hidden;
}
#slider-euros-margin-value-min,
#slider-horas-margin-value-min {
	float: left;
	width: 16%;
}
#slider-euros-margin-value-max,
#slider-horas-margin-value-max {
	float: left;
	width: 20%;
	text-align: right;
}
#slider-euros,
#slider-horas {
	float: left;
	width: 64%;
	margin-top: 7px;
}
.euros:after {
	content: '€';
}
.horas:after {
	content: 'h';
}
.m-l li:nth-child(1) {
	line-height: 34px;
}
.modalidad .selct_busc > li {
	margin-bottom: 20px;
}
.filtro input[type=checkbox] {
	display: none;
}
.filtro label {
	position: relative;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-weight: normal;
	height: 34px;
	display: table;
	padding-left: 20px;
	margin: 0;
}
.filtro label p {
	display: table-cell;
	vertical-align: middle;
	line-height: 16px;
}
.filtro label:before {
	content: "";
	position: absolute;
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
	left: 0;
	top: 50%;
	margin-top: -8px;

}
.filtro input[type=checkbox]:checked + label {
	color: #000;
}
.filtro input[type=checkbox]:checked + label:before {
	position: absolute;
	background-image: url(../img/check_black.svg);
	background-repeat: no-repeat;
	background-position: 1px 2px;
	left: 0;
	top: 50%;
	margin-top: -8px;
}

@media (min-width: 767.98px) {
	.filtro_avanzado2 a:nth-child(1) {
		float: right;
	}

}
@media (max-width: 767.98px) {
	#filtro_rapido .filtro_avanzado2 a:nth-child(1) {
		float: left;
		margin-bottom: 20px;
	}

	.btn-group {

		display: block;

	}
	#filtro_rapido .filtro_avanzado2 div{
		width: 100%;
	}

	#filtro_rapido .filtro_avanzado2 a{
		width: 100%;
	}


}
.filtro_avanzado2 {
	width: 100%;
	border-top: solid 1px #F2F4F4;
	padding-top: 20px;
	overflow: hidden;
}
.filtro_avanzado2 li {
	font-size: 13px;
	float: left;
	padding-right: 17px;
}
.tematicas_filtro ul .titul {
	margin-top: 20px;
	margin-bottom: 5px;
	font-size: 15px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	width: 100%;
}
.tematicas_filtro ul {
	border-bottom: solid 1px #F2F4F4;
	overflow: hidden;
	padding-bottom: 7px;
	margin-bottom: 12px;
}
.tematicas_filtro ul .titul:first-child {
	margin-top: 0;
}
.tematicas_filtro ul:nth-child(8) {
	border: none;
}
.filtro_extras {
	margin-top: 20px;
	margin-bottom: 30px;
	overflow: hidden;
}
.filtro_extras ul {
	padding: 20px 0;
	float: left;
	width: 100%;
	border-bottom: solid 1px #DFE1E1;
}
.filtro_extras ul:nth-child(1) {
	border-top: solid 1px #DFE1E1;
}
.filtro_extras ul li:first-child {
	margin-top: 7px;
	font-size: 14px;
}
.gasto {
	margin-top: 8px;
	margin-bottom: 6px;
	width: 260px;
}
.mas_comprados {
	float: right;
	width: 200px;
}
.mas_comprados .bootstrap-select > .dropdown-toggle {
	background-color: #fff;
	border-color: #fff;
}
.cont_btns a {
	margin: 0 10px 10px 10px;
}
.cont_btns {
	text-align: center;
	margin: 20px 0 0 0;
}
.cont_btns a {
	width:100%;
	max-width: 150px;
}
.cont_btns2 a {
	margin: 0 10px 10px 10px;
}
.cont_btns2 {
	text-align: center;
	margin: 20px 0 0 0;
}
.cont_btns2 a {
	width:100%;
	max-width: 215px;
}

.cont_btns3{
	display: flex;
	justify-content: center;
}

.cont_btns3 a{
	min-width: 215px;
	margin: 0 10px 10px;
}
.f_tematicas {
	overflow: hidden;
	margin-left: -12px;
}
.f_tematicas li {
	margin-left: 12px;
	margin-bottom: 12px;
	float: left;
}
.f_tematicas li a {
	background-color: #6D7076;
	font-size: 13px;
	border-radius: 3px;
	color: #fff;
	padding: 4px 12px;
	float: left;
}
.f_tematicas li a:hover,
.f_tematicas li a:active {
	color: #fff !important;
}
.f_tematicas li a i {
	margin-left: 4px;
	font-size: 12px;
}
.cont_tematicas {
	margin-bottom: 30px;
	overflow: hidden;
}
/*---------Fin Buscador cursos ---------*/


/*---------Modal ---------*/
.modal-dialog {
	margin-top: 100px;
}
.modal-content {
	border-radius: 0;
	padding-top: 40px;
}
.modal-content .close {
	position: absolute;
	right: 0;
	top: 0;
	margin: 10px;
	display: block;
	color: #A6A7A9;
	filter: alpha(opacity=1);
	opacity: 1;
	z-index: 1051;
}
.modal_favoritos {
	text-align: center;
	font-size: 18px;
	padding-bottom: 30px;
}
.modal_favoritos h4 {
	margin-bottom: 70px;
	padding: 0 20px;
}
.modal_favoritos a{
	margin-right: 10px;
	margin-bottom: 10px;
}

/*---------Fin Modal ---------*/




/*---------Ficha curso ---------*/
.cont-img-cabecera-curso {
	position: relative;
}
.partner_circle {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 50px;
}
.img-cabecera-curso {
	width: 100%;
	margin-bottom: 20px;
}
.check_add_favoritos {

}
.check_add_favoritos input[type=checkbox] {
	display: none;
}
.check_add_favoritos label {
	font-weight: normal;
	font-size: 12px;
	color: #333;
	background-color: #F2F4F4;
	position: relative;
	width: 100%;
	display: table;
	border-radius: 4px;
	padding: 15px 0 15px 0;
	cursor: pointer;
	text-align: center;
}
.check_add_favoritos label p {
	display: table-cell;
	vertical-align: middle;
}
.check_add_favoritos label:before {
	position: absolute;
	content: "";
	display: inline-block;
	background-image: url(../img/favourite_gray.svg);
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	left: 8px;
	top: 50%;
	margin-top: -12px;
}
.check_add_favoritos input[type=checkbox]:checked + label:before {
	background-image: url(../img/favourite.svg);
	background-repeat: no-repeat;
	left: 0;
	top: 50%;
	left: 8px;
	margin-top: -12px;
}
.apuntarme {
	background-color: #2B2E33;
	text-align: center;
	color: #fff;
	padding: 25px 0;
	font-size: 15px;
}
.apuntarme2 {
	text-align: center;
	padding-top: 15px;
	font-size: 15px;
}
.apuntarme a i {
	font-size: 18px;
	font-weight: bold;
	vertical-align: -2px;
	margin-right: 5px;
}
.linea_fondo {
	position: relative;
	background-image: url(../img/punto_blanco.gif);
	background-repeat: repeat-x;
	background-position: center;
	height: 31px;
	margin-bottom: 30px;
}
.apuntarme .star {
	position: absolute;
	width: 138px;
	margin-bottom: 30px;
	background-color: #2B2E33;
	padding-left: 10px;
	padding-right: 10px;
	left: 50%;
	margin-left: -69px;
	z-index: 101;
}
.apuntarme .star a {
	font-size: 22px;
}
.ancho-apun {
	width: 90%;
	max-width: 220px;
	margin-left: auto;
	margin-right: auto;
}
.ancho-apun2 {
	width: 90%;
	max-width: 260px;
	margin-left: auto;
	margin-right: auto;
}
.precio_curso {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 24px;
	background-image: url(../img/punto_blanco.gif);
	background-repeat: repeat-x;
	background-position: center;
}
.precio_curso span {
	background-color: #2B2E33;
	padding-left: 10px;
	padding-right: 10px;
}
.precio_curso2 {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 24px;
	background-image: url(../img/punto_negro.gif);
	background-repeat: repeat-x;
	background-position: center;
}
.precio_curso2 span {
	background-color: #fff;
	padding-left: 10px;
	padding-right: 10px;
}
.punteado {
	border-top: none;
	border-bottom: 1px dashed #6D7076;
}
.info-extra {
	background-color: #fff;
	z-index: 100;
	position: relative;
}
.alumnos {
	width: 100%;
	position: relative;
}
#owl-alumnos .item {
	padding-left: 0;
	padding-right: 0;
}
#owl-alumnos .item ul {
	width: 100%;
	margin-bottom: 30px;
	text-align: center;
}
#owl-alumnos .item ul li {
	display: inline-block;
	vertical-align: top;
	width: 45px;
	height: 45px;
	background-image: url(../img/alumnos/icono_alumno.svg);
	background-repeat: no-repeat;
	background-size: 45px;
	margin: 10px 3px 13px 3px;
}
#owl-alumnos .item  img {
	width: 100%;
}
.print {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	display: block;
	background-image: url(../img/print.svg);
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center left;
	padding: 5px 0 5px 30px;
}
.pdf {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	display: block;
	background-image: url(../img/pdf.svg);
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center left;
	padding: 5px 0 5px 30px;
}
.factura {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	display: block;
	background-image: url(../img/factura.svg);
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center left;
	padding: 5px 0 5px 30px;
}
.ppt {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	display: block;
	background-image: url(../img/ppt.svg);
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center left;
	padding: 5px 0 5px 30px;
}
.print:hover,
.print:active,
.print:focus,
.pdf:hover,
.pdf:active,
.pdf:focus,
.factura:hover,
.factura:active,
.factura:focus,
.ppt:hover,
.ppt:active,
.ppt:focus {
	color: #2B2C33;
}
.mobile {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 18px;
	width: 130px;
	margin: 0 auto;
	color: #EF3724;
	display: block;
	background-image: url(../img/mobile.svg);
	background-repeat: no-repeat;
	background-size: 15px;
	background-position: center left;
	padding: 5px 0 5px 25px;
}
.ficha_curso {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-right: 45px;
	position: relative;
}
.ficha_curso hr {
	border-color: #DFE1E1;
}
.ficha_curso p {
	margin-bottom: 15px;
}
.margenes_f_c {
	padding-left: 20px;
	padding-right: 20px;
}
.datos_curso {
	border-top: solid 1px #DFE1E1;
	border-bottom: solid 1px #DFE1E1;
	overflow: hidden;
}
.disc_red {
	padding-left: 20px;
}
.disc_red li {
	list-style: none;
	color: #2B2C33 !important;
	margin-bottom: 10px;
	text-align: left;
}
.disc_red li span {
	color: #2B2C33;
}
.formato_listado{
	padding-left: 10px;
}
.formato_listado li::before{
	content: '•';
	padding-right: 5px;
}
.datos_curso .star {
	display: inline-block;
	vertical-align: -30%;
}
.datos_curso .suscripcion {
	background-image: url(../img/reloj_monedas.svg);
	background-repeat: no-repeat;
	background-position: left center;
	line-height: 30px;
	margin: 8px 0 0 20px;
	padding-left: 35px;
	position: relative;
}
.datos_curso .bonof5 {
	background-image: url(../img/f5.svg);
	background-repeat: no-repeat;
	background-position: left center;
	line-height: 30px;
	margin: 8px 0 0 20px;
	padding-left: 35px;
	position: relative;
}
.datos_curso .suscripcion span,
.datos_curso .bonof5 span {
	margin-left: 10px;
}
.datos_curso .profesor {
	border-top: solid 20px #F7F7F7;
	text-align: center;
	overflow: hidden;
	margin: 0 -20px 0 -20px;
	padding-top: 20px;
}
.datos_curso .profesor img {
	width: 110px;
	margin: 0 0 10px 0;
}
.borde {
	border: solid 1px #E0E2E2;
	padding: 3px;
}
.datos_curso .profesor .nombre {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	color: #EF3724;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 5px;
}
.datos_curso .profesor .cargo {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 25px;
}
.datos_curso .profesor .cursos-valoracion {
	float: left;
	width: 100%;
	border-top: solid 1px #DFE1E1;
	text-align: center;
	font-size: 12px;
	color: #6C7076;
	line-height: 14px;
}
.datos_curso .profesor .cursos,
.datos_curso .profesor .valoracion {
	padding-top: 10px;
	margin-bottom: 0;
	width: 50%;
	height: 90px;
	float: left;
}
.datos_curso .profesor .valoracion {
	padding-top: 18px;
}
.datos_curso .profesor .valoracion p {
	margin-top: 8px;
}
.datos_curso .profesor .linea {
	border-left: solid 1px #DFE1E1;
}
.datos_curso .profesor p span {
	line-height: 30px;
}

.video_curso video {
	width: 100%;
	vertical-align: bottom;
}
.redes_curso {
	margin-top: 20px;
	width: 100%;
	text-align: center;
}
.redes_curso li {
	display: inline-block;
	margin-left: 17px;
}
.redes_curso li:first-child {
	margin-left: 0;
}
.btn-twitter {
	display: block;
	width: 37px;
	height: 37px;
	line-height: 37px;
	background-image: url(../img/social_twitter.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #59A7DE;
	border-radius: 6px;
}
.btn-facebook2 {
	display: block;
	width: 37px;
	height: 37px;
	line-height: 37px;
	background-image: url(../img/social_facebook.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #375598;
	border-radius: 6px;
}
.btn-linkedin {
	display: block;
	width: 37px;
	height: 37px;
	line-height: 37px;
	background-image: url(../img/social_linkedin.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #0075B6;
	border-radius: 6px;
}
.btn-correo {
	display: block;
	width: 37px;
	height: 37px;
	line-height: 37px;
	background-image: url(../img/correo.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #A4A5A7;
	border-radius: 6px;
}
.opiniones {
	position: relative;
	overflow: hidden;
}
.opiniones img {
	width: 50px;
}
.opiniones ul {
	margin-left: 20px;
	position: relative;
}
.comentario,
.comentario_2 {
	font-size: 13px;
	background-color: #F6F8F8;
	padding: 10px 10px 5px 10px;
	margin-top: 15px;
	border-radius: 5px;
	margin-bottom: 25px;
	position: relative;
}
.comentario p,
.comentario_2 p {
	margin-bottom: 5px;
}
.comentario:before,
.comentario_2:before {
	top: -20px;
	left: 15px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(242, 244, 244, 0);
	border-bottom-color: #F6F8F8;
	border-width: 10px;
}
.head_sidebar,
.apuntarme {
	position: relative;
}
.head_sidebar > div {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: block;
	background-color: rgba(0,0,0,.5);
	overflow: hidden;
	z-index: 99;
}
.head_sidebar > div span {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #fff;
	background-color: #EF3724;
	text-align: center;
	padding: 8px 30px;
	display: block;
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	position: absolute;
	top: 26px;
	left: -35px;
}
.apuntarme > div {
	position: absolute;
	width: 150px;
	height: 150px;
	top: 0;
	left: 0;
	display: block;
	overflow: hidden;
	z-index: 1;
}
.apuntarme > div span {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 11px;
	color: #fff;
	background-color: #EF3724;
	text-align: center;
	padding: 8px;
	display: block;
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	margin: 25px 0 0 -60px;
}

.fecha_lugar_curso .avisarme {
	width: 100%;
	white-space: normal;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 13px;
	margin-top: -10px;
	margin-bottom: 20px;
}
.fecha_lugar_curso li {
	position: relative;
	padding-left: 35px;
}
#appHorarioCurso{
	padding-bottom: 0;
	margin-bottom: 0;
}

#appHorarioCurso ul{
	padding-bottom: 0;
	margin-bottom: 0;
}

.fecha_lugar_curso p {
	margin-bottom: 5px;
}
.fecha_lugar_curso .link-underline {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
.calendar:before {
	position: absolute;
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "\e669";
	color: #EF3724;
	left: 0;
	top: -4px;
	font-size: 22px;
}
.direc:before {
	position: absolute;
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "\e880";
	color: #EF3724;
	left: 0;
	top: -4px;
	font-size: 22px;
}
.documentacion li {
	position: relative;
	padding-left: 25px;
	margin-bottom: 10px;
}
.enlace:before {
	position: absolute;
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "\e6f4";
	color: #D5D6D7;
	left: 0;
	top: 0;
	font-size: 16px;
}
.documento:before {
	position: absolute;
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "\e93a";
	color: #D5D6D7;
	left: 0;
	top: 0;
	font-size: 16px;
}
.pertenece_programa {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	background-color: #595D64;
	color: #fff;
	padding: 10px 20px;
	font-size: 14px;
	overflow: hidden;
}
.pertenece_programa a {
	margin-top: 10px;
	display: block;
}
.pertenece_programa p {

}

.itinerarios_asociados {
	background-color: #4E4E4E;
	color: #fff;
	padding: 10px 20px;
	font-size: 14px;
	overflow: hidden;
}

.itinerarios_asociados a {
	margin-top: 0px;
	display: block;
}
.itinerarios_asociados p {

}

.certificado {
	background-color: #DFE1E1;
	padding: 15px 10px 20px 10px;
	margin: 1px 20px 20px 20px;
	overflow: hidden;
}
.certificado {
	padding: 15px 10px 15px 10px;
}
.cuartos {
	width: 80%;
	overflow: hidden;
	float: left;
	margin-top: 7px;
}
.cuartos li {
	float: left;
	width: 24%;
	background-color: #fff;
	margin-left: 1px;
	line-height: 15px;
	height: 15px;
	color: #fff;
}
.cuartos .active {
	background-color: #9CC100;
	color: #9CC100;
}
.btn-certificado {
	float: right;
	width: 35px;
	height: 30px;
	color: #fff;
	text-align: center;
	position: relative;
	line-height: 16px;
	background-image: url(../img/diploma.svg);
	background-repeat: no-repeat;
	background-position: center;
}
.btn-certificado:hover,
.btn-certificado:active,
.btn-certificado:focus {
	color: #fff;
}
.partes_curso {
	margin-left: 20px;
	margin-right: 20px;
	border: solid 1px #DFE1E1;
	border-radius: 6px;
}
.partes_curso header {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	line-height: 18px;
	background-color: #F2F4F4;
	border-bottom: solid 1px #DFE1E1;
	padding: 10px 20px;
	border-radius: 6px 6px 0 0;
}
.parte li {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 20px 15px 20px;
	border-top: solid 1px #DFE1E1;
}
.parte li:first-child {
	border-top: none;
}
.parte li p {
	margin-bottom: 6px;
}
.parte li a {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 13px;
	display: block;
	border-radius: 0;
}
.sesion {
	margin-bottom: 20px;
}
.sesion a {
	display: block;
	color: #2B2C33;
	position: relative;
	padding-right: 20px;
	margin-right: 10px;
}
.sesion a:hover,
.sesion a:active,
.sesion a:focus {
	color: #2B2C33;
}
.sesion .temario {
	display: none;
}
.sesion .temario .disc_red {
	padding-left: 24px;
}
.sesion i {
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -8px;
	color: #A5A6A8;
	font-size: 17px;
}
.sesion .temario > p {
	margin-top: 10px;
	margin-bottom: 10px;
}
.busq_programas_ciudad {
	background-color: #6D7076;
	padding: 10px;
	color: #fff;
}
.busq_programas_ciudad2 {
	color: #fff;
}
.busq_programas_ciudad2 > p {
	background-color: #6D7076;
	padding: 10px;
	margin-bottom: 0;
}
.cont-select {
	margin: 0 auto;
}
.cont_select2 {
	border-left: solid 1px #ccc;
	border-right: solid 1px #ccc;
	border-bottom: solid 1px #ccc;
	padding: 10px 10px 0 10px;
}
.busq_programas_ciudad .bootstrap-select,
.busq_programas_ciudad2 .bootstrap-select {
	margin-bottom: 10px;
	height: 30px;
}
.busq_programas_ciudad .bootstrap-select > .dropdown-toggle,
.busq_programas_ciudad2 .bootstrap-select > .dropdown-toggle {
	height: 30px;
	border-color: #dfe1e1;
	background-color: #F6F8F8;
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
	font-size: 13px;
	padding-top: 5px;
	padding-left: 8px;
}
.opacidad {
	opacity:0.3;
}
.opacidad .plazas {
	display: none;
}
.opacidad .c_p_3 p:nth-child(1) {
	padding-top: 30px;
}
.opacidad .btn {
	pointer-events: none;
	cursor: default;
}
.cont_programa li {
	position: relative;
}
.txt_resaltado {
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 100px;
	font-size: 16px;
	color: #ED3724;
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	z-index: 9999;
}
.curso_programa {
	border: solid 1px #ccc;
}
.curso_programa p {
	margin-bottom: 0;
}
.c_p_1 {
	padding: 10px;
}
.c_p_2 {
	border-top: solid 1px #ccc;
	overflow: hidden;
}
.c_p_3 {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	border-top: solid 1px #ccc;
	background-color: #F2F4F4;
	text-align: center;
	padding: 8px 0 12px 0;
}
.c_p_titulo {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 15px;
}
.c_p_profesor {
	font-size: 14px;
}
.c_p_lugar {
	font-size: 14px;
}
.t_p_altura {
	min-height: 65px;
}
.c_p_tematica {
	padding: 10px;
	text-align: center;
	border-bottom: solid 1px #ccc;
}
.c_p_tematica p:nth-child(1) {
	font-size: 12px;
	margin-bottom: 6px;
}
.c_p_nivel,
.c_p_valoracion {
	font-size: 12px;
	float: left;
	width: 50%;
	text-align: center;
	padding: 15px 0;
}
.c_p_nivel p,
.c_p_valoracion p {
	margin-bottom: 5px;
}

.p_p_altura {
	min-height: 80px;
}
.p_p_altura hr {
	border-color: #000;
	padding: 0;
	margin: 0 auto;
	margin-top: -14px;
	margin-bottom: 10px;
	width: 145px;
}
.c_p_3 p:nth-child(2) {
	font-size: 20px;
	background-color: #F2F4F4;
	display: inline-block;
	padding: 0 14px;
}
.plazas {
	font-size: 13px;
}
.c_p_3 .btn {
	font-size: 13px;
}
.titu_datos_curso {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 66px;
	height: 66px;
}
.dc,
.df {
	overflow: hidden;
	font-size: 15px;
	margin-right: -20px;
	margin-left: -20px;
	text-align: center;
}
.dc li {
	float: left;
	width: 50%;
	text-align: center;
}
.dc li:nth-child(2n+1) {
	border-right: solid 1px #DFE1E1;
}
.dc span,
.df span {
	background-color: #F7F7F7;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	display: block;
	height: 30px;
	line-height: 30px;
}
.centra_verti {
	display: table;
	height: 70px;
	width: 100%;
}
.centra_verti p,
.centra_verti > div {
	display: table-cell;
	vertical-align: middle;
}
.cabecera_titulo {
	background-color: #fff;
	text-align: center;
	padding: 30px 0;
	border-bottom: solid 1px #DFE1E1;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 22px;
	line-height: 26px;
}
.cabecera_titulo p {
	font-size: 14px;
}
.cabecera_titulo h1 span {
	font-size: 24px;
}
.no_border {
	border: none !important;
}
.si_border {
	border-bottom: solid 1px #DFE1E1;
}
.img_partner {
	text-align: center;
	border-bottom: solid 1px #DFE1E1;
}
.espacio_img_partner {
	border-bottom: solid 1px #DFE1E1;
}
/*---------Fin Ficha curso ---------*/

/*---------Itinerario ---------*/
.lista_comun {
	padding-left: 30px;
}
.lista_comun li:before {
	content: '•';
	padding-right: 5px;
}
.itinerario {
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
	font-size: 15px;
	overflow: hidden;
}
.itinerario img {
	width: 100%;
}
.descripcion_itinerario {
	padding: 20px 20px 0 20px;
}
.disponibilidad_itinerario {
	padding:  0 20px 20px 20px;
}
.disponibilidad_itinerario ul {
	padding-left: 30px;
}
/*---------Fin Itinerario ---------*/

/*---------Temática ---------*/
.tematica,
.subtematica {
	text-align: center;
	padding: 10px 20px 20px 20px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
	font-size: 14px;
	margin-bottom: 15px;
}
.subtematica {
	padding: 5px 10px 20px 10px;
}
.tematica img,
.subtematica img {

}
.subtematica img {
	height: 80px;
}
.tematica p,
.subtematica p {
	margin-bottom: 20px;
}
.tematica ul,
.subtematica ul {
	display: inline-block;
	vertical-align: top;
	padding: 0 8px;
}
.tematica li,
.subtematica li {
	margin-bottom: 10px;
	line-height: 18px;
}
.tematica li a {
	text-decoration: underline;
}
.subtematica h3 {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 18px;
	margin-bottom: 20px;
}
/*---------Fin Temática ---------*/

/*---------Planes precio ---------*/
.planes_precio {
	background-color: #fff;
	margin-bottom: 20px;
}
.lista_precio {
	color: #6C7076;
	margin: 0 -20px;
}
.lista_precio li {
	position: relative;
	border-bottom: solid 1px #F2F4F4;
	line-height: 18px;
	padding: 15px 12px 15px 35px;
	background-image: url(../img/marca.svg);
	background-repeat: no-repeat;
	background-position: 12px 19px;
}
.lista_precio li:first-child {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 15px;
	background-image: none;
	padding: 20px 12px;
}
.planes_precio header {
	padding: 20px;
	text-align: center;
	color: #fff;
	border-radius: 5px 5px 0 0;
}
.planes_precio header p {
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
	font-size: 15px;
}
.cuerpo_lista_precio {
	margin: 0 20px;
	min-height: 380px;
	position: relative;
	padding-bottom: 90px;
}
.cuerpo_lista_precio .btn {
	position: absolute;
	bottom: 20px;
	width: 100%;
}
.como_funciona {
	text-align: center;
	margin-top: 30px;
}
.como_funciona i {
	vertical-align: -1px;
	padding-left: 20px;
}
.funciona {
	display: none;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	margin-top: 20px;
	text-align: left;
}
.funciona li {
	margin-bottom: 5px;
}

/*---------Fin Planes precio ---------*/


/*---------Como funciona ---------*/
.c_funciona,
.c_funciona2 {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	border: solid 1px #DFE1E1;
	padding: 12px 20px;
}
.c_funciona {
	background-color: #F6F8F8;
}
.c_funciona2 {
	background-color: #fff;
}
.c_funciona i,
.c_funciona2 i {
	font-weight: bold;
	padding-right: 10px;
}
.c_funciona .c_f,
.c_funciona2 .c_f {
	display: none;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	margin: 20px 0 0 26px;
}

.como_funciona_fxz_unlimited,
.como_funciona_bono_f5 {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	overflow: hidden;
	line-height: 26px;
}
.como_funciona_fxz_unlimited .fs12 {
	line-height: 16px;
}
.como_funciona_fxz_unlimited {
	border-bottom: solid 1px #ccc;
	padding-bottom: 20px;
	margin-bottom: 30px;
}


/*---------Fin Como funciona ---------*/




/*---------Regala formación---------*/
.regala_formacion {
	background-color: #2B2D33;
	color: #fff;
	font-size: 15px;
}
.regala_formacion h6 {
	font-size: 23px;
	line-height: 27px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
	text-align: left;
}
.regala_formacion img {
	width: 100%;
}
.regala_formacion article {
	padding: 20px;
	text-align: center;
}
.regala_formacion article p {
	text-align: left;
}
.regala_formacion article a {
	margin: 30px 0 20px 0;
}
/*---------Fin Regala formación---------*/


/*---------Upgradebox---------*/
.upgradebox {
	padding: 20px;
	margin-bottom: 20px;
}
.mt30xs {
	margin-top: 30px;
}
/*---------Fin Upgradebox---------*/


/*---------Profesores---------*/
.f-profesores {
	background-image: url(../img/profesores.png);
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
}
.banners_maestros {
	text-align: center;
	padding-top: 30px;
}
.banners_maestros li {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	text-align: center;
	width: 100%;
	padding: 20px 15px;
	display: inline-block;
	vertical-align: top;
	border-bottom: solid 1px #F2F4F4;
	margin-bottom: 30px;
}
.banners_maestros li:nth-child(3) {
	border-bottom: none;
}
.banners_maestros img {
	margin-bottom: 10px;
}
.busq_profesores_ciudad {
	background-color: #6D7076;
	padding: 10px;
	color: #fff;
	margin-bottom: 20px;
}
.busq_profesores_ciudad .bootstrap-select {
	margin-bottom: 10px;
	height: 30px;
}
.busq_profesores_ciudad .bootstrap-select > .dropdown-toggle {
	height: 30px;
	border-color: #dfe1e1;
	background-color: #F6F8F8;
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
	font-size: 13px;
	padding-top: 5px;
	padding-left: 8px;
}
.busc_profesores_nom input {
	height: 40px;
	background-image: url(../img/zoom.svg); background-repeat: no-repeat; background-position: 9px center; background-size: 20px; padding-left: 35px;
}
.busc_profesores_nom ::-webkit-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #333; font-size: 15px;}
.busc_profesores_nom ::-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #333; font-size: 15px;}
.busc_profesores_nom :-ms-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #333; font-size: 15px;}
.busc_profesores_nom input:-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #333; font-size: 15px;}
.select_tematica {
	max-width: 270px;
	margin: 0 auto 10px auto;
}
.select_tematica .btn {
	font-size: 15px;
}
.ubicacion {
	font-size: 16px;
	color: #EF3724;
}
.ubicacion i {
	font-size: 24px;
	vertical-align: -5px;
	padding-right: 5px;
}
.info_profesor {
	text-align: center;
	color: #6C7076;
}
.info_profesor img {
	width: 110px;
	margin: 20px 0 25px 0;
}
.c_h_a {
	border-top: solid 1px #dfe1e1;
	overflow: hidden;
	display: table;
	width: 100%;
}
.c_h_a li {
	display: table-cell;
	vertical-align: middle;
	width: 32.8%;
	height: 80px;
	border-left: solid 1px #dfe1e1;
}
.c_h_a li:first-child {
	border: none;
}
.c_h_a li p {
	margin: 0;
}
.info_profesor .valoracion {
	height: 70px;
	background-color: #ffffff;
	color: #6C7076;
	font-size: 12px;
	padding-top: 10px;
}
.info_profesor .valoracion p {
	margin-bottom: 3px;
}
.info_profesor .contact {

}
.contact {
	text-align: left;
}
.contact li {
	margin-bottom: 10px;
}
.contact a {
	color: #2B2D33;
	font-size: 13px;
	display: table;
}
.contact a span {
	display: table-cell;
	vertical-align: middle;
}
.contact_twitter {
	float: left;
	width: 37px;
	height: 37px;
	background-image: url(../img/social_twitter_x.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #000000;
	border-radius: 6px;
	margin-right: 10px;
}
.contact_linkedin {
	float: left;
	width: 37px;
	height: 37px;
	background-image: url(../img/social_linkedin.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #0075B6;
	border-radius: 6px;
	margin-right: 10px;
}
.contact_link {
	float: left;
	width: 37px;
	height: 37px;
	background-image: url(../img/link.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #6D7076;
	border-radius: 6px;
	margin-right: 10px;
}
.datos_p {
	padding-top: 10px;
}
.datos_p p {
	display: inline-block;
	margin: 7px 15px 7px 0;
}
.datos_p span {
	display: inline-block;
	padding-left: 15px;
	margin-right: 15px;
}
.datos_p span p:nth-child(1) {

}
.datos_p span {
	border-right: solid 1px #ccc;
	border-left: solid 1px #ccc;
}
.curso_profesor {
	background-color: #fff;
}
.curso_profesor p {
	margin-bottom: 0;
}
.prof_c_p_1 {
	padding: 10px;
}
.prof_c_p_2 {
	border-top: solid 1px #ccc;
	overflow: hidden;
}
.prof_c_p_3 {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	text-align: center;
	padding: 8px 0 12px 0;
}
.prof_c_p_titulo {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 15px;
}
.prof_c_p_lugar {
	font-size: 14px;
}
.prof_t_p_altura {
	min-height: 65px;
}
.prof_c_p_tematica {
	padding: 10px;
	text-align: center;
	border-bottom: solid 1px #ccc;
}
.prof_c_p_tematica p:nth-child(1) {
	font-size: 12px;
	margin-bottom: 6px;
}
.prof_c_p_nivel,
.prof_c_p_valoracion {
	font-size: 12px;
	float: left;
	width: 50%;
	text-align: center;
	padding: 15px 0;
}
.prof_c_p_nivel p,
.prof_c_p_valoracion p {
	margin-bottom: 5px;
}

.prof_p_p_altura {
	min-height: 80px;
}
.prof_p_p_altura hr {
	border-color: #000;
	padding: 0;
	margin: 0 auto;
	margin-top: -14px;
	margin-bottom: 10px;
	width: 145px;
}
.prof_c_p_3 .prof_euros {
	font-size: 20px;
	background-color: #F2F4F4;
	display: inline-block;
	padding: 0 14px;
}
.prof_plazas {
	font-size: 13px;
}
.prof_c_p_3 .btn {
	font-size: 13px;
	width: 145px;
}
.fondo_gris1,
.fondo_gris1 .prof_euros {
	background-color: #2B2D33;
	color: #fff;
}
.fondo_gris1 hr {
	border-color: #fff;
}
.fondo_gris2,
.fondo_gris2 .prof_euros {
	background-color: #D6D6D6;
	color: #fff;
}
.fondo_gris2 hr {
	border-color: #fff;
}
/*---------Fin Profesores---------*/

/*---------Porque foxize---------*/
.video_poque_foxize video,
.video_poque_foxize img {
	width: 100%;
	vertical-align: top;
}
.equipo {
	position: relative;
	text-align: center;
	overflow: hidden;
}
.equipo img {
	width: 150%;
	margin-left: -27.5%;
}
.equipo > p {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 20px;
}
.equipo_profesor {
	text-align: center;
	padding:20px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	min-height: 350px;
	margin-bottom: 20px;
}
.equipo_profesor > img {
	width: 110px;
	margin-bottom: 10px;
}
.equipo_profesor .nombre {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #ED3724;
	margin-bottom: 0;
}
.equipo_profesor .cargo {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
}
.equipo_profesor a {
	padding-right: 8px;
}


.principios {
	background-color: #fff;
	overflow: hidden;
}
.principios video {
	width: 100%;
	vertical-align: top;
}
.nuestros_principios {
	padding: 20px;

}
.nuestros_principios li {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 30px;
	font-size: 16px;
	overflow: hidden;
}
.nuestros_principios li:nth-child(3) {
	margin-bottom: 0;
}
.nuestros_principios .num {
	width: 20px;
	height: 37px;
	margin: 0 auto 10px auto;
	border-bottom: 3px solid #EF3724;
	text-align: center;
	font-family: 'robotoblack', Arial, Helvetica, sans-serif;
	font-size: 32px;
	line-height: 32px;
	color: #EF3724;
}
/*---------Fin Porque foxize---------*/

/*---------Compra---------*/
.compra {
	background-color: #fff;
	padding: 20px;
}
.compra h1 {
	line-height: 26px;
}
.lista_compra li:first-child {
	border-top: solid 1px #DFE1E1;
}
.lista_compra li {
	border-bottom: solid 1px #DFE1E1;
	padding: 20px 0;
	display: table;
	width: 100%;
}
.compra .datos {
	width: 100%;
	display: inline-block;
	padding-bottom: 10px;
}
.compra p {
	margin-bottom: 4px;
}
.compra .unidades {
	width: 65px;
	display: inline-block;
	vertical-align: middle;
	position: relative;
}
.compra .unidades input {
	background-color: #F2F4F4;
	border: 1px solid #DFE1E1;
	border-right: none;
	border-radius: 4px 0 0 4px;
	width: 45px;
	height: 40px;
	float: left;
	text-align: center;
}
.compra .btn-mas {
	position: absolute;
	top: 50%;
	margin-top: -20px;
	width: 20px;
	height: 20px;
	background-color: #F2F4F4;
	border: 1px solid #DFE1E1;
	border-bottom: none;
	border-radius: 0 4px 0 0;
	background-image: url(../img/btn-mas.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 10px;
}
.compra .btn-menos {
	position: absolute;
	top: 50%;
	width: 20px;
	height: 20px;
	background-color: #F2F4F4;
	border: 1px solid #DFE1E1;
	border-radius: 0 0 4px 0;
	background-image: url(../img/btn-menos.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 10px;
}
.compra .precio {
	width: 93px;
	padding-right: 20px;
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 18px;
	display: inline-block;
	vertical-align: middle;
	text-align: right;
}
.compra .precio2 {
	width: 100%;
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 18px;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
}
.compra .papelera {
	width: 18px;
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
}
.compra .papelera i {
	color: #A5A6A8;
}
.compra .total,
.compra .total2 {
	line-height: 60px;
	font-size: 15px;
	text-align: right;
}
.compra .docente,
.compra .fecha {
	line-height: 16px;
	font-size: 14px;
	color: #6C7076;
}
.compra .curso {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	line-height: 16px;
}
.btn-compra {
	overflow: hidden;
}
.btn-compra a {
	width: 100%;
	max-width: 180px;
	margin: 10px auto;
	display: block;
}
.mensage {
	text-align: center;
	width: 90%;
	margin: 30px auto;
	font-size: 15px;
}
@media (min-width: 450px) {
	.compra .datos {
		display: inline-block;
		width: 95%;
		padding-bottom: 0;
	}
	.compra .unidades {
		display: table-cell;
	}
	.compra .precio,
	.compra .precio2 {
		display: table-cell;
	}
	.compra .precio2 {
		text-align: right;
		width: 93px;
	}
	.compra .papelera {
		display: table-cell;
	}
	.compra .total {
		padding-right: 38px;
	}
	.btn-compra a:nth-child(1) {
		float: left;
	}
	.btn-compra a:nth-child(2) {
		float: right;
	}
}
.linea_progreso {
	height: 20px;
	border-top: solid 2px #ccc;
	margin-bottom: 15px;
	margin-top: 80px;
}
.circulo {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	color: #fff;
	font-size: 17px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	text-align: center;
	line-height: 40px;
}

.text-verde {
	color: #00BF56;
}
.text-gris {
	color: #DFE1E1;
}
.linea_progreso ul {
	margin-top: -20px;
}
.linea_progreso ul li {
	background-color: #fff;
}
.linea_progreso ul li img {
	width: 16px;
	margin-top: -4px;
}
.linea_progreso ul li:nth-child(1) {
	float: left;
	padding-right: 10px;
}
.linea_progreso ul li:nth-child(2) {
	position: absolute;
	width: 60px;
	left: 50%;
	margin-left: -30px;
	padding: 0 10px;
}
.linea_progreso ul li:nth-child(3) {
	float: right;
	padding-left: 10px;
}
.text_linea_progreso {
	height: 28px;
	font-size: 20px;
	line-height: 24px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 50px;
	position: relative;
	color: #000;
}
.text_linea_progreso span:nth-child(1) {
	float: left;
}
.text_linea_progreso span:nth-child(2) {
	position: absolute;
	width: 160px;
	left: 50%;
	margin-left: -80px;
	text-align: center;
}
.text_linea_progreso span:nth-child(3) {
	float: right;
	text-align: right;
}
.codigo_descuento {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	text-align: right;
	overflow: hidden;
	line-height: 33px;
	font-size: 13px;
}
.dto {
	width: 100%;
	display: block;
	float: right;
	margin-bottom: 5px;
}
.compra input {
	height: 35px;
}
.codigo_descuento input {
	float: right;
	width: 210px;
	/*margin-left: 15px;*/
}
.precio_final {
	background-color: #F2F4F4;
	padding:10px;
	font-size: 15px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	text-align: center;
	margin-bottom: 40px;
	margin-top: 10px;
}
.quiero_factura {
	margin-bottom: 20px;
}
.quiero_factura label {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-weight: normal;
	line-height: 22px;
	height: 22px;
	padding-left: 30px;
	position: relative;
	cursor: pointer;
}
.condiciones_pago label {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-weight: normal;
	line-height: 22px;
	height: 22px;
	padding-left: 30px;
	position: relative;
}
.condiciones_pago p {

	text-justify: inter-word;

}
.quiero_factura input[type=checkbox],
.condiciones_pago input[type=checkbox] {
	display: none;
}
.quiero_factura label:before,
.condiciones_pago label:before {
	position: relative;
	margin-right: 10px;
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
	vertical-align: top;
	left: 0;
}
.quiero_factura input[type=checkbox]:checked + label:before,
.condiciones_pago input[type=checkbox]:checked + label:before {
	position: relative;
	margin-right: 10px;
	content: "\e66f";
	font-size: 22px;
	color: #2B2E33;
	text-align: center;
	line-height: 20px;
}
#datos {
	display: none;
}
.box {
	display: none;
}
.datos_particular {
	margin-bottom: 20px;
}
.datos_empresa {
	margin-bottom: 30px;
}
.datos_particular input,
.datos_empresa input {
	margin-bottom: 15px;
}
.max_width350 {
	max-width: 350px;
}
.max_width260 {
	max-width: 260px;
}
.max_width160 {
	max-width: 160px;
}
.datos_particular > div,
.datos_empresa > div {
	margin-bottom: 12px;
}
.datos_particular > div label,
.datos_empresa > div label {
	font-size: 15px;
	font-weight: normal;
	line-height: 16px;
	height: 16px;
	padding-left: 25px;
	position: relative;
}
.datos_particular > div input[type=radio],
.datos_empresa > div input[type=radio] {
	display: none;
}
.datos_particular > div label:before,
.datos_empresa > div label:before {
	position: absolute;
	content: '';
	display: inline-block;
	width: 16px;
	height: 16px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 50%;
	vertical-align: top;
	left: 0;
}
.datos_particular > div input[type=radio]:checked + label:before,
.datos_empresa > div input[type=radio]:checked + label:before {
	content: '';
	position: absolute;
	background-image: url(../img/punto.svg);
	background-repeat: no-repeat;
	background-position: center;
}
.metodo_pago {
	width: 100%;
	border-top: solid 1px #DFE1E1;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	padding-top: 30px;
}
.metodo_pago label {
	font-size: 15px;
	font-weight: normal;
	line-height: 20px;
	height: 20px;
	padding-left: 35px;
	position: relative;
	margin-bottom: 20px;
}
.metodo_pago input[type=radio] {
	display: none;
}
.metodo_pago label:before {
	position: absolute;
	content: '';
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 50%;
	vertical-align: top;
	left: 0;
}
.metodo_pago input[type=radio]:checked + label:before {
	content: '';
	position: absolute;
	background-image: url(../img/punto.svg);
	background-repeat: no-repeat;
	background-position: center;
}
.metodo_pago label{
	margin-right: 40px;
}
.condiciones_pago {
	text-align: center;
	font-size: 12px;
	padding-top: 20px;
	overflow: hidden;
}
.condiciones_pago > div {
	border-top: solid 1px #DFE1E1;
	padding-top: 20px;
	max-width: 260px;
	margin: 20px auto 0 auto;
}
.btn_continuar {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 80px;
}
.banco {
	text-align: center;
	background-color: #F6F8F8;
	padding: 10px;
	line-height: 22px;
	margin: 20px 0 30px 0;
}
.confirmacion_compra {
	max-width: 530px;
	text-align: center;
	margin: 0 auto 40px;
}
.confirmacion_compra .fs30 {
	line-height: 36px;
}
/*---------Fin compra---------*/

/*---------Contacto---------*/
.datos_contacto {
	font-size: 15px;
}
.datos_contacto li {
	margin-bottom: 20px;
	position: relative;
	padding-left: 40px;
}
.datos_contacto .carta {
	background-image: url(../img/carta.svg);
	background-repeat: no-repeat;
}
.datos_contacto .mobile2 {
	line-height: 30px;
	background-image: url(../img/mobile.svg);
	background-repeat: no-repeat;
	background-size: 14px;
	background-position: 5px 0;
}
.datos_contacto .lugar {
	background-image: url(../img/lugar.svg);
	background-repeat: no-repeat;
	background-position: top left;
}
.f_contacto input {
	background-color: #fff;
	margin-bottom: 10px;
}
.f_contacto textarea {
	background-color: #fff;
	margin-bottom: 10px;
	height: 150px;
}
.f_politica {
	position: relative;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	display: block;
	width: 230px;
	margin: 0 auto 20px auto;
}
.f_politica input[type=checkbox] {
	display: none;
}
.f_politica label {
	padding-left: 30px;
}
.f_politica label:before {
	position: absolute;
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	display: inline-block;
	width: 22px;
	height: 22px;
	background-color: #f6f8f8;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
	vertical-align: top;
	left: 0;
}
.f_politica input[type=checkbox]:checked + label:before {
	position: absolute;
	content: "\e66f";
	font-size: 22px;
	color: #2B2E33;
	text-align: center;
	line-height: 20px;
}
.footer_form .btn  {
	display: block;
	margin: 0 auto;
}
#map {
	width: 100%;
	height: 200px;
}
.espacio_contact {
	margin-bottom: 30px;
}
/*---------Fin Contacto---------*/

/*---------Bibilioteca---------*/
.busq_biblioteca {
	background-color: #6D7076;
	padding: 10px;
	color: #fff;
	margin-bottom: 20px;
}
.busq_biblioteca .bootstrap-select {
	margin-bottom: 10px;
	height: 30px;
}
.busq_biblioteca .bootstrap-select > .dropdown-toggle {
	height: 30px;
	border-color: #dfe1e1;
	background-color: #F6F8F8;
	font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
	font-size: 13px;
	padding-top: 5px;
	padding-left: 8px;
}
.destacado {
	overflow: hidden;
	height: 209px;
}
.img_destacado img {
	width: 100%;
	overflow: hidden;
}
.txt_destacado {
	padding: 15px;
}
.txt_destacado p:nth-child(1),
.txt_destacado p:nth-child(2) {
	margin-bottom: 5px;
}
.txt_destacado .globalizacion {
	background-image: url(../img/libro.svg);
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding-left: 30px;
	line-height: 30px;
}
.suscribite_biblioteca {
	padding: 20px 15px 42px 15px;
	margin-bottom: 30px;
}
.cont-img-cab-biblio {
	margin: -20px -15px 20px -15px;
}
.cont-img-cab-biblio img {
	width: 100%;
}
.titulo_suscribete {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 21px;
	line-height: 25px;
	color: #ef3724;
	margin-bottom: 20px;
}
.suscribite_biblioteca ul {
	margin-bottom: 40px;
}
.suscribite_biblioteca li {
	line-height: 18px;
	padding: 0 0 0 25px;
	background-image: url(../img/marca.svg);
	background-repeat: no-repeat;
	background-position: 0 3px;
	margin-bottom: 10px;
}
.popular {
	padding: 20px 15px 40px 15px;
}
.ficha_biblioteca .header {
	overflow: hidden;
}
.ficha_biblioteca,
.ficha_biblioteca .cont {
	overflow: hidden;
}
.ficha_biblioteca .col-left img {
	width: 100%;
}
.ficha_biblioteca .col-right {
	padding: 15px 15px 0 15px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;

}
.check_add_favoritos_biblioteca {
	max-width: 300px;
	padding: 5px 0;
	margin: 0 auto;
}
.check_add_favoritos_biblioteca input[type=checkbox] {
	display: none;
}
.check_add_favoritos_biblioteca label {
	font-weight: normal;
	font-size: 12px;
	color: #fff;
	background-color: #000;
	position: relative;
	width: 100%;
	display: table;
	border-radius: 4px;
	padding: 10px 8px 15px 45px;
	cursor: pointer;
}
.check_add_favoritos_biblioteca label p {
	display: table-cell;
	vertical-align: middle;
}
.check_add_favoritos_biblioteca label:before {
	position: absolute;
	content: "";
	display: inline-block;
	background-image: url(../img/favourite_white.svg);
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	left: 15px;
	top: 50%;
	margin-top: -12px;
}
.check_add_favoritos_biblioteca input[type=checkbox]:checked + label:before {
	background-image: url(../img/favourite.svg);
	background-repeat: no-repeat;
	left: 0;
	top: 50%;
	left: 15px;
	margin-top: -12px;
}
.items {
	margin-top: 20px;
}
.items li {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	margin-bottom: 20px;
	font-size: 13px;
	padding: 6px 0  0 30px;
	background-repeat: no-repeat;
	background-position: left center;
}
.lapiz_red {
	background-image: url(../img/lapiz_red.svg);
}
.libro_red {
	background-image: url(../img/libro_red.svg);
}
.cesta_red {
	background-image: url(../img/cesta_red.svg);
}
.marca_red {
	background-image: url(../img/marca_red.svg);
}
.carpeta_red {
	background-image: url(../img/carpeta_red.svg);
}
.col-left .btn {
	max-width: 300px;
	margin: 0 auto;
	display: block;
}

.biblio article {
	background-color: #fff;
	margin: 0 0 30px 0;
}

.biblio article header {
	text-align: center;
	text-transform: uppercase;
	padding: 22px 22px 0 22px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 16px;
}

.biblio article header hr {
	margin: 0 0 -12px 0;
	border: 0;
	border-top: 1px solid #eee;
}

.biblio article header p {
	background-color: #fff;
	padding: 0 15px;
	display: inline-block;
}

.biblio article figure {
	text-align: center;
	height: 160px;
	line-height: 160px;
	margin: 0 0 20px 0;
}

.biblio article figure img {
	max-height: 100%;
}

.biblio article footer {
	background-color: #6D7076;
	height: 120px;
	color: #fff;
	font-size: 15px;
	text-align: center;
}

.biblio article footer .txt {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	height: 85px;
	padding: 20px 20px 0 20px;
}

.biblio article footer .autor {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
}

@media (min-width: 992px) {
	.biblio {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.biblio article {
		margin-right: 30px;
		width: calc((100% - 30px) / 2);
	}

	.biblio article:nth-child(2n) {
		margin-right: 0;
	}
}

@media (min-width: 1200px) {
	.biblio article {
		width: calc((100% - 60px) / 3);
	}

	.biblio article:nth-child(2n) {
		margin-right: 30px;
	}

	.biblio article:nth-child(3n) {
		margin-right: 0;
	}
}

@media (min-width: 1200px) {
	.ficha_biblio .colum-izq,
	.ficha_biblio .colum-der {
		float: left;
	}

	.ficha_biblio .colum-izq {
		width: 210px;
	}

	.ficha_biblio .colum-der {
		width: 637px;
		padding: 0 20px 30px 0;
	}

	.check_add_favoritos_biblio,
	.ficha_biblio .cont_btn .btn {
		float: left;
		margin: 0 20px 0 0;
	}

	.border-right {
		border-right: solid 1px #ccc;
	}
}


.check_add_favoritos_black {
	max-width: 330px;
	padding: 15px;
}

.check_add_favoritos_black label {
	font-size: 14px;
}


.ficha_biblio .cont_btn .btn,
.check_add_favoritos {
	width: 100%;
	max-width: 255px;
}
.check_add_favoritos{
	margin: 0 auto 20px auto;
}
.check_add_favoritos_biblio input[type=checkbox] {
	display: none;
}
.check_add_favoritos_biblio label {
	font-weight: normal;
	font-size: 14px;
	color: #fff;
	background-color: #000;
	position: relative;
	width: 100%;
	display: table;
	border-radius: 4px;
	padding: 13px 8px 13px 45px;
	cursor: pointer;
	margin: 0;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
}
.check_add_favoritos_biblio label p {
	display: table-cell;
	vertical-align: middle;
}
.check_add_favoritos_biblio label:before {
	position: absolute;
	content: "";
	display: inline-block;
	background-image: url(../img/favourite_white.svg);
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	left: 15px;
	top: 50%;
	margin-top: -12px;
}
.check_add_favoritos_biblio input[type=checkbox]:checked + label:before {
	background-image: url(../img/favourite.svg);
	background-repeat: no-repeat;
	left: 0;
	top: 50%;
	left: 15px;
	margin-top: -12px;
}

@media (min-width: 1200px) {
	.ficha_biblio .colum-izq,
	.ficha_biblio .colum-der {
		float: left;
	}
	.ficha_biblio .colum-izq {
		width: 210px;
	}
	.ficha_biblio .colum-der {
		width: 637px;
		padding: 0 20px 30px 0;
	}
	.check_add_favoritos_biblio,
	.ficha_biblio .cont_btn .btn {
		float: left;
		margin: 0 20px 0 0;
	}
	.border-right {
		border-right: solid 1px #ccc;
	}
}

.ficha_biblio .colum-izq,
.ficha_biblio .colum-der {
	padding: 0 20px 30px 20px;
}

.ficha_biblio {
	padding: 20px 0 0 0;
	overflow: hidden;
}



.mb30 {
	margin-bottom: 30px;
}

.ficha_biblio .tags {
	margin-top: -1px;
	font-size: 13px;
	width: 100%;
}

.ficha_biblio .tags .lapiz_red {
	background-image: url(../img/lapiz_red.svg);
}

.ficha_biblio .tags span {
	background-position: 18px 16px;
	background-repeat: no-repeat;
	background-size: 19px;
	padding: 15px 20px 15px 44px;
	line-height: 21px;
}

.ficha_biblio .cont_btn {
	overflow: hidden;
	text-align: center;
	padding: 30px 0 10px 0;
}

@media (min-width: 768px){
	.titulo_2_1 {
		font-size: 28px;
		line-height: 32px;
	}}
.titulo_2_1 {
	font-size: 24px;
	line-height: 28px;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	margin-bottom: 10px;
}

@media (max-width: 768px) {
	.busq_biblioteca .bootstrap-select > .dropdown-toggle {
		height: 30px;
		border-color: #dfe1e1;
		background-color: #F6F8F8;
		font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
		font-size: 15px;
		padding-top: 4px;
		padding-left: 8px;

	}

	.busq_biblioteca .cont_select
	{
		width: 100%;
	}

}

/*---------Fin Bibilioteca---------*/

/*---------Libros---------*/
.centrado {
	width: 160px;
	margin: 0 auto;
}
.catalogo_libros {
	padding: 30px 0;
	overflow: hidden;
}
.catalogo_libros li {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	width: 100%;
	max-width: 160px;
	margin-bottom: 10px;
	float: left;
}
.catalogo_libros li img {
	width: 100%;
	margin-bottom: 15px;
}
.catalogo_libros .text {
	height: 100px;
}
.catalogo_libros li p {
	text-align: left;
	margin-bottom: 0;

}
@media (min-width: 440px) {
	.centrado {
		width: 360px;
	}
	.catalogo_libros {
		margin-left: -40px;
	}
	.catalogo_libros li {
		margin-left: 40px;
	}
}
@media (min-width: 640px) {
	.centrado {
		width: 560px;
	}
}
.conseguir_resumen {
	text-align: center;
	padding-bottom: 30px;
}
.conseguir_resumen h2 {
	font-size: 20px;
	line-height: 26px;
}
.descarga_resumen {
	padding: 20px;
	overflow: hidden;
	text-align: center;
}
.descarga_resumen p {
	line-height: 24px;
}
.descarga_resumen img {
	box-shadow: 0 0 5px #999;
	margin-bottom: 20px;
}
.descarga_resumen i {
	font-size: 22px;
	font-weight: bold;
	vertical-align: -3px;
	padding-right: 10px;
}
.descarga_resumen .titulo_libro {
	line-height: 18px;
	min-height: 50px;
}
.consulta_catalogo {
	padding-top: 20px;
	text-align: center;
	color: #fff;
	background-image: url(../img/consulta_catalogo.png);
	background-size: cover;
	background-position: center;
	min-height: 194px;
}
.descarga_resumen .form-group {
	margin-bottom: 0;
}
.descarga_resumen .input-group {
	max-width: 320px;
	margin: 20px auto 0 auto;
	position: relative;
}
.descarga_resumen .input-group-addon i {
	font-size: 26px;
	color: #fff;
	padding: 0;
}
.descarga_resumen .input-group-addon {
	background-color: #EF3724;
	border: 1px solid #EF3724;
	border-radius: 0 4px 4px 0 !important;
}
.mensaje_ok_descarga {
	position: absolute;
	font-size: 12px;
	line-height: 17px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	background-image: url(../img/ok.svg);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 30px;
	padding-left: 40px;
	top: 5px;
	left: 10px;
	height: 35px;
	z-index: 99;
	text-align: left;
	background-color: #F6F8F8;
}
@media (min-width: 410px) {
	.mensaje_ok_descarga {
		font-size: 15px;
	}
}
/*---------Fin Libros---------*/


/*---------Update---------*/
.cont_update,
.cont_update2 {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	border: solid 1px #DFE1E1;
	padding: 12px 20px;
}
.cont_update {
	background-color: #F6F8F8;
}
.cont_update2 {
	background-color: #fff;
}
.cont_update i,
.cont_update2 i {
	font-weight: bold;
	padding-right: 10px;
}
.cont_update .desple_update,
.cont_update2 .desple_update {
	display: none;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	margin: 20px 0 0 0;
	overflow: hidden;
}
.cont_update2 .desplegado {
	display: block;
}
.update {
	overflow: hidden;
}
.update li {
	border-top: 1px solid #F2F4F4;
	overflow: hidden;
	padding: 15px 0;
}
.update .numero {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	width: 45px;
	margin-bottom: 20px;
}
@media (min-width: 500px) {
	.update .numero {
		float: left;
	}
}
.update .numero span {
	font-size: 32px;
	line-height: 36px;
	border-bottom: 3px solid #EF3724;
	color: #EF3724;
}
.update .text p {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	margin-bottom: 0;
}
.listado1 li:first-child {
	border: none;
}
.cabecera_update {
	background-image: url(../img/fondo_cabecera_update.png);
	background-size: cover;
	background-position: center;
}
.apuntate_update {
	text-align: center;
	padding-bottom: 30px;

}
.apuntate_update h2 {
	font-size: 20px;
	line-height: 26px;
}
.apuntate_update input {
	width: 100%;

}
.apuntate_update label {
	display: inherit;
	width: 250px;
	margin: 0 auto 12px auto;
	font-weight: normal;
	position: relative;
}

/*---------Fin Update---------*/

/*---------404---------*/
.cont_video_404 {
	margin-top: -20px;
	position: relative;
}
.video_404 {
	position: absolute;
	width: 100%;
	vertical-align: top;
	margin-bottom:-1px;
	z-index: -1;
}
.text_404 {
	width: 100%;
	text-align: center;
	padding-top: 10px;
}
.text_404 p:nth-child(1) {
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
	color: #fff;
	font-size: 34px;
	line-height: 44px;
}
.text_404 p:nth-child(2) {
	font-size: 24px;
	color: #333333;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
}
.text_404 p:nth-child(3) {
	font-size: 16px;
	width: 80%;
	margin: 0 auto;
}
/*---------Fin 404---------*/




/*---------Mi cuenta---------*/
.sub_menu li {
	margin-bottom: 15px;
}
.sub_menu li:last-child {
	margin-bottom: 5px;
}
.textarea_darme_baja {
	background-color: #fff;
	max-width: 400px;
	height: 140px !important;
}
.darme_baja {
	max-width: 400px;
}
.maxwidth380 {
	max-width: 380px;
}
.f-perfil,
.redes {
	max-width: 470px;
}
.f-perfil label {
	font-weight: normal;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	display: inline-block;
	width: 150px;
	line-height: 20px;
	padding-right: 10px;
}
.f-perfil input {
	display: inline-block;
	max-width: 310px;
	background-color: #fff;
}
.f-perfil .btn-group,
.f-perfil .dropdown-toggle {
	display: inline-block;
	max-width: 240px;
	background-color: #fff;
}
.maxwidth240 {
	max-width: 240px !important;
}
.calendario {
	background-image: url(../img/calendario_perfil.svg);
	background-repeat: no-repeat;
	background-position: right 10px center;
}
.perfil_redes {
	margin-bottom: 5px;
	overflow: hidden;
}
.perfil_redes label {
	font-weight: normal;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	line-height: 32px;
	padding-right: 10px;
	text-align: right;
	float: left;
}
.perfil_redes label img {
	vertical-align: top;
	width: 32px;
}
.perfil_redes input {
	float: left;
	max-width: 85%;
	background-color: #fff;
	height: 32px;
}
.guardar_cambios {
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
	background-color: #ccc;
}
.menu_cuenta {
	padding: 15px 20px;
	background-color: #fff;
}
.menu_cuenta > p {
	margin-bottom: 0;
}
.menu_cuenta > p a {
	display: block;
}
.menu_cuenta > p i {
	margin-top: 3px;
	float: right;
}
.desple_menu_cuenta {
	margin-top: 20px;
	display: none;
}
.cont_img {
	background-color: #DFE1E1;
	width: 170px;
	height: 170px;
	background-image: url(../img/fondo_img_usuario.svg);
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 10px;
}
.cont_img img {
	vertical-align: top;
}
.bono {
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	font-size: 15px;
	background-image: url(../img/bono_f5.svg);
	background-repeat: no-repeat;
	background-position: left top;
	padding-left: 60px;
}
.bono p {
	margin-bottom: 5px;
}
.listado {
	font-size: 15px;
	line-height: 17px;
	border: solid 1px #DFE1E1;
	border-radius: 4px;
}
.listado li {
	border-top: solid 1px #DFE1E1;
	background-color: #fff;
	padding: 15px;
	overflow: hidden;
}
.listado li span:first-child {
	float: left;
	width: 74%;
	font-size: 15px;
	color: #2B2C33;
	text-align: left;
	margin-right: 2%;
}
.listado li span {
	float: right;
	vertical-align: text-top;
	color: #6C7076;
	width: 24%;
	font-size: 14px;
	text-align: right;
}
.listado li:first-child {
	border-radius: 4px 4px 0 0;
	border-top: none;
	background-color: #F6F8F8;
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
}
.listado li:last-child {
	border-radius: 0 0 4px 4px;
}
.tabla_preferencias {
	border-radius: 5px;
	border: solid 1px #DFE1E1;
	background-color: #fff;
}
.tabla_preferencias h5 {
	border-radius: 4px 4px 0 0;
	color: #fff;
	background-color: #C2C2C2;
	padding: 10px 15px;
	font-size: 18px;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
}
.otras_ciudades {
	border-radius: 5px;
	border: solid 1px #DFE1E1;
	width: 160px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
.otras_ciudades p {
	margin-bottom: 0;
	padding: 4px 10px 4px 27px;
}
.otras_ciudades p a {
	display: block;
}
.otras_ciudades p span {
	float: right;
	margin-top: 1px;
}
.otras_ciudades ul {
	display: none;
	margin-bottom: 10px;
}
.otras_ciudades li {
	padding-left: 12px;
	margin-bottom: 2px;
}
.tematica_curso {
	margin: 20px;
	width: calc(100% - 40px);
}
.tematica_curso h6 {
	margin: 0 0 15px 0;
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	font-size: 14px;
}
.tematica_curso h6 a {
	display: block;
}
.tematica_curso h6 span {
	float: right;
	margin-top: 2px;
}
.lista_tematica {
	display: none;
}
.cont-tablas {
	overflow: hidden;
}
.cont-tablas section {
	min-height: 170px;
}
.cont-tablas section:nth-child(1),
.cont-tablas section:nth-child(2) {
	margin-right: 20px;
}
.cont-tablas section:nth-child(1),
.cont-tablas section:nth-child(2),
.cont-tablas section:nth-child(3) {
	width: 240px;
}
.tabla_preferencias {
	overflow: hidden;
}
.lista_tematica {
	margin-bottom: 30px;
}
.bullet_red {
	padding-left: 20px;
	position: relative;
}
.bullet_red:before {
	content: '•';
	position: absolute;
	color: #F23514;
	font-size: 28px;
	left: 0;
	top: -8px;
}
.borde_especial {
	border: 5px solid;
	border-image:url(../img/borde_especial.png) 5 5 repeat;
	height: 386px;
	display: table;
	width: 100%;
	text-align: center;
}
.centrado_vertical {
	display: table-cell;
	vertical-align: middle;
	float: unset !important;
}
.cont_mi_cuenta_sections {
	background-color: #fff;
	overflow: hidden;
}
.mi_cuenta_section_1,
.mi_cuenta_section_1_2 {
	margin: 15px;
	padding: 0 0 20px 0;
	border-bottom: solid 1px #F2F4F4;
}
.mi_cuenta_section_1 p,
.mi_cuenta_section_1_2 p {
	margin-bottom: 5px;
}
.mi_cuenta_section_1 p:first-child,
.mi_cuenta_section_1_2 p:first-child {
	line-height: 22px;
	margin-bottom: 7px;
}
.clases4_4,
.clases3_4,
.clases2_4,
.clases1_4,
.clases0_4 {
	background-repeat: no-repeat;
	background-position: left 4px;
	padding-left: 95px;
	line-height: 16px;
}
.clases4_4 {
	background-image: url(../img/clases4_4.svg);
}
.clases3_4 {
	background-image: url(../img/clases3_4.svg);
}
.clases2_4 {
	background-image: url(../img/clases2_4.svg);
}
.clases1_4 {
	background-image: url(../img/clases1_4.svg);
}
.clases0_4 {
	background-image: url(../img/clases0_4.svg);
}
.mi_cuenta_section_2,
.mi_cuenta_section_2_2 {
	padding: 0 15px 15px 15px;
}
.mi_cuenta_section_2 div,
.mi_cuenta_section_2_2 div {
	margin: 10px 0;
}
.doc_necesario {
	background-image: url(../img/documento_descarga.svg);
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center left;
	padding: 5px 0 5px 30px;
}
.dejar_comentario {
	background-image: url(../img/comentario.svg);
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center left;
	padding: 5px 0 5px 30px;
}
.compratir_linkedin {
	background-image: url(../img/linkedin_gris.svg);
	background-repeat: no-repeat;
	background-size: 22px;
	background-position: center left;
	padding: 5px 0 5px 30px;
}
.mi_cuenta_section_3,
.mi_cuenta_section_3_2 {
	background-color: #2B2E33;
	padding: 20px;
	color: #fff;
	text-align: center;
}
.linea_fondo2 {
	position: relative;
	background-image: url(../img/punto_blanco.gif);
	background-repeat: repeat-x;
	background-position: center;
	height: 31px;
	margin-bottom: 10px;
}
.valorar {
	width: 90%;
	max-width: 260px;
	margin-left: auto;
	margin-right: auto;
}
.valorar .star {
	position: absolute;
	width: 125px;
	margin-bottom: 30px;
	background-color: #2B2E33;
	padding-left: 10px;
	padding-right: 10px;
	left: 50%;
	margin-left: -62px;
	z-index: 499;
}
.valorar .star a {
	font-size: 20px;
}
.cont_btn_doc {
	width: 90%;
	max-width: 260px;
	margin-left: auto;
	margin-right: auto;
}
.btn_documentacion {
	font-size: 13px;
	line-height: 16px;
	background-color: #9AC100;
	display: block;
	border-radius: 3px;
	padding: 10px 10px 10px 40px;
	background-image: url(../img/diploma_withe.svg);
	background-repeat: no-repeat;
	background-position: 10px center;
	background-size: 22px;
}
.btn_no_asistido {
	font-size: 13px;
	line-height: 16px;
	background-color: #E03030;
	display: inline-block;
	border-radius: 3px;
	padding: 10px;
	margin-bottom: 10px;
	color: white;
}
.disable {
	position: relative;
	pointer-events: none;
}
.disable:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0.4;
	background-color: #fff;
	z-index: 999;
}
.sesiones {
	padding: 0 15px 20px;
	background-color: #fff;
}
.sesiones > a {
	border: solid 1px #DFE1E1;
	display: block;
	padding: 10px;
}
.sesiones > a i {
	font-weight: bold;
	padding-right: 10px;
}
.f_g {
	background-color: #F6F8F8;
}
.desple_sesiones {
	display: none;
	margin-top: 10px;
	border: solid 1px #DFE1E1;
	padding: 1px;
	background-color: #fff;
}
.alumno {
	padding: 20px;
	text-align: center;
}
.banners_datos_3, .banners_datos_2 {
	text-align: center;
	overflow: hidden;
}
.banners_datos_3 li, .banners_datos_2 li {
	margin: 15px 0;
	padding: 20px 10px;
	border-bottom: solid 1px #DFE1E1;
}
.banners_datos_4,
.banners_datos_4_1 {
	text-align: center;
	overflow: hidden;
	padding-top: 10px;
}
.banners_datos_4 li,
.banners_datos_4_1 li {
	padding: 20px 10px;
	border-bottom: solid 1px #DFE1E1;
}
.banners_datos_4 li:first-child,
.banners_datos_4_1 li:first-child {
	padding-top: 0;
}
.banners_datos_6 {
	text-align: center;
	overflow: hidden;
	padding-top: 10px;
}
.banners_datos_6 li {
	padding: 20px 10px;
	border-bottom: solid 1px #DFE1E1;
}

.banners_datos_2 li > p,
.banners_datos_3 li > p,
.banners_datos_4 li > p,
.banners_datos_4_1 li >p,
.banners_datos_6 li > p {
	margin-bottom: 0;
}
.banners_datos_3 li:nth-child(3) {
	border-bottom: none;
}
.banners_datos_2 li:nth-child(2) {
	border-bottom: none;
}
.banners_datos_4 li:nth-child(4),
.banners_datos_4_1 li:nth-child(4) {
	border-bottom: none;
}
.banners_datos_6 li:nth-child(6) {
	border-bottom: none;
}

.banners_datos_2 img,
.banners_datos_3 img,
.banners_datos_4 img,
.banners_datos_4_1 img,
.banners_datos_6 img {
	vertical-align: baseline;
}
.banners_datos_2 .centrar,
.banners_datos_3 .centrar,
.banners_datos_4 .centrar,
.banners_datos_4_1 .center {
	display: inline-block;
}
.banners_datos_2 .centrar p,
.banners_datos_3 .centrar p,
.banners_datos_4 .centrar p,
.banners_datos_4_1 .center p {
	margin-bottom: 0;
}
.titul_banner {
	padding: 20px 20px 0 20px;
	margin-bottom: 0;
}
.rangos_alumno {
	border-top: solid 1px #F2F4F4;
	text-align: center;
	overflow: hidden;
	padding: 0 20px 50px 20px;
}
.rangos_alumno li {
	width: 100%;
	padding-top: 60px;
	float: left;
}

.rangos_alumno .circle-text {
	margin-top: 55px !important;
	padding: 0;
	color: #333333;
	line-height: 20px !important;
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
}
.rangos_alumno .circle-info,
.rangos_alumno .circle-info-half {
	padding: 0;
	color: #6C7076;
	margin-top: 90px !important;
	font-size: 14px;
}
.rangos_alumno .circliful {
	width: 100%;
}

.margen {
	height: 20px;
	position: relative;
	margin-bottom: 30px;
}
.margen span {
	position: absolute;
	width: 100%;
	top: 25px;
	left: 0;
	line-height: 16px;
	padding: 0 10px;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 16px;
}

@media (min-width: 500px) {
	.rangos_alumno li {
		width: 50%;
	}
	.rangos_alumno li:nth-child(1) .margen,
	.rangos_alumno li:nth-child(3) .margen,
	.rangos_alumno li:nth-child(5) .margen,
	.rangos_alumno li:nth-child(7) .margen {
		border-right: solid 1px #DFE1E1;
	}
}
.border {
	border-radius: 4px;
	border: solid 1px #DFE1E1;
}
.border > p {
	font-size: 16px;
	font-family: 'robotobold', Arial, Helvetica, sans-serif;
	border-radius: 3px 3px 0 0;
	background-color: #F6F8F8;
	padding: 15px;
	border-bottom: solid 1px #DFE1E1;
	margin-bottom: 0;
}
.select_mes_anyo .bootstrap-select > .dropdown-toggle {
	background-color: #fff;
}
.select_mes_anyo {
	height: 105px;
}
.select_mes_anyo > li {
	display: inline-block;
	width: 130px;
	margin: 0 0 15px 0;
}
.select_mes_anyo > li:first-child {
	margin-right: 15px;
}
.cont_btn_desc_informe {
	float: left;
	width: 100%;
}
.cont_btn_desc_informe2 {
	text-align: center;
}
.cont_desple {
	position: relative;
}
@media (max-width: 767px) {
	.cont_desple > a {
		color: inherit;
		text-decoration: inherit;
		pointer-events: none;
		cursor: default;
	}
	.cont_desple > a:hover {
		color: inherit;
		text-decoration: inherit;
	}
}
.desplegable_oculto {
	display: none;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	position: absolute;
	width: 360px;
	top: 98%;
	right: 0%;
	background: #fff;
	border: 1px solid #f2f4f4;
	z-index: 999;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.1);
	text-align: left;
	padding: 15px 2px 15px 15px;
	line-height: 18px;
	font-size: 14px;
}
.table_2 .desplegable_oculto {
	margin-right: calc(50% - 28px);
}
.table_3 .desplegable_oculto {
	margin-right: calc(50% - 30px);
}
.table_4 .tabla_cursos_inscritos .desplegable_oculto {
	width: 440px;
	right: 13px;
}
.table_4 .tabla_cursos_asistidos .desplegable_oculto {
	width: 440px;
	right: 13px;
}
.table_5 .desplegable_oculto {
	margin-right: calc(50% - 30px);
}
.desplegable_oculto:after, .desplegable_oculto:before {
	bottom: 100%;
	border: solid transparent;
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	pointer-events: none;

}
.table_2 .desplegable_oculto:after,
.table_2 .desplegable_oculto:before,
.table_3 .desplegable_oculto:after,
.table_3 .desplegable_oculto:before,
.table_5 .desplegable_oculto:after,
.table_5 .desplegable_oculto:before {
	left: 92%;
}
.table_4 .tabla_cursos_inscritos .desplegable_oculto:after,
.table_4 .tabla_cursos_inscritos .desplegable_oculto:before {
	left: 50.5%;
}
.table_4 .tabla_cursos_asistidos .desplegable_oculto:after,
.table_4 .tabla_cursos_asistidos .desplegable_oculto:before {
	left: 50.5%;
}
.desplegable_oculto:after {
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #fff;
	border-width: 10px;
	margin-left: -10px;
}
.desplegable_oculto:before {
	border-color: rgba(242, 244, 244, 0);
	border-bottom-color: #f2f4f4;
	border-width: 11px;
	margin-left: -11px;
}
.desplegable_oculto .cerrar {
	float: right;
	text-align: right;
	padding: 0 10px;
	display: block;
}
.tabla_cursos_inscritos .cerrar,
.tabla_cursos_asistidos .cerrar {
	float: inherit;
	text-align: right;
	padding: 0 0 5px 0;
	display: block;
}
.listado3 li {
	padding: 12px 0;
	border-top: solid 1px #F2F4F4;
}
.listado3 li:first-child {
	border: none;
}
.select_test .bootstrap-select > .dropdown-toggle {
	background-color: #fff;
}
.select_test > li {
	display: inline-block;
	width: 250px;
	margin: 0 0 15px 0;
}
.select_test > li:first-child {
	margin-right: 15px;
}
.tu_nota {
	background-color: #fff;
	max-width: 555px;
	overflow: hidden;
}
.mi_cuenta_nota {
	padding: 20px 20px 0 20px;
}
.mi_cuenta_nota .circle-text {
	margin-top: 60px !important;
}
.mi_cuenta_nota .circle-info-half {
	margin-top: 100px !important;
}
.mi_cuenta_datos {
	padding: 0 0 20px 20px;
}
.mi_cuenta_datos p {
	margin-bottom: 5px;
}
.banners3 {
	text-align: center;
	padding-top: 30px;
}
.banners3 li {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	text-align: center;
	width: 100%;
	padding: 20px 15px;
	display: inline-block;
	vertical-align: top;
	border-bottom: solid 1px #F2F4F4;
	margin-bottom: 30px;
}
.banners3 li:nth-child(3) {
	border-bottom: none;
}
.banners3 img {
	margin-bottom: 10px;
}
.links i {
	font-size: 22px;
	padding-right: 10px;
	vertical-align: -4px;
}
.links a {
	display: inline-block;
	margin-right: 20px;
	margin-bottom: 20px;
}
.cont_menu_sub {
	background-color: #fff;
	padding: 20px 20px 10px 20px;
	margin-bottom: 20px;
}
.cont_menu_sub > p a {
	display: block;
}
.cont_menu_sub > p i {
	margin-top: 3px;
	float: right;
}
.menu_sub {
	display: none;
}
.menu_suscripcion,
.menu_sub {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 24px;
	color:#A5A5A7;
	text-align: center;
	margin-bottom: 30px;
	overflow: hidden;
}
.menu_suscripcion a,
.menu_sub a {
	display: block;
	padding: 10px;
}
.menu_suscripcion li,
.menu_sub li {
	border-bottom: solid 1px #DFE1E1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.menu_suscripcion li.active,
.menu_sub li.active,
.menu_suscripcion li:hover,
.menu_sub li:hover {
	border-bottom: solid 1px #EF3724;
	color: #2B2D33;
	font-size: 20px;
}
.descuento {
	border-bottom: solid 1px #DFE1E1;
	padding-bottom: 10px;
	margin-bottom: 10px;
}
.descuento  p {
	margin-bottom: 0;
}
.codigo_d {
	font-family: 'robotolight_italic', Arial, Helvetica, sans-serif;
	font-size: 14px;
	background-color: #F6F8F8;
	border: solid 1px #DFE1E1;
	border-radius: 5px;
	padding: 5px 15px;
	display: inline;
}
.documentos li {
	min-height: 40px;
}
.tabla_preferencias .segundo {
	padding-left: 20px;
}
/*---------Fin Mi cuenta---------*/

/*---------Table---------*/

.cont_table {
	border: solid 1px #DFE1E1;
	padding: 1px;
	background-color: #fff;
}
/*Table1*/
.table_1 {
	width: 100%;
}
.table_1 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}
@media (max-width: 991px) {
	.table_1 table,
	.table_1 thead,
	.table_1 tbody,
	.table_1 th,
	.table_1 td,
	.table_1 tr {
		display: block;
	}
	.table_1 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_1 td,
	.table_1 th {
		width: 100%;
	}
	.table_1 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_1 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_1 td:nth-of-type(1):before { content: "Número";}
	.table_1 td:nth-of-type(2):before { content: "Título"; }
	.table_1 td:nth-of-type(3):before { content: "Documentación"; }
	.table_1 td:nth-of-type(4):before { content: "Valoración"; }

	@media (max-width: 359px) {
		.table_1 td:nth-child(3) {
			padding-left: 60% !important;
		}
	}

	.table_1 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_1 .star {
		margin: 0;
	}
}
/*Table2*/
.table_2 {
	width: 100%;
}
.table_2 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}

@media (max-width: 991px) {
	.table_2 table,
	.table_2 thead,
	.table_2 tbody,
	.table_2 th,
	.table_2 td,
	.table_2 tr {
		display: block;
	}
	.table_2 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_2 td,
	.table_2 th {
		width: 100%;
	}
	.table_2 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_2 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_2 td:nth-of-type(1):before { content: "Cursos";}
	.table_2 td:nth-of-type(2):before { content: "Alumnos"; }
	.table_2 td:nth-of-type(3):before { content: "Costes"; }
	.table_2 td:nth-of-type(4):before { content: "Valoraciones"; }


	.table_2 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_2 .star {
		margin: 0;
	}
}
.table_2 .cuadrado {
	width: 28px;
	height: 28px;
	display: block;
	border: solid 1px #DFE1E1;
	background-color: #F2F4F4;
	position: relative;
}
.table_2 .cuadrado:before {
	content: '';
	width: 6px;
	height: 6px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -3px;
	margin-top: -3px;
	border-radius: 50%;

}
.table_2 .punto_verde:before {
	background-color: #9DBF15;
}
.table_2 .punto_rojo:before {
	background-color: #F23514;
}
.table_2 .punto_gris:before {
	background-color: #999999;
}

/*Table3*/
.table_3 {
	width: 100%;
}
.table_3 img {
	margin-right: 8px;
}
.table_3 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}

@media (max-width: 991px) {
	.table_3 table,
	.table_3 thead,
	.table_3 tbody,
	.table_3 th,
	.table_3 td,
	.table_3 tr {
		display: block;
	}
	.table_3 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_3 td,
	.table_3 th {
		width: 100%;
	}
	.table_3 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_3 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_3 td:nth-of-type(1):before { content: "Envíos";}
	.table_3 td:nth-of-type(2):before { content: "Fecha"; }
	.table_3 td:nth-of-type(3):before { content: "Aperturas"; }
	.table_3 td:nth-of-type(4):before { content: "% Aperturas"; }
	.table_3 td:nth-of-type(5):before { content: "Clics"; }
	.table_3 td:nth-of-type(6):before { content: "% Clics"; }


	.table_3 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
}
/*Table4*/
.table_4 {
	width: 100%;
}
.table_4 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}

@media (max-width: 991px) {
	.table_4 table,
	.table_4 thead,
	.table_4 tbody,
	.table_4 th,
	.table_4 td,
	.table_4 tr {
		display: block;
	}
	.table_4 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_4 td,
	.table_4 th {
		width: 100%;
	}
	.table_4 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_4 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_4 td:nth-of-type(1):before { content: "Alumnos";}
	.table_4 td:nth-of-type(2):before { content: "Cursos inscritos"; }
	.table_4 td:nth-of-type(3):before { content: "Cursos asistidos"; }
	.table_4 td:nth-of-type(4):before { content: "Valoraciones"; }


	.table_4 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_4 .star {
		margin: 0;
	}
}
/*Table5*/
.table_5 {
	width: 100%;
}
.table_5 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}

@media (max-width: 991px) {
	.table_5 table,
	.table_5 thead,
	.table_5 tbody,
	.table_5 th,
	.table_5 td,
	.table_5 tr {
		display: block;
	}
	.table_5 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_5 td,
	.table_5 th {
		width: 100%;
	}
	.table_5 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_5 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_5 td:nth-of-type(1):before { content: "Cursos";}
	.table_5 td:nth-of-type(2):before { content: "Fecha"; }
	.table_5 td:nth-of-type(3):before { content: "Ciudad"; }
	.table_5 td:nth-of-type(4):before { content: "Alumnos"; }
	.table_5 td:nth-of-type(5):before { content: "Costes"; }
	.table_5 td:nth-of-type(6):before { content: "Valoraciones"; }


	.table_5 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_5 .star {
		margin: 0;
	}
}
/*Table6*/
.table_6 {
	width: 100%;
}
.table_6 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}

@media (max-width: 991px) {
	.table_6 table,
	.table_6 thead,
	.table_6 tbody,
	.table_6 th,
	.table_6 td,
	.table_6 tr {
		display: block;
	}
	.table_6 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_6 td,
	.table_6 th {
		width: 100%;
	}
	.table_6 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_6 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_6 td:nth-of-type(1):before { content: "Cursos";}
	.table_6 td:nth-of-type(2):before { content: "Fecha"; }
	.table_6 td:nth-of-type(3):before { content: "Modalidad"; }
	.table_6 td:nth-of-type(4):before { content: "Estado"; }
	.table_6 td:nth-of-type(5):before { content: "Valoraciones"; }


	.table_6 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_6 .star {
		margin: 0;
	}
}
/*Table7*/
.table_7 {
	width: 100%;
}
.table_7 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}
.table_7 tbody tr td:first-child,
.table_7 thead tr th:first-child {
	text-align: left;
	padding-left: 15px;
}
.table_7 td {
	text-align: center;

}
.table_7 th {
	background-color: #F2F4F4;
	font-size: 15px;
	padding: 15px 5px;
	text-align: center;
}
.table_7 thead tr th:nth-child(1),
.table_7 tbody tr td:nth-child(1) {
	border-right: solid 1px #DFE1E1;

}
.table_7 tbody tr {
	border-top: solid 1px #DFE1E1;
}
.table_7 tbody tr:first-child {
	border-top: none;
}
/*Table8*/
.table_8 {
	width: 100%;
}
.table_8 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}

@media (max-width: 991px) {
	.table_8 table,
	.table_8 thead,
	.table_8 tbody,
	.table_8 th,
	.table_8 td,
	.table_8 tr {
		display: block;
	}
	.table_8 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_8 td,
	.table_8 th {
		width: 100%;
	}
	.table_8 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_8 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_8 td:nth-of-type(1):before { content: "Cursos";}
	.table_8 td:nth-of-type(2):before { content: "Alumnos"; }
	.table_8 td:nth-of-type(3):before { content: "Valoraciones"; }


	.table_8 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_8 .star {
		margin: 0;
	}
}
/*Table9*/
.table_9 {
	width: 100%;
}
.table_9 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}

@media (max-width: 991px) {
	.table_9 table,
	.table_9 thead,
	.table_9 tbody,
	.table_9 th,
	.table_9 td,
	.table_9 tr {
		display: block;
	}
	.table_9 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_9 td,
	.table_9 th {
		width: 100%;
	}
	.table_9 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_9 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_9 td:nth-of-type(1):before { content: "Fecha";}
	.table_9 td:nth-of-type(2):before { content: "Evento"; }
	.table_9 td:nth-of-type(3):before { content: "Título"; }

	.table_9 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_9 .star {
		margin: 0;
	}
}

/*Table10*/
.table_10 {
	width: 100%;
}
.table_10 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 15px 5px;
}
@media (max-width: 991px) {
	.table_10 table,
	.table_10 thead,
	.table_10 tbody,
	.table_10 th,
	.table_10 td,
	.table_10 tr {
		display: block;
	}
	.table_10 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_10 td,
	.table_10 th {
		width: 100%;
	}
	.table_10 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_10 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}

	.table_10 td:nth-of-type(1):before { content: "Fecha";}
	.table_10 td:nth-of-type(2):before { content: "Ciudad"; }
	.table_10 td:nth-of-type(3):before { content: "Estado"; }
	.table_10 td:nth-of-type(4):before { content: "Alumnos"; }
	.table_10 td:nth-of-type(5):before { content: "Ingresos"; }

	.table_10 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_10 .star {
		margin: 0;
	}
}
/*Table11*/
.titu_tabla_11 {
	background-color: #DFE1E1;
	border: solid 1px #DFE1E1;
	font-size: 15px;
	padding: 10px;
	margin-bottom: 1px;
}
.table_11 {
	width: 100%;
}
.table_11 td {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 14px;
	padding: 10px 5px;
}
@media (max-width: 991px) {
	.table_11 table,
	.table_11 thead,
	.table_11 tbody,
	.table_11 th,
	.table_11 td,
	.table_11 tr {
		display: block;
	}
	.table_11 thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table_11 td,
	.table_11 th {
		width: 100%;
	}
	.table_11 td {
		position: relative;
		padding-left: 50% !important;
	}
	.table_11 td:before {
		position: absolute;
		top: 6px;
		left: 10px;
		width: 45%;
		padding:  3px 10px 0 0;
		white-space: nowrap;
		font-size: 15px;
		font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	}
	.table_11 td:nth-of-type(1):before { content: "Sesiones";}
	.table_11 td:nth-of-type(2):before { content: "Fecha";}
	.table_11 td:nth-of-type(3):before { content: "Ciudad"; }
	.table_11 td:nth-of-type(4):before { content: "Estado"; }
	.table_11 td:nth-of-type(5):before { content: "Alumnos"; }
	.table_11 td:nth-of-type(6):before { content: "Ingresos"; }

	.table_11 tbody tr:nth-child(2n+1) {
		background-color: #F2F4F4;
	}
	.table_11 .star {
		margin: 0;
	}
}
/*---------Fin Table---------*/

/*---------Bono F5---------*/
.bono_f5 {
	background-image: url(../img/fondo_bono_f5.png);
	background-size: cover;
	background-position: center;
}
.titul_cabecera_imagen {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 32px;
	line-height: 36px;
	margin-bottom: 20px;
}
.subtitul_cabecera_imagen {
	font-size: 16px;
	margin-bottom: 20px;
}
.comprar_f5 {
	padding: 20px 20px 30px 20px;
	color: #fff;
}
.comprar_f5 p:nth-child(1) {
	margin-top: 10px;
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
	font-size: 18px;
}
.comprar_f5 p:nth-child(2) {
	margin-bottom: 20px;
}
.activar_f5 {
	padding: 20px 20px 30px 20px;
}
.activar_f5 p:nth-child(1) {
	margin-top: 10px;
	font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
	font-size: 18px;
}
.activar_f5 p:nth-child(2) {
	margin-bottom: 20px;
}
/*---------Fin Bono F5---------*/


/*---------Unlimited---------*/
/*.unlimited {*/
/*	background-image: url(../img/fondo_unlimited.png);*/
/*	background-size: cover;*/
/*	background-position: center;*/
/*}*/
/*.unlimited_inscripcion {*/
/*	background-image: url(../img/cabecera_unlimited_inscripcion.png);*/
/*	background-size: cover;*/
/*	background-position: center;*/
/*}*/
.titul_unlimited {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 32px;
	line-height: 36px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.subtitul_unlimited {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 16px;
	margin-bottom: 20px;
}
.precios_unlimited {
	background-color: #fff;
	border-radius: 5px;
	text-align: center;
	padding-bottom: 30px;
	margin-bottom: 20px;
}
.precios_unlimited header {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 30px;
	border-radius: 5px 5px 0 0;
	color: #fff;
	text-align: center;
	padding: 50px 0;
}
.precios_unlimited ul {
	width: 220px;
	margin: 25px auto 20px auto;
}
.precios_unlimited li {
	position: relative;
	line-height: 18px;
	padding: 5px 12px 5px 35px;
	background-image: url(../img/marca.svg);
	background-repeat: no-repeat;
	background-position: 12px 10px;
	text-align: left;
}
.precios_unlimited .btn-green {
	width: 220px;
}
.condiciones_unlimited {
	width: 90%;
	margin: 0 auto;
	text-align: left;
	font-size: 16px;
}
.condiciones_unlimited li {
	margin-bottom: 20px;
}
/*---------Fin Unlimited---------*/


/*---------aviso legal---------*/
.texto {
	font-size: 15px;
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	line-height: 22px;
}
.texto p {
	margin-bottom: 22px;
}
/*---------Fin aviso legal---------*/


/*---------Ebook---------*/
.cabecera_ebook {
	background-image: url(../img/fondo_ebook.png);
	background-size: cover;
	background-position: center;
}
.titul_ebook {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 32px;
}
.subtitul_ebook {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 16px;
	margin-bottom: 20px;
}
.ebook_destacado {
	background-color: #fff;
	overflow: hidden;
	text-align: center;
}
.ebook_destacado img {
	width: 100%;
	max-width: 340px;
}
.ebook_destacado .ebook_info {
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 15px;
	padding: 20px;
}
.ebook_destacado .titulo {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 18px;
}
.ebook_destacado .subtitulo {
	font-size: 16px;
}
.ebook_destacado .footer {
	font-size: 15px;
	margin-top: 20px;
}
.ebook_destacado .footer a {
	text-decoration: underline;
}
.ebook {
	background-color: #fff;
	text-align: center;
}
.margen_mobile {
	margin-bottom: 20px;
}
.ebook img {
	width: 100%;
	max-width: 340px;
}
.ebook .ebook_info {
	padding: 20px;
}
.ebook .titulo {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 22px;
	text-align: left;
	line-height: 26px;
}
.ebook .texto {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
	text-align: left;
}
.ebook .texto a {
	text-decoration: underline;
}
.inicio_sesion {
	font-family: 'robotolight', Arial, Helvetica, sans-serif;
	font-size: 15px;
}
.inicio_sesion a {
	font-family: 'robotomedium', Arial, Helvetica, sans-serif;
	text-decoration: underline;
}
.ebook .form-control,
.ebook_destacado .form-control {
	width: 100%;
	max-width: 320px;
	margin: 0 auto;
	margin-bottom: 20px;
}
/*---------Fin Ebook---------*/

.alto_cabecera_imagen {
	height: 150px;
	min-height: 150px;
	display: table;
	width: 100%;
}
.alto_cabecera_imagen_con_breadcrumb {
	height: 104px;
	min-height: 104px;
	display: table;
	width: 100%;
}




/*------------------------------------------------------- SM ------------------------------------------------------------------*/
@media (min-width: 768px) {

	.cabecera_blanca {
		min-height: 230px;
	}

	/*---------cookies---------*/
	.cookies {
		font-size: 14px;
		padding-top: 20px;
		padding-right: 60px;
	}

	/*---------Fin cookies---------*/
	/*---------Espacios (margenes)---------*/
	.espacio-bottom-bloque1 {
		margin-bottom: 20px;
	}

	.espacio-bottom-bloque2 {
		margin-bottom: 40px;
	}

	.espacio-bottom-bloque3 {
		margin-bottom: 30px;
	}

	/*---------Espacios (margenes)---------*/
	/*---------Titulos---------*/
	.titulo_1 {
		font-size: 36px;
		line-height: 40px;
		font-family: 'roboto_condensedlight', Arial, Helvetica, sans-serif;
		margin-bottom: 20px;
	}

	.titulo_1_2 {
		font-size: 36px;
		line-height: 40px;
		font-family: 'roboto_condensedlight', Arial, Helvetica, sans-serif;
		margin-bottom: 3px;
	}

	.titulo_2 {
		font-size: 28px;
		line-height: 32px;
		margin-bottom: 20px;
	}

	.titulo_3 {
		font-size: 36px;
		line-height: 40px;
		margin-bottom: 20px;
	}

	.titulo_2_1 {
		font-size: 28px;
		line-height: 32px;
	}

	.titulo_2_2 {
		font-size: 21px;
		line-height: 25px;
		margin-bottom: 10px;
	}

	.titulo_2_3 {
		font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
		font-size: 21px;
		line-height: 24px;
		margin-bottom: 10px;
	}

	.titulo_5 {
		font-size: 18px;
		line-height: 24px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.titulo_6 {
		font-size: 58px;
		line-height: 62px;
		margin-bottom: 20px;
	}

	.titulo_7 {
		font-size: 58px;
		line-height: 62px;
		margin-bottom: 20px;
	}

	.titulo_8 {
		font-size: 60px;
		line-height: 64px;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.subtitulo_1 {
		width: 90%;
		margin: 0 auto;
		font-size: 16px;
		line-height: 20px;
		font-family: 'robotolight', Arial, Helvetica, sans-serif;
		margin-bottom: 30px;
	}

	.subtitulo_1_1 {
		font-size: 16px;
	}

	.subtitulo_2 {
		margin-bottom: 50px;
		font-size: 16px;
	}

	.subtitulo_3 {
		font-size: 20px;
		line-height: 24px;
	}

	.subtitulo_3_1 {
		font-size: 15px;
		margin-bottom: 50px;
	}

	/*---------Fin Titulos---------*/
	/*---------Cabecera---------*/
	.logo {
		position: inherit;
		float: left;
		height: 66px;
		width: 140px;
		margin-left: 45px;
		padding-right: 0;
		padding-left: 0;
	}

	.buscador {
		float: right;
		height: 68px;
		width: 58px;
		line-height: 68px;
		text-align: center;
		border-left: solid 1px #f2f4f4;
	}

	.buscador i {
		font-size: 18px;
		color: #A5A6A8;
		line-height: 68px;
	}

	.menu-cabecera {
		float: right;
		border-left: none;
		padding-left: 0;
	}

	.menu-cabecera a, .menu-cabecera a:hover {
		color: #000;
	}

	.telf {
		font-family: 'robotomedium', Arial, Helvetica, sans-serif;
		float: right;
		line-height: 68px;
		text-align: center;
		border-left: solid 1px #f2f4f4;
		color: #ef3724;
		padding: 0 20px;
		font-size: 15px;
	}

	.telf img {
		padding-right: 8px;
		vertical-align: -13%;
	}

	.favoritos, .carrito {
		position: relative;
		float: right;
		font-size: 24px;
		width: 61px;
		height: 68px;
		line-height: 68px;
		text-align: center;
		border-left: solid 1px #f2f4f4;
	}

	.acceso {
		float: right;
		line-height: 68px;
		border-right: solid 1px #f2f4f4;
		border-left: solid 1px #f2f4f4;
		color: #2b2e33;
		text-align: left;
	}

	.acceso a {
		display: inline-block;
		width: auto;
		padding: 0 20px;
	}

	.acceso a:nth-child(1) {
		border-right: solid 1px #f2f4f4;
	}

	.img-usuario {
		width: 70px;
		height: 68px;
		float: right;
		line-height: 68px;
		border-right: solid 1px #f2f4f4;
		border-left: solid 1px #f2f4f4;
		text-align: center;
		position: relative;
	}

	.img-usuario img {
		width: 50px;
	}

	.notificacion {
		position: relative;
		float: right;
		font-size: 24px;
		width: 70px;
		height: 68px;
		line-height: 68px;
		text-align: center;
		border-left: solid 1px #f2f4f4;
	}

	.carrito > span,
	.notificacion > span {
		position: absolute;
		top: 15%;
		left: 50%;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #EF3724;
		font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
		font-size: 14px;
		color: #fff;
		text-align: center;
		line-height: 23px;
		cursor: pointer;
	}

	.log {
		width: 70px;
	}

	/* Desplegables */
	.desple-mi-cuenta {
		display: none;
		position: absolute;
		width: 180px;
		top: 80%;
		right: 10%;
		background: #fff;
		border: 1px solid #f2f4f4;
		z-index: 999;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		text-align: center;
	}

	.desple-mi-cuenta a {
		display: block;
		line-height: 60px;
	}

	.mi_cuenta {
		font-size: 16px;
	}

	.mi_cuenta i {
		color: #EF3724;
		font-size: 22px;
		vertical-align: sub;
		margin-right: 5px;
	}

	.cerrar_sesion {
		border-top: solid 1px #f2f4f4;
		color: #ccc !important;
		text-decoration: underline !important;
	}

	.desple-notificaciones {
		display: none;
		position: absolute;
		width: 360px;
		top: 80%;
		right: 10%;
		background: #fff;
		border: 1px solid #f2f4f4;
		z-index: 999;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		text-align: left;
		padding: 15px 2px 30px 15px;
		line-height: 18px;
		font-size: 14px;
	}

	.scroll {
		padding-right: 13px;
		max-height: 400px;
		overflow: scroll;
	}

	.scroll2 {
		padding-right: 13px;
		max-height: 300px;
		overflow: scroll;
	}

	.flecha-mas-contenido {
		background-image: url(../img/arrows-down.svg);
		background-repeat: no-repeat;
		background-position: center bottom 2px;
		background-size: 25px;

	}

	.desple-notificaciones h4 {
		font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
		font-size: 17px;
		padding-bottom: 10px;
		padding-top: 30px;
	}

	.desple-notificaciones h4:first-child {
		padding-top: 0px;
	}

	.desple-notificaciones li {
		position: relative;
		border-bottom: solid 1px #DFE1E1;
		padding: 10px 10px 2px 10px;
	}

	.desple-notificaciones li:nth-child(2n+1) {
		background-color: #F6F8F8;
	}

	.desple-notificaciones li > span {
		font-size: 20px;
		position: absolute;
		right: 8px;
		display: block;
		z-index: 9;
	}

	.espacio-cerrar {
		padding-right: 20px;
	}

	.desple-notificaciones p {
		position: relative;
		margin-bottom: 8px;
	}

	.desple-notificaciones .fecha {
		padding-left: 32px;
	}

	.desple-notificaciones .fecha:before {
		font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
		content: "\e669";
		font-size: 24px;
		color: #A5A6A8;
		position: absolute;
		left: 0;
		top: 6px;
	}

	.desple-carrito {
		display: none;
		position: absolute;
		width: 400px;
		top: 80%;
		right: 10%;
		background: #fff;
		z-index: 999;
		-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		text-align: left;
		line-height: 18px;
		font-size: 14px;
	}

	.desple-carrito ul {

	}

	.desple-carrito li {
		border-bottom: solid 1px #f2f4f4;
		padding: 20px;
		display: table;
	}

	.datos-carrito {
		width: 170px;
		margin-right: 20px;
		display: inline-block;
	}

	.desple-carrito p {
		margin-bottom: 4px;

	}

	.desple-carrito .unidades {
		width: 65px;
		display: table-cell;
		vertical-align: middle;
		position: relative;
	}

	.desple-carrito .unidades input {
		background-color: #F2F4F4;
		border: 1px solid #DFE1E1;
		border-right: none;
		border-radius: 4px 0 0 4px;
		width: 45px;
		height: 40px;
		float: left;
		text-align: center;
	}

	.desple-carrito .btn-mas {
		position: absolute;
		top: 50%;
		margin-top: -20px;
		width: 20px;
		height: 20px;
		background-color: #F2F4F4;
		border: 1px solid #DFE1E1;
		border-bottom: none;
		border-radius: 0 4px 0 0;
		background-image: url(../img/btn-mas.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 10px;
	}

	.desple-carrito .btn-menos {
		position: absolute;
		top: 50%;
		width: 20px;
		height: 20px;
		background-color: #F2F4F4;
		border: 1px solid #DFE1E1;
		border-radius: 0 0 4px 0;
		background-image: url(../img/btn-menos.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 10px;
	}

	.desple-carrito .precio {
		width: 93px;
		padding-right: 20px;
		font-family: 'robotobold', Arial, Helvetica, sans-serif;
		font-size: 18px;
		display: table-cell;
		vertical-align: middle;
		text-align: right;
	}

	.desple-carrito .papelera {
		width: 18px;
		display: table-cell;
		vertical-align: middle;
		font-size: 18px;
	}

	.desple-carrito .papelera i {
		color: #A5A6A8;
	}

	.desple-carrito .total {
		font-family: 'robotolight', Arial, Helvetica, sans-serif;
		line-height: 70px;
		font-size: 15px;
		text-align: right;
		padding-right: 58px;
	}

	.desple-carrito .docente,
	.desple-carrito .fecha {
		line-height: 16px;
		font-size: 13px;
		color: #EF3724;
	}

	.desple-carrito .curso {
		font-family: 'robotomedium', Arial, Helvetica, sans-serif;
		line-height: 16px;
	}


	.desple-mi-cuenta:after, .desple-mi-cuenta:before {
		left: 151px;
	}

	.desple-notificaciones:after, .desple-notificaciones:before {
		left: 332px;
	}

	.desple-carrito:after, .desple-carrito:before {
		left: 374px;
	}

	.desple-mi-cuenta:after, .desple-mi-cuenta:before,
	.desple-notificaciones:after, .desple-notificaciones:before,
	.desple-carrito:after, .desple-carrito:before {
		bottom: 100%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	.desple-mi-cuenta:after,
	.desple-notificaciones:after,
	.desple-carrito:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: 10px;
		margin-left: -10px;
	}

	.desple-mi-cuenta:before,
	.desple-notificaciones:before,
	.desple-carrito:before {
		border-color: rgba(242, 244, 244, 0);
		border-bottom-color: #f2f4f4;
		border-width: 11px;
		margin-left: -11px;
	}

	/* Fin Desplegables */
	.cont_btn_f {
		float: right;
		padding: 14px 0 0 29px;
	}

	/*---------Fin Cabecera---------*/
	/*---------Menu principal---------*/
	.cont-menu ul {
		border-top: none;
		margin-top: -10px;
	}

	/*---------Fin menu principal---------*/
	/*---------Slider testimoniales---------*/
	#owl-slider-testimoniales .owl-wrapper-outer {
		background-image: url(../img/Home_Pic_Users.jpg);
	}

	#owl-slider-testimoniales .item {
		height: 450px;
		color: #fff;
		text-align: center;
	}

	#owl-slider-testimoniales .item h2 {
		font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
		padding: 60px 0 30px 0;
		font-size: 32px;
		line-height: 36px;
	}

	#owl-slider-testimoniales .item .txt {
		position: relative;
		width: 570px;
		margin: 0 auto 15px auto;
		color: #2B2D33;
		background-color: #fff;
		padding: 35px;
		font-family: 'robotolight', Arial, Helvetica, sans-serif;
		font-size: 18px;
		line-height: 26px;
		border-radius: 4px;
	}

	#owl-slider-testimoniales .item .nombre {
		font-size: 17px;
	}

	#owl-slider-testimoniales .item .txt:after {
		bottom: -20px;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255, 255, 255, 0);
		border-top-color: #fff;
		border-width: 10px;
		margin-left: -5px;
	}

	#owl-slider-testimoniales .owl-controls .owl-buttons {
		display: none;
	}

	/*---------Fin Slider testimoniales---------*/
	/*---------Slider home---------*/
	#owl-slider-home .item {
		height: 315px;
		padding-top: 20px;
	}

	#owl-slider-home ul li {
		display: inline-block;
		font-size: 21px;
		line-height: 25px;
		padding: 5px 30px 0 30px;
		margin-bottom: 20px;
	}

	#owl-slider-home ul li:nth-child(2) {
		border-left: solid 1px #fff;
		border-right: solid 1px #fff;
	}

	.t-s {
		font-size: 12px;
	}

	.btn-slider {
		padding: 10px 30px;
		margin: 10px 0 25px 0;
	}

	.titu-slider {
		font-size: 48px;
		line-height: 48px;
	}

	.sub-titu-slider {
		font-size: 19px;
		line-height: 30px;
	}

	/*---------Slider home---------*/
	/*---------Slider home login---------*/
	#owl-slider-home-login .item {
		height: 315px;
		padding-top: 70px;
		font-size: 20px;
	}

	#owl-slider-home-login h2 {
		font-size: 48px;
		line-height: 52px;
		margin-bottom: 10px;
	}

	/*---------Fin Slider home login---------*/
	/*---------Destacado update---------*/
	.destacado_update {
		margin-top: 10px;
		width: 100%;
		text-align: center;
		align-items: center;
		display: flex;
		padding: 20px;
	}

	.destacado_update li {
		margin-bottom: 1px;
	}

	.destacado_update .btn {
		width: 220px;
		margin-top: 10px;
	}

	.centrado_update {
		width: 220px;
		margin: 0 auto;
	}

	/*---------Fin Destacado update---------*/
	/*---------Bloque Por donde Empiezo---------*/
	.num {
		margin: 0 0 10px 0;
		border-bottom: 3px solid #EF3724;
		float: left;
	}

	.empiezo {
		text-align: left;
		border-bottom: none;
		padding-bottom: 10px;
		padding-top: 10px;
		margin-bottom: 40px;
		margin-top: 20px;
	}

	.empiezo > .edit {
		width: 173px;
		padding-left: 10px;
		float: left;
	}

	.p-donde .col-xs-12:nth-child(3) {
		border-left: solid 1px #F2F4F4;
		border-right: solid 1px #F2F4F4;
	}


	/*---------Fin Bloque Por donde Empiezo---------*/
	/*---------Bloque Temáticas---------*/
	.tematicas h2 {
		font-size: 20px;
	}

	.tematicas ul {
		margin: 30px 0 40px 0px;
	}

	.tematicas ul li {
		width: 14.66%;
		height: 140px;
		line-height: inherit;
		margin-bottom: 0;
		text-align: center;
	}

	.tematicas ul img {
		display: inline-block;
		line-height: 90px;
		width: 100%;
		font-size: 42px;
		color: #fff;
	}

	.tematicas ul li h3 {
		display: block;
		line-height: 16px;
		font-size: 14px;
	}

	/*---------Fin Bloque Temáticas---------*/
	/*---------Bloque Asesoramiento---------*/
	.asesoramiento h2 {
		font-size: 26px;
		line-height: 28px;
	}

	.asesoramiento .form-group {
		padding-top: 20px;
		min-height: 136px;
	}

	.asesoramiento .form-group label {
		width: 280px;
		max-width: 280px;
		margin-right: 0;
		margin-left: 0;
		float: left;
	}

	.asesoramiento .form-group label:nth-child(1),
	.asesoramiento .form-group label:nth-child(2) {
		margin-bottom: 15px;
	}

	.asesoramiento .form-group label:nth-child(2n+1) {
		margin-right: 30px;
		margin-left: 50px;
	}

	.interno .asesoramiento .form-group label:nth-child(1),
	.interno .asesoramiento .form-group label:nth-child(2),
	.interno .asesoramiento .form-group label:nth-child(3),
	.interno .asesoramiento .form-group label:nth-child(4) {
		float: inherit;
		margin-right: 0;
		margin-left: 0;
		margin: 0 auto 10px auto;
	}

	.asesoramiento_ok {
		padding-top: 130px;
		height: 270px;
		background-position: center 100px;
	}

	.assesorament_registrat label {
		margin: 0 0 10px 0;
	}

	.column_asse_regis > li {
		width: 45%;
	}

	.column_asse_regis > li:nth-child(1) {
		margin-right: 20px;
		border: none;
	}

	/*---------Fin Bloque Asesoramiento---------*/
	/*---------Bloque PorQue Foxize---------*/
	.modulo-PQFoxize1, .modulo-PQFoxize2 {
		border-bottom: none;
	}

	.line-right {
		background-image: url(../img/linea.gif);
		background-repeat: no-repeat;
		background-position: right 50px;
		background-size: 1px 240px;

	}

	/*---------Fin Bloque PorQue Foxize---------*/
	/*---------Footer---------*/
	.row-footer {
		padding: 50px 0 60px 0;
	}

	.logo-footer {
		margin-bottom: 30px;
	}

	.row-footer .contacto p {
		padding-bottom: 20px;
	}

	.colum-1,
	.colum-2,
	.colum-3 {
		float: left;
		width: 33%;
		min-height: 215px;
	}

	.colum-1 li,
	.colum-3 li {
		margin-bottom: 10px;
	}

	.colum-2 li {
		margin-bottom: 37px;
	}

	.link-footer-a {
		font-family: 'roboto_condensedbold', Arial, Helvetica, sans-serif;
		font-size: 18px;
		text-transform: uppercase;
	}

	.border-left {
		border-left: solid 1px #f2f4f4;
	}

	.metodos-pago {
		width: 100%;
	}

	.txt-aceptamos {
		font-size: 12px;
	}

	.pago {
		float: left;
		height: 40px;
		padding: 8px 10px 0 0;
	}

	.l-contacto {
		border-color: #fff;
		display: block;
		margin-top: 0px;
	}

	.l-newsletter-redes-empresa {
		border-color: #fff;
		margin-top: 20px;
	}

	.colum-newsletter, .colum-redes, .colum-empresa {
		float: left;
	}

	.colum-newsletter {
		width: 285px;
	}

	.colum-redes {
		width: 250px;
	}

	.colum-empresa {
		width: 185px;
	}

	.redes {
		margin-right: 8px;
	}

	.colum-empresa p {
		margin-bottom: 0;
		margin-top: 10px;
	}

	.newsletter-redes-empresa .form-group {
		max-width: 250px;
	}

	.menu-footer-azul {
		float: left;
		width: 650px;
		border-top: solid 1px #dfe1e1;
		padding-top: 20px;
		margin-top: 66px;
	}

	.menu-footer-azul ul {
		float: left;
		width: 450px;
	}

	.menu-footer-azul ul li {
		float: left;
		margin-right: 20px;
	}

	.copi {
		width: 200px;
		display: inline-block;
		text-align: right;
	}

	.colum-empresa .btn-small {
		font-size: 12px;
	}

	/*---------Fin Footer---------*/
	/*---------Login---------*/
	.centrado-login {
		width: 720px;
		margin: 30px auto 0 auto;
		overflow: hidden;
	}

	.nav-login,
	.marco {
		padding: 20px 15px 0 15px;
	}

	.soy-usuario {
		width: 345px;
		float: left;
		margin-right: 30px;
		min-height: 500px;
		padding-top: 20px;
		text-align: center;
	}

	.soy-usuario h1 {
		text-align: left;
	}

	.nuevo {
		width: 345px;
		float: left;
		min-height: 500px;
	}

	.nuevo img {
		width: 100%;
	}

	.soy-nuevo {
		margin: 0;
	}

	.unete {
		margin: 10px 0 0 0;
		min-height: 80px;
	}

	/*---------Fin login---------*/
	/*---------Registro---------*/
	.registro .bootstrap-select {
		max-width: 250px;
		float: left !important;
	}

	.registro,
	.registro-ok {
		width: 620px;
		padding: 20px 30px;
		margin: 0 auto;
	}

	.registro-ok h2 {
		padding-bottom: 40px;
	}

	.registro-ok h1 {
		padding-bottom: 5px;
	}

	/*---------Fin Registro---------*/
	/*---------Mi cuenta ---------*/
	.mi-cuenta {
		margin-top: 10px;
		padding: 20px;
		line-height: 22px;
		overflow: hidden;
	}

	.mi-cuenta .btn {
		width: 220px;
		float: right;
		margin-top: 24px;
	}

	.txt-mi-cuenta {
		width: 420px;
		float: left;
	}

	.mi-cuenta p {
		margin: 0;
	}

	.links {
		text-align: right;
	}

	/*---------Fin Mi cuenta ---------*/
	/*---------modulo recurso ---------*/
	.modulo-recurso img {
		width: 100%;
	}

	.modulo-recurso footer {
		position: absolute;
		width: 100%;
		height: 100px;
		color: #fff;
		top: 190px;
		width: 100%;
		padding: 10px;
		transition: .7s;
		background-color: #2B2E33;
	}

	.modulo-recurso footer h5 {
		font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 10px;
	}

	.modulo-recurso footer p {
		line-height: 22px;
		font-size: 16px;
	}

	.modulo-recurso:hover footer {
		top: 148px;
	}

	.tooltip_unlimited:hover:after {
		width: 230px;
	}

	.tooltip_f5:hover:after {
		width: 202px;
	}

	/*---------Fin modulo recurso ---------*/
	/*---------modulo test ---------*/
	.modulo-test h3,
	.modulo-test h2 {
		font-size: 18px;
	}

	/*---------Fin modulo test ---------*/
	/*---------Buscador cursos ---------*/
	.desplegable_filtro_avanzado {
		display: block;
		margin-top: 0;
	}

	.titulo_filtro {
		margin-bottom: 25px;
	}

	.titulo_filtro span {
		display: none;
	}

	.titulo_filtro_rapido {
		font-size: 13px;
		margin-bottom: 2px;
	}

	.filtro_rapido .selct_busc > li {
		width: 130px;
		display: inline-block;
		padding: 5px 0;
	}

	.filtro_rapido2 .selct_busc > li {
		width: 190px;
		display: inline-block;
		padding: 5px 0;
	}

	.filtro_rapido .selct_busc > li:nth-child(1),
	.filtro_rapido2 .selct_busc > li:nth-child(1) {
		margin-right: 10px;
	}

	.filtro_rapido .selct_busc > li:nth-child(3) {
		float: right;
		width: 155px;
		padding-left: 20px;
		margin-left: 17px;
		border-left: solid 1px #A5A6A8;
	}

	.comprados {
		float: left;
		border-left: solid 1px #A5A6A8;
		padding-left: 19px;
		margin-left: 9px;
	}

	.filtro_rapido .bootstrap-select,
	.filtro_rapido2 .bootstrap-select {
		margin-bottom: 0;
		margin-right: 0;
		height: 40px;
	}

	.filtro_rapido .bootstrap-select > .dropdown-toggle,
	.filtro_rapido2 .bootstrap-select > .dropdown-toggle {
		height: 40px;
		border-color: #dfe1e1;
		background-color: #F6F8F8;
		font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
		font-size: 15px;
		padding-top: 7px;
		padding-left: 8px;
		border-radius: 5px;
	}

	.filtro_rapido .bootstrap-select,
	.filtro_rapido2 .bootstrap-select {
		border-radius: 5px;
	}

	.m-l,
	.m-l li {
		float: left;
	}

	.m-l li:nth-child(2),
	.m-l li:nth-child(3) {
		margin-left: 15px;
	}

	/*---------Fin Buscador cursos ---------*/
	/*---------Como funciona ---------*/
	.como_funciona_fxz_unlimited,
	.como_funciona_bono_f5 {
		float: left;
		width: 50%;
	}

	.como_funciona_fxz_unlimited {
		padding-right: 20px;
		border-bottom: none;
		border-right: solid 1px #ccc;
		margin-bottom: 10px;
	}

	.como_funciona_bono_f5 {
		padding-left: 20px;
	}

	.mt_espcial {
		margin-top: 98px;
	}

	/*---------Fin Como funciona ---------*/
	/*---------Modal ---------*/
	.modal_favoritos {
		padding-bottom: 90px;
	}

	/*---------Fin Modal ---------*/
	/*---------Ficha curso ---------*/
	.partner_circle {
		right: 20px;
		top: 20px;
		width: 70px;
	}

	.check_add_favoritos label {
		font-size: 14px;
	}

	#owl-alumnos .item ul li {
		margin: 10px 1px 13px 1px;
	}

	.img-cabecera-curso {
		margin-bottom: 0;
	}

	.redes_curso li {
		margin-left: 3px;
		font-size: 11px;
		color: #fff;
		text-align: right;
	}

	.btn-twitter,
	.btn-facebook2,
	.btn-linkedin,
	.btn-correo {
		width: 105px;
		height: 30px;
		line-height: 30px;
		padding-right: 10px;
		background-size: 15px;
		background-position: 7px center;
	}

	.btn-correo {
		width: 94px;
	}

	.btn-facebook2 {
		background-size: 7px;
	}

	.opiniones img {
		float: left;
		margin-right: 20px;
	}

	.comentario {
		margin-top: 0;
		width: 360px;
		float: left;
	}

	.opiniones ul {
		margin-left: 70px;
	}

	.comentario_2 {
		margin-top: 0;
		float: left;
		width: 290px;
	}

	.comentario:before,
	.comentario_2:before {
		top: 15px;
		left: -20px;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(242, 244, 244, 0);
		border-right-color: #F6F8F8;
		border-width: 10px;
	}

	.head_sidebar > div span {
		font-size: 12px;
	}

	.fecha_lugar_curso li {
		padding-left: 28px;
	}

	.pertenece_programa a {
		width: 200px;
		margin-left: auto;
		margin-right: auto;
	}

	.apuntarme > div span {
		font-size: 9px;
		margin: 16px 0 0 -90px;
	}

	.cuartos {
		width: 365px;
	}

	.cuartos li {
		width: 24.5%;
	}

	.partes_curso header {
		padding: 15px 20px;
	}

	.parte li {
		overflow: hidden;
		padding: 20px;
	}

	.parte li p {
		float: left;
		margin-bottom: 0;
	}

	.parte li a {
		float: right;
		width: 220px;
	}

	.busq_programas_ciudad .bootstrap-select {
		margin-bottom: 0;
		margin-right: 0;
		height: 40px;
	}

	.busq_programas_ciudad2 .bootstrap-select {
		height: 40px;
	}

	.busq_programas_ciudad .bootstrap-select > .dropdown-toggle,
	.busq_programas_ciudad2 .bootstrap-select > .dropdown-toggle {
		height: 40px;
		border-color: #dfe1e1;
		background-color: #F6F8F8;
		font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
		font-size: 15px;
		padding-top: 7px;
		padding-left: 8px;
		border-radius: 5px;
	}

	.busq_programas_ciudad .bootstrap-select {
		border-radius: 5px;
	}

	.busq_programas_ciudad > p {
		float: left;
		font-size: 14px;
	}

	.titu_datos_curso {
		font-size: 21px;
	}

	.centra_verti span {
		background-color: inherit;
		margin-bottom: 5px;
		display: inline-block;
	}

	.cabecera_titulo {
		font-size: 26px;
		line-height: 30px;
	}

	.cabecera_titulo p {
		font-size: 16px;
	}

	.cabecera_titulo h1 span {
		font-size: 28px;
	}

	.espacio-bottom-especial {
		margin-bottom: 30px;
	}

	.espacio_img_partner {
		border: none;
	}

	.txt_resaltado {
		width: 50%;
		right: 0;
		bottom: 104px;
	}

	.curso_programa {
		overflow: hidden;
	}

	.c_p_titulo {
		font-size: 18px;
		line-height: 22px;
		padding-bottom: 1px;
	}

	.c_p_profesor {
		font-size: 15px;
	}

	.c_p_2 {
		border-right: solid 1px #ccc;
	}

	.c_p_2,
	.c_p_3 {
		float: left;
		width: 50%;
	}

	.c_p_tematica {
		height: 66px;
	}

	.c_p_nivel,
	.c_p_valoracion {
		height: 70px;
	}

	.p_p_altura {
		height: 85px;
	}

	.plazas {
		padding-top: 8px;
	}

	/*---------Fin Ficha curso ---------*/
	/*---------Itinerario ---------*/
	.img_itinerario {
		float: right;
		width: 300px;
	}

	.descripcion_itinerario {
		float: left;
		width: 420px;
	}

	.separacion_itinerario {
		float: left;
		width: 100%;
	}

	.disponibilidad_itinerario {
		float: left;
		width: 100%;
	}

	.disponibilidad_itinerario li {
		float: left;
		margin-left: 40px;
	}

	.disponibilidad_itinerario ul {
		padding-left: 0;
	}

	/*---------Fin Itinerario ---------*/
	/*---------Temática ---------*/
	.tematica {
		margin-bottom: 30px;
		height: 380px;
	}

	.subtematica {
		margin-bottom: 30px;
		height: 350px;
	}

	/*---------Fin Temática ---------*/
	/*---------Planes precio ---------*/
	.planes_precio {
		background-color: #fff;
		margin-bottom: 10px;
	}

	/*---------Fin Planes precio ---------*/
	/*---------Regala formación---------*/
	.regala_formacion {
		font-size: 14px;
		overflow: hidden;
	}

	.regala_formacion h6 {
		font-size: 20px;
		line-height: 24px;
		float: left;
		width: 240px;
	}

	.regala_formacion img {
		width: 270px;
		float: left;
	}

	.regala_formacion p {
		float: left;
		width: 240px;
	}

	.regala_formacion article {
		float: left;
		width: 448px;
		padding: 15px 20px 0 20px;
	}

	.regala_formacion article a {
		float: right;
		margin: -10px 0 0 0;
	}

	/*---------Fin Regala formación---------*/
	/*---------Upgradebox---------*/
	.upgradebox {
		margin-bottom: 0;
		min-height: 400px;
	}

	.upgradebox p {
		margin-bottom: 20px;
	}

	.mt30xs {
		margin-top: 0;
	}

	/*---------Fin Upgradebox---------*/
	/*---------Profesores---------*/
	.f-profesores {
		padding-top: 50px;
	}

	.banners_maestros {
		text-align: center;
		padding-top: 30px;
	}

	.banners_maestros li {
		width: 32.6%;
		min-height: 230px;
		padding: 20px 15px;
		border-bottom: none;
		border-left: solid 1px #F2F4F4;
		margin-bottom: 30px;
	}

	.banners_maestros li:first-child {
		border: none;
	}

	.busq_profesores_ciudad {
		padding: 15px 10px;
		margin-bottom: 30px;
	}

	.busq_profesores_ciudad .bootstrap-select {
		margin-bottom: 0;
		margin-right: 0;
		height: 40px;
	}

	.busq_profesores_ciudad .bootstrap-select > .dropdown-toggle {
		height: 40px;
		border-color: #dfe1e1;
		background-color: #F6F8F8;
		font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
		font-size: 15px;
		padding-top: 7px;
		padding-left: 8px;
		border-radius: 5px;
	}

	.busq_profesores_ciudad .bootstrap-select {
		border-radius: 5px;
	}

	.busq_profesores_ciudad p {
		display: inline-block;
		padding-right: 20px;
	}

	.busq_profesores_ciudad .cont_select {
		display: inline-block;
		width: 200px;
	}

	.select_tematica {
		float: left;
		max-width: 280px;
		width: 280px;
	}

	.select_tematica select {
	}

	.curso_profesor {
		overflow: hidden;
	}

	.prof_c_p_titulo {
		font-size: 18px;
		line-height: 22px;
		padding-bottom: 1px;
	}

	.prof_c_p_2,
	.prof_c_p_3 {
		float: left;
		width: 50%;
	}

	.prof_c_p_tematica {
		height: 66px;
	}

	.prof_c_p_nivel,
	.prof_c_p_valoracion {
		height: 70px;
	}

	.prof_p_p_altura {
		height: 85px;
	}

	.prof_plazas {
		padding-top: 8px;
	}

	/*---------Fin Profesores---------*/
	/*---------Porque foxize---------*/
	.equipo_profesor {
		margin-bottom: 30px;
	}

	.equipo img {
		width: 100%;
		margin-left: 0;
	}

	.equipo > p {
		position: absolute;
		text-align: center;
		top: 10px;
		width: 100%;
		font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
		font-size: 20px;
		color: #fff;
	}


	.nuestros_principios {
		padding: 30px;
	}

	.nuestros_principios .num {
		float: left;
		margin: 0 15px 0 0;
	}

	/*---------Fin Porque foxize---------*/
	/*---------Compra---------*/
	.compra {
		margin-top: 20px;
	}

	.lista_compra li {
		padding: 20px 10px;
	}

	.compra .datos {
		width: 480px;
		padding-right: 20px;
		display: inline-block;
	}

	.compra .total {
		padding-right: 48px;
	}

	.mensage {
		width: 80%;
		margin: 70px auto;
	}

	.codigo_descuento {
		font-size: 14px;
		padding-right: 10px;
	}

	.compra .total,
	.compra .total2 {
		padding-right: 10px;
	}

	.precio_final {
		text-align: right;
	}

	.condiciones_pago > div {
		max-width: 400px;
		margin-top: 50px;
	}

	.datos_particular,
	.datos_empresa {
		margin-left: 45px;
	}

	/*---------Fin compra---------*/
	/*---------Biblioteca---------*/
	.busq_biblioteca {
		padding: 15px 10px;
		margin-bottom: 30px;
	}

	.busq_biblioteca .bootstrap-select {
		margin-bottom: 0;
		margin-right: 0;
		height: 40px;
	}

	.busq_biblioteca .bootstrap-select > .dropdown-toggle {
		height: 40px;
		border-color: #dfe1e1;
		background-color: #F6F8F8;
		font-family: 'robotolight', Arial, Helvetica, sans-serif !important;
		font-size: 15px;
		padding-top: 7px;
		padding-left: 8px;
		border-radius: 5px;
	}

	.busq_biblioteca .bootstrap-select {
		border-radius: 5px;
	}

	.busq_biblioteca p {
		width: 100%;
		display: inline-block;
		padding-right: 20px;
	}

	.busq_biblioteca .cont_select {
		display: inline-block;
		width: 200px;
		margin-right: 8px;
	}

	.check_add_favoritos_biblioteca {
		max-width: 330px;
		padding: 15px;
	}

	.check_add_favoritos_biblioteca label {
		font-size: 14px;
	}

	/*---------Fin Biblioteca---------*/
	/*---------Libros---------*/
	.catalogo_libros {
		padding: 70px 0 20px 0;
	}

	.conseguir_resumen p {
		font-size: 16px;
		width: 92%;
		margin: 0 auto;
	}

	.descarga_resumen img {
		float: left;
		margin-right: 20px;
		margin-bottom: 0;
	}

	.descarga_resumen {
		text-align: inherit;
	}

	.descarga_resumen p {
		line-height: 20px;
	}

	.descarga_resumen .titulo_libro {
		min-height: 58px;
	}

	.descarga_resumen .input-group {
		margin: 20px 0 0 0;
	}

	/*---------Fin Libros---------*/
	/*---------Update---------*/
	.apuntate_update p {
		font-size: 16px;
		margin: 0 auto;
	}

	.apuntate_update label {
		width: 200px;
		display: inline-block;
		margin: 15px 0 0 0;
	}

	.apuntate_update .form-group label:nth-child(1),
	.apuntate_update .form-group label:nth-child(2) {
		margin-right: 10px;
	}

	/*---------Fin Update---------*/
	/*---------404---------*/
	.text_404 {
		padding-top: 30px;
	}

	.text_404 p:nth-child(1) {
		font-size: 40px;
		line-height: 50px;
	}

	.text_404 p:nth-child(2) {
		font-size: 30px;
	}

	.text_404 p:nth-child(3) {
		font-size: 20px;
	}

	/*---------Fin 404---------*/
	/*---------Mi cuenta---------*/
	.f-perfil label {
		line-height: 45px;
		text-align: right;
	}

	.perfil_redes label {
		float: inherit;
		display: inline-block;
		width: 150px;
	}

	.perfil_redes input {
		float: inherit;
		display: inline-block;
		width: 66%;
		max-width: 400px;
	}

	.guardar_cambios {
		margin-left: 154px;
	}

	.desple_menu_cuenta {
		margin-top: 0;
		display: block;
	}

	.tematica_curso {
		overflow: hidden;
		padding-top: 20px;
	}

	.tematica_curso ul {
		display: block;
		float: left;
		width: 100%;
	}

	.tematica_curso a {
		float: left;
		width: 100%;
		pointer-events: none;
		cursor: default;
		line-height: 10px;
	}

	.lista_tematica {
		margin: 0;
	}

	.list_tematicas .tematica_curso {
		border-top: solid 1px #f2f2f2;
		margin: 0 20px 10px 20px;
	}

	.list_tematicas .tematica_curso:nth-child(1) {
		border-top: none;
	}

	.lista_tematica li {
		float: left;
		margin-right: 20px;
	}

	.btn_privacidad_contrasenya {
		padding-left: 50px;
	}

	.banners_datos_3 li {
		float: left;
		width: 33.3%;
		border-right: solid 1px #DFE1E1;
		border-bottom: none;
	}

	.banners_datos_2 li {
		float: left;
		width: 50%;
		border-right: solid 1px #DFE1E1;
		border-bottom: none;
	}

	.banners_datos_3 li:nth-child(3) {
		border-right: none;
	}

	.banners_datos_2 li:nth-child(2) {
		border-right: none;
	}

	.banners_datos_4,
	.banners_datos_4_1 {
		padding: 20px;
	}

	.banners_datos_4 li:first-child,
	.banners_datos_4_1 li:first-child {
		padding-top: 20px;
	}

	.banners_datos_4 li,
	.banners_datos_4_1 li {
		float: left;
		width: 50%;
		border-right: solid 1px #DFE1E1;
	}

	.banners_datos_4 li:nth-child(3),
	.banners_datos_4 li:nth-child(4),
	.banners_datos_4_1 li:nth-child(3),
	.banners_datos_4_1 li:nth-child(4) {
		border-bottom: none;
	}

	.banners_datos_4 li:nth-child(4),
	.banners_datos_4 li:nth-child(2),
	.banners_datos_4_1 li:nth-child(4),
	.banners_datos_4_1 li:nth-child(2) {
		border-right: none;
	}

	.banners_datos_6 {
		padding: 20px;
	}

	.banners_datos_6 li:first-child {
		padding-top: 20px;
	}

	.banners_datos_6 li {
		float: left;
		width: 50%;
		border-right: solid 1px #DFE1E1;
	}

	.banners_datos_6 li:nth-child(5),
	.banners_datos_6 li:nth-child(6) {
		border-bottom: none;
	}

	.banners_datos_6 li:nth-child(2),
	.banners_datos_6 li:nth-child(4),
	.banners_datos_6 li:nth-child(6) {
		border-right: none;
	}

	.cont_btn_desc_informe {
		float: left;
		width: inherit;
	}

	.cont_btn_desc_informe2 {
		text-align: right;
	}

	.select_mes_anyo {
		height: 105px;
	}

	.banners3 {
		text-align: center;
		padding-top: 30px;
	}

	.banners3 li {
		width: 32.6%;
		padding: 20px 15px;
		border-bottom: none;
		border-left: solid 1px #F2F4F4;
		margin-bottom: 30px;
	}

	.banners3 li:first-child {
		border: none;
	}

	.cont-tablas section:nth-child(1),
	.cont-tablas section:nth-child(2) {
		width: 230px;
	}

	.cont-tablas section:nth-child(1) {
		margin-right: 10px;
	}

	.cont-tablas section:nth-child(2) {
		margin-right: 0;
	}

	.cont-tablas section:nth-child(3) {
		width: 100%;
	}

	.cont-tablas section:nth-child(3) ul {
		width: 200px;
		float: left;
	}

	.menu_suscripcion li {
		width: 33%;
		float: left;
	}

	.menu_suscripcion li a {
		padding: 10px 15px;
	}

	.tabla_preferencias .segundo {
		margin-top: 10px;
	}

	/*---------Fin Mi cuenta---------*/
	/*---------Bono F5---------*/
	.titul_cabecera_imagen {
		font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
		font-size: 44px;
		line-height: 48px;
	}

	.subtitul_cabecera_imagen {
		font-size: 18px;
	}

	.comprar_f5 {
		padding-bottom: 30px;
	}

	.comprar_f5 p:nth-child(1) {
		margin-top: 20px;
		font-size: 22px;
	}

	.comprar_f5 p:nth-child(2) {
		margin-bottom: 30px;
	}

	.activar_f5 p:nth-child(1) {
		margin-top: 10px;
		font-size: 20px;
	}

	.activar_f5 p:nth-child(2) {
		margin-bottom: 30px;
	}

	/*---------Fin Bono F5---------*/
	/*---------Unlimited---------*/
	.titul_unlimited {
		font-size: 36px;
		line-height: 40px;
	}

	.precios_unlimited header {
		font-size: 24px;
		padding: 30px 0;
	}

	.precios_unlimited .btn-green {
		width: 180px;
	}

	/*---------Fin Unlimited---------*/
	/*---------ebook---------*/
	.titul_ebook {
		font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
		font-size: 42px;
		margin-top: 30px;
	}

	.subtitul_ebook {
		font-size: 20px;
		margin-bottom: 60px;
	}

	.ebook img {
		max-width: 100%;
	}

	.ebook .titulo {
		min-height: 80px;
	}

	.ebook .texto {
		min-height: 150px;
	}

	.margen_mobile {
		margin-bottom: 0;
	}

	/*---------Fin ebook---------*/

}

@media (min-width: 768px) and (max-width: 991px) {

	/*---------modulo curso ---------*/
	.modulo-curso .precio {
		font-size: 18px;
	}

	.modulo-curso .cont-stars {
		width: 46%;
	}

	/* deprecated
	.modulo-curso .favorito {
		width: 20%;
	}*/

	/*---------Fin modulo curso ---------*/
	/*---------Footer ---------*/
	.suscrito {
		font-size: 13px;
		left: 8px;
		line-height: 16px;
		background-image: url(../img/ok.svg);
		background-repeat: no-repeat;
		background-position: left center;
		background-size: 23px;
		padding-left: 30px;
	}

	/*---------Fin footer---------*/
	.alto_cabecera_imagen {
		height: 250px;
		min-height: 250px;
	}
	.alto_cabecera_imagen_con_breadcrumb {
		height: 204px;
		min-height: 204px;
	}

}

@media (min-width: 900px) and (max-width: 991px) {
	/*---------Cabecera---------*/
	.logo {
		width: 185px;
		margin-left: 0px;
		text-align: left;
	}
	/*---------Fin Cabecera---------*/
}


/*------------------------------------------------------- MD ------------------------------------------------------------------*/
@media (min-width: 992px) {

	.cabecera_blanca {
		min-height: 270px;
	}

	.cont_menu_sub > p a {
		display: none;
	}

	.menu_sub {
		display: flex;
	}

	.cont_menu_sub {
		background-color: inherit;
		padding: 0;
	}

	/*---------cookies---------*/
	.cookies {
		font-size: 17px;
		padding-right: 80px;
	}

	.cerrar_cookies {
		position: absolute;
		top: 20px;
		right: 20px;
		text-align: center;
		line-height: 20px;
		font-size: 22px;
		float: right;
		width: 70px;
		height: 30px;
		background-color: #F23514;
		/*border-radius: 50%;*/
	}

	/*---------Fin cookies---------*/
	/*---------Espacios (margenes)---------*/
	.espacio-bottom-bloque1 {
		margin-bottom: 20px;
	}

	.espacio-bottom-bloque2 {
		margin-bottom: 40px;
	}

	.espacio-bottom-bloque3 {
		margin-bottom: 30px;
	}

	/*---------Espacios (margenes)---------*/
	/*---------Titulos---------*/
	.titulo_2 {
		font-size: 28px;
		line-height: 32px;
		margin-bottom: 30px;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
	}

	.titulo_6 {
		font-size: 72px;
		line-height: 76px;
		margin-bottom: 30px;
	}

	.titulo_7 {
		font-size: 72px;
		line-height: 76px;
		margin-bottom: 30px;
	}

	.titulo_8 {
		font-size: 72px;
		line-height: 76px;
		margin-bottom: 30px;
	}

	.subtitulo_1 {
		width: 80%;
	}

	.subtitulo_2 {
		margin-bottom: 70px;
		font-size: 20px;
	}

	.subtitulo_3_1 {
		margin-bottom: 80px;
	}

	/*---------Fin Titulos---------*/
	/*---------Cabecera---------*/
	.logo {
		width: 130px;
		text-align: left;
		margin-left: 60px;
	}

	.acceso {
		border-right: solid 1px #f2f4f4;
		padding-left: 0;
		text-align: center;
	}

	.buscador i {
		font-size: 18px;
		color: #A5A6A8;
		line-height: 68px;
	}

	.busc {
		width: 250px;
		margin: 15px auto;
		background-image: url(../img/zoom.svg);
		background-repeat: no-repeat;
		background-size: 16px;
		background-position: 10px center;
	}

	.img-usuario,
	.log {
		width: 80px;
	}

	.buscador { /* tiene que estar por debajo de .log para que este no le cambie la anchura */
		width: 275px;
	}

	/*  Desplegables */
	.desple-mi-cuenta:after, .desple-mi-cuenta:before {
		left: 148px;
	}

	.desple-notificaciones:after, .desple-notificaciones:before {
		left: 328px;
	}

	.desple-carrito:after, .desple-carrito:before {
		left: 370px;
	}

	/* Fin Desplegables */
	/*---------Fin Cabecera---------*/
	/*---------Slider testimoniales---------*/
	#owl-slider-testimoniales .owl-wrapper-outer {
		background-size: 113%;
	}

	#owl-slider-testimoniales .item {
		height: 419px;
	}

	/*---------Fin Slider testimoniales---------*/
	/*---------Slider home---------*/
	#owl-slider-home .item {
		height: 412px;
	}

	#owl-slider-home ul li {
		display: inline-block;
		font-size: 25px;
		line-height: 25px;
		padding: 5px 30px 0 30px;
		margin-bottom: 40px;
	}

	#owl-slider-home ul li:nth-child(2) {
		border-left: solid 1px #fff;
		border-right: solid 1px #fff;
	}

	.t-s {
		font-size: 13px;
	}

	.titu-slider {
		margin-top: 30px;
		font-size: 48px;
		line-height: 48px;
	}

	.sub-titu-slider {
		font-size: 20px;
		line-height: 30px;
	}

	.btn-slider {
		font-size: 18px;
		padding: 14px 40px;
		margin: 30px 0 35px 0;
	}

	/*---------Fin Slider home---------*/
	/*---------Slider home login---------*/
	#owl-slider-home-login .item {
		height: 270px;
		padding-top: 50px;
	}

	/*---------Fin Slider home login---------*/
	/*---------Destacado update---------*/
	.destacado_update {
		margin-top: 0;
		height: 270px;
	}

	/*---------Fin Destacado update---------*/
	/*---------Bloque Por donde Empiezo---------*/
	.empiezo > .edit {
		width: 246px;
		padding-left: 20px;
	}

	/*---------Fin Bloque Por donde Empiezo---------*/
	/*---------Bloque Temáticas---------*/
	.tematicas ul li {
		height: 170px;
	}

	.tematicas ul li span {
		width: 120px;
		height: 120px;
		border-radius: 60px;
	}

	.tematicas ul i {
		line-height: 120px;
	}

	.tematicas ul li h3 {
		font-size: 16px;
		line-height: 18px;
		padding: 0 10%;
	}

	/*---------Fin Bloque Temáticas---------*/
	/*---------Bloque Asesoramiento---------*/
	.asesoramiento .form-group label {
		max-width: 210px;
		width: 210px;
	}

	.interno .asesoramiento .form-group {
		min-height: 136px;
	}

	.asesoramiento .form-group {
		min-height: 86px;
	}

	.asesoramiento .form-group label:nth-child(2n+1) {
		margin-right: 0;
		margin-left: 0;
	}

	.asesoramiento .form-group label:nth-child(1),
	.asesoramiento .form-group label:nth-child(2),
	.asesoramiento .form-group label:nth-child(3) {
		margin-bottom: 20px;
		margin-right: 23px;
	}

	.asesoramiento hr {
		margin: 20px auto 20px auto;
	}

	.interno .asesoramiento .form-group label:nth-child(1),
	.interno .asesoramiento .form-group label:nth-child(2),
	.interno .asesoramiento .form-group label:nth-child(3),
	.interno .asesoramiento .form-group label:nth-child(4) {
		max-width: 280px;
		width: 280px;
	}

	.asesoramiento_ok {
		padding-top: 100px;
		height: 221px;
		background-position: center 70px;
	}

	.column_asse_regis {
		padding-top: 20px;
	}

	.column_asse_regis label {
		margin-left: auto;
		margin-right: auto;
	}

	.column_asse_regis > li:nth-child(1) {
		border-right: solid 1px #f2f4f4;
	}

	/*---------Fin Bloque Asesoramiento---------*/
	/*---------Bloque PorQue Foxize---------*/
	.modulo-PQFoxize1, .modulo-PQFoxize2, .modulo-PQFoxize3 {
		padding-left: 15px;
		padding-right: 15px;
	}

	.line-right {
		background-size: 1px 200px;

	}

	.PQFoxize .cont-btn {
		margin: 50px 0 20px 0;
	}

	/*---------Fin Bloque PorQue Foxize---------*/
	/*---------Footer---------*/
	.espacio_before_footer {
		margin-bottom: 70px;
	}

	.espacio_before_footer2 {
		margin-bottom: 40px;
	}

	.contacto .width170 {
		width: 130px;
	}

	.newsletter-redes-empresa {
		margin-top: 0;
	}

	.colum-newsletter, .colum-redes, .colum-empresa {
		width: 100%;
	}

	.newsletter-redes-empresa .form-group {
		max-width: 100%;
		margin-bottom: 20px;
	}

	.colum-empresa img {

}
.metodos-pago {
	width: auto;
	float: right;
}
.tarjetas {
	margin-right: 30px;
}
.logo-enisa {
	margin-right: 50px;
}
.menu-footer-azul {
	width: 840px;
}
.menu-footer-azul ul {
	width: 640px;
}
.colum-empresa .btn-small {
	font-size: 14px;
}
/*---------Fin Footer---------*/


/*---------Registro---------*/
.margin90 {
	margin-left: 90px;
	margin-right: 90px;
}
.registro {
	width: 750px;
	padding: 20px 0;
}
.registro-ok {
	width: 750px;
	padding: 20px 90px;
}
.form-registro {
	position: relative;
}
.nombre_campos {
	font-size: 15px;
	width: 180px;
	position: absolute;
	left: -200px;
	top: 0;
	text-align: right;
}
.nombre_campos li {
	line-height: 45px;
	margin-bottom: 15px;
}
.nombre_campos label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.desktop ::-webkit-input-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #999; font-size: 14px;}
.desktop ::-moz-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #999; font-size: 14px;}
.desktop :-ms-input-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #999; font-size: 14px;}
.desktop input:-moz-placeholder { font-family: 'robotolight_italic', Arial, Helvetica, sans-serif; color: #999; font-size: 14px;}

	.desktop .filter-option {
		font-family: 'robotolight_italic', Arial, Helvetica, sans-serif;
		color: #999;
		font-size: 14px;
	}

	.desktop .selected {
		display: none;
	}

	.form-registro .form-group {
		margin-bottom: 30px;
	}

	.acepto-politica label {
		width: 320px;
	}

	/*---------Fin Registro---------*/
	/*---------Mi cuenta ---------*/
	.mi-cuenta {
		margin-top: 0;
		padding: 20px;
		line-height: 22px;
		overflow: hidden;
		height: 270px;
	}

	.txt-mi-cuenta {
		float: inherit;
		width: 100%;
		height: 180px;
	}

	.mi-cuenta .btn {
		display: block;
		width: 220px;
		margin: 0 auto;
		float: inherit;
	}

	.mi-cuenta p {
		margin-bottom: 20px;
	}

	.banners_datos_6 li {
		float: left;
		width: 33%;
		border-right: solid 1px #DFE1E1;
	}

	.banners_datos_6 li:nth-child(4),
	.banners_datos_6 li:nth-child(5),
	.banners_datos_6 li:nth-child(6) {
		border-bottom: none;
	}

	.banners_datos_6 li:nth-child(3),
	.banners_datos_6 li:nth-child(6) {
		border-right: none;
	}

	.banners_datos_6 li:nth-child(2),
	.banners_datos_6 li:nth-child(4) {
		border-right: solid 1px #DFE1E1;
	}

	.table_4 .tabla_cursos_inscritos .desplegable_oculto {
		width: 680px;
		right: -332px;
	}

	.table_4 .tabla_cursos_asistidos .desplegable_oculto {
		width: 680px;
		right: -142px;
	}

	.table_4 .tabla_cursos_inscritos .desplegable_oculto:after,
	.table_4 .tabla_cursos_inscritos .desplegable_oculto:before {
		left: 37.7%;
	}

	.table_4 .tabla_cursos_asistidos .desplegable_oculto:after,
	.table_4 .tabla_cursos_asistidos .desplegable_oculto:before {
		left: 65.3%;
	}

	/*---------Fin mi cuenta ---------*/
	/*---------modulo recurso ---------*/
	.modulo-recurso {
		height: 198px;
	}

	.modulo-recurso footer {
		height: 111px;
		top: 121px;
	}

	.modulo-recurso footer h5 {
		font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 10px;
	}

	.modulo-recurso footer p {
		line-height: 19px;
		font-size: 15px;
	}

	.modulo-recurso:hover footer {
		top: 83px;
	}

	/*---------Fin modulo recurso ---------*/
	/*---------Buscador cursos ---------*/
	.filtro_rapido {
		padding: 0;
	}

	.titulo_filtro_rapido {
		font-size: 16px;
		margin-bottom: 2px;
		float: left;
		line-height: 66px;
		width: 140px;
		margin-left: 13px;
	}

	.filtro_rapido .selct_busc > li {
		padding: 13px 0;
	}

	.filtro_rapido .selct_busc > li:nth-child(3) {
		margin-right: 13px;
	}

	.modalidad {
		min-height: 50px;
	}

	.m-l {
		margin-top: 5px;
		margin-right: 15px;
	}

	.modalidad .selct_busc,
	.modalidad .selct_busc > li {
		float: left;
	}

	.modalidad .selct_busc > li {
		min-width: 200px;
		margin-right: 15px;
	}

	/*---------Fin Buscador cursos ---------*/
	/*---------Ficha curso ---------*/
	.check_add_favoritos label {
		font-size: 15px;
	}

	#owl-alumnos .item ul li {
		margin: 10px 3px 13px 3px;
	}

	.titulo_3 {
		font-size: 42px;
		line-height: 46px;
		margin-bottom: 20px;
	}

	.titulo_2_2 {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 10px;
	}

	.ficha_curso {
		font-size: 15px;
	}

	.intro_curso {
		font-size: 16px;
	}

	.dc li {
		width: 20%;
	}

	.dc li:nth-child(2n+1) {
		/*border-right: inherit;*/
	}

	.dc li:nth-child(1),
	.dc li:nth-child(2),
	.dc li:nth-child(4),
	.dc li:nth-child(5) {
		border-right: solid 1px #DFE1E1;
	}

	.df {
		border-top: solid 1px #DFE1E1;
	}

	.dt_left {
		width: 347px;
		float: left;
	}

	.datos_top {
		height: 246px;
	}

	.datos_curso .profesor {
		width: 230px;
		float: right;
		border-top: none;
		border-left: solid 1px #DFE1E1;
		text-align: center;
		overflow: hidden;
		margin: 0 -20px 0 0;
		padding-top: 20px;
	}

	.datos_curso .profesor p:first-child {
		height: 20px;
	}

	.datos_curso .profesor .nombre {
		height: 20px;
	}

	.datos_curso .profesor .cargo {
		height: 41px;
	}

	.df .centra_verti {
		height: 90px;
	}

	.btn-twitter,
	.btn-facebook2,
	.btn-linkedin,
	.btn-correo {
		width: 111px;
		height: 30px;
		line-height: 30px;
		padding-right: 10px;
		background-size: 15px;
		background-position: 7px center;
	}

	.btn-correo {
		width: 98px;
	}

	.btn-facebook2 {
		background-size: 7px;
	}

	.comentario {
		width: 505px;
	}

	.comentario_2 {
		width: 435px;
	}

	.head_sidebar > div span {
		font-size: 14px;
	}

	.fecha_lugar_curso li {
		padding-left: 35px;
	}

	.pertenece_programa {
		font-size: 16px;
	}

	.apuntarme > div span {
		font-size: 14px;
		margin: 30px 0 0 -50px;
	}

	.head_sidebar > div span {
		top: 30px;
		left: -40px;
	}

	.cuartos {
		width: 515px;
	}

	.titu_datos_curso {
		font-size: 24px;
	}

	.cabecera_titulo {
		font-size: 36px;
		line-height: 40px;
		padding-bottom: 40px;
	}

	.cabecera_titulo p {
		font-size: 16px;
	}

	.cabecera_titulo h1 span {
		font-size: 38px;
	}

	.img_partner {
		position: absolute;
		right: 0;
		top: 0;
		width: 230px;
		height: 100%;
		border: none;
		border-left: solid 1px #DFE1E1;
	}

	.img_partner img {
		position: absolute;
		width: 150px;
		top: 50%;
		right: 40px;
		transform: translate(0, -50%);
		-webkit-transform: translate(0, -50%);
	}

	.espacio_img_partner {
		padding-right: 270px;
	}

	/*---------Fin Ficha curso ---------*/
	/*---------Itinerario ---------*/
	.itinerario {
		background-image: url(../img/punto_gris.gif);
		background-repeat: repeat-y;
		background-position: 776px;
	}

	.img_itinerario {
		float: left;
		width: 300px;
	}

	.descripcion_itinerario {
		float: left;
		width: 470px;
		padding: 20px 0 0 20px;
	}

	.separacion_itinerario {
		display: none;
	}

	.disponibilidad_itinerario {
		float: left;
		width: 170px;
		padding: 20px;
	}

	.disponibilidad_itinerario li {
		float: left;
		margin-left: 30px;
	}

	.disponibilidad_itinerario ul {
		padding-left: 0;
	}

	/*---------Fin Itinerario ---------*/
	/*---------Temática ---------*/
	.tematica {
		height: 460px;
	}

	/*---------Fin Temática ---------*/
	/*---------Planes precio ---------*/
	.cuerpo_lista_precio {
		min-height: 430px;
	}

	/*---------Fin Planes precio ---------*/
	/*---------Como funciona ---------*/
	.como_funciona_fxz_unlimited,
	.como_funciona_bono_f5 {
		margin-top: 15px;
	}

	.como_funciona_fxz_unlimited {
		padding-left: 10px;
		padding-right: 30px;
	}

	.como_funciona_bono_f5 {
		padding-right: 10px;
		padding-left: 30px;
	}

	.mt_espcial {
		margin-top: 83px;
	}

	/*---------Fin Como funciona ---------*/
	/*---------Regala formación---------*/
	.regala_formacion {
		font-size: 15px;
	}

	.regala_formacion h6 {
		font-size: 23px;
		line-height: 27px;
		float: left;
		width: 100%;
	}

	.regala_formacion img {
		width: 390px;
		float: left;
	}

	.regala_formacion p {
		float: left;
		width: 100%;
	}

	.regala_formacion article {
		float: left;
		width: 550px;
		padding: 15px 20px 0 20px;
		text-align: center;
	}

	.regala_formacion article a {
		float: none;
		margin: 10px auto 0 auto;
	}

	/*---------Fin Regala formación---------*/
	/*---------Upgradebox---------*/
	.upgradebox {
		min-height: 330px;
	}

	/*---------Fin Upgradebox---------*/
	/*---------Profesores---------*/
	.f-profesores {
		padding-top: 80px;
	}

	.banners_maestros li {
		padding: 20px 20px;
		min-height: 210px;
	}

	.select_tematica {
		float: left;
		max-width: 210px;
		width: 210px;
	}

	.busc_profesores_nom {
		float: right;
	}

	.busc_profesores_nom input {
		width: 250px;
	}

	/*---------Fin Profesores---------*/
	/*---------Porque foxize---------*/
	.equipo > p {
		font-size: 24px;
	}


	.principios video {
		float: left;
		width: 500px;
		vertical-align: top;
	}

	.nuestros_principios {
		float: left;
		width: 440px;
		padding: 20px;
	}

	.nuestros_principios li {
		font-size: 15px;
		margin-bottom: 9px;
	}

	.nuestros_principios p {
		float: left;
		width: 365px;
	}

	/*---------Fin Porque foxize---------*/
	/*---------Compra---------*/
	.compra .datos {
		width: 375px;
	}

	/*---------Fin Compra---------*/
	/*---------Contacto---------*/
	.f_contacto input {
		width: 340px;
		margin-bottom: 18px;
	}

	.f_contacto textarea {
		height: 220px;
	}

	.f_contacto input:nth-child(1),
	.f_contacto input:nth-child(3) {
		float: left;
	}

	.f_contacto input:nth-child(2),
	.f_contacto input:nth-child(4) {
		float: right;
	}

	.f_politica {
		position: relative;
		font-family: 'robotolight', Arial, Helvetica, sans-serif;
		display: block;
		width: 230px;
		margin: 10px 15px 0 250px;
		float: left;
	}

	.footer_form .btn {
		float: left;
		display: block;
		margin: 0 0;
	}

	#map {
		height: 300px;
	}

	/*---------Fin Contacto---------*/
	/*---------Biblioteca---------*/
	.busq_biblioteca p {
		width: inherit;
	}

	.img_destacado img {
		float: right;
		width: 280px;
	}

	.txt_destacado {
		width: 330px;
		float: left;
	}

	/*---------Fin Biblioteca---------*/
	/*---------Fin Libros---------*/
	.centrado {
		width: 760px;
	}

	.descarga_resumen .titulo_libro {
		min-height: 38px;
	}

	/*---------Fin Libros---------*/
	/*---------Update---------*/
	.update {
		width: 400px;
		float: left;
	}

	.listado1 {
		margin-left: 27px;
	}

	.listado2 li:first-child {
		border: none;
	}

	.listado2 {
		margin-left: 47px;
	}

	.apuntate_update p {
		width: 70%;
	}

	.apuntate_update label {
		width: 250px;
	}

	/*---------Fin Update---------*/
	/*---------Mi cuenta ---------*/
	.mi_cuenta_section_1 {
		float: left;
		width: 65%;
		border-bottom: none;
		border-right: solid 1px #F2F4F4;
		margin: 0;
		padding: 0 20px;
		height: 150px;
		display: table;
	}

	.mi_cuenta_section_2 {
		padding: 0 15px 0 25px;
		float: left;
		width: 35%;
		display: table;
		height: 150px;
	}

	.mi_cuenta_section_3 {
		float: left;
		width: 100%;
		height: 150px;
	}

	.mi_cuenta_section_1_2 {
		float: left;
		width: 65%;
		border-bottom: none;
		border-right: solid 1px #F2F4F4;
		margin: 0;
		padding: 0 20px;
		height: 110px;
		display: table;
	}

	.mi_cuenta_section_2_2 {
		padding: 0 15px 0 25px;
		float: left;
		width: 35%;
		display: table;
		height: 110px;
	}

	.mi_cuenta_section_3_2 {
		float: left;
		width: 100%;
		height: 110px;
	}

	.cont_btn_desc_informe {
		float: right;
	}

	.select_mes_anyo {
		height: 45px;
	}

	@media (min-width: 560px) {
		.mi_cuenta_nota {
			width: 255px;
			float: left;
			border-right: solid 1px #F2F4F4;
		}

		.mi_cuenta_datos {
			padding: 135px 0 0 20px;
			float: left;
		}
	}
	.banners3 li {
		padding: 20px 35px;
	}

	.cont-tablas section:nth-child(1),
	.cont-tablas section:nth-child(3) {
		width: 230px;
	}

	.cont-tablas section:nth-child(2) {
		width: 217px;
		margin-right: 10px;
	}

	.cont-tablas section:nth-child(1),
	.cont-tablas section:nth-child(2),
	.cont-tablas section:nth-child(3) {
		height: 200px;
	}

	.tabla_preferencias .segundo {
		margin-top: 0;
	}

	.descuento {
		text-align: center;
		float: left;
		border-bottom: none;
		border-right: solid 1px #DFE1E1;
		padding-bottom: 0;
		margin-bottom: 0;
		margin-right: 20px;
		padding-right: 20px;
	}

	.menu_sub li {
		width: 20%;
		float: left;
	}

	.menu_sub li a {
		min-height: 70px;
	}

	/*---------Fin Mi cuenta---------*/
	/*---------Table---------*/
	/*Table1*/
	.colum1-txt-left tbody tr td:first-child {
		text-align: left;
		padding-left: 15px;
	}

	.table_1 td {
		text-align: center;

	}

	.table_1 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_1 thead tr th:nth-child(1),
	.table_1 tbody tr td:nth-child(1),
	.table_1 thead tr th:nth-child(2),
	.table_1 tbody tr td:nth-child(2),
	.table_1 thead tr th:nth-child(3),
	.table_1 tbody tr td:nth-child(3) {
		border-right: solid 1px #DFE1E1;
	}

	.table_1 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_1 tbody tr:first-child {
		border-top: none;
	}

	/*Table2*/
	.colum2-txt-left tbody tr td:first-child {
		text-align: left;
		padding-left: 15px;
	}

	.table_2 td {
		text-align: center;

	}

	.table_2 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_2 thead tr th:nth-child(1),
	.table_2 tbody tr td:nth-child(1),
	.table_2 thead tr th:nth-child(2),
	.table_2 tbody tr td:nth-child(2),
	.table_2 thead tr th:nth-child(3),
	.table_2 tbody tr td:nth-child(3) {
		border-right: solid 1px #DFE1E1;
	}

	.table_2 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_2 tbody tr:first-child {
		border-top: none;
	}

	/*Table3*/
	.table_3 td {
		text-align: center;

	}

	.table_3 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_3 thead tr th:nth-child(1),
	.table_3 tbody tr td:nth-child(1),
	.table_3 thead tr th:nth-child(2),
	.table_3 tbody tr td:nth-child(2),
	.table_3 thead tr th:nth-child(3),
	.table_3 tbody tr td:nth-child(3),
	.table_3 thead tr th:nth-child(4),
	.table_3 tbody tr td:nth-child(4),
	.table_3 thead tr th:nth-child(5),
	.table_3 tbody tr td:nth-child(5) {
		border-right: solid 1px #DFE1E1;
	}

	.table_3 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_3 tbody tr td:first-child {
		border-top: none;
		text-align: left;
	}

	/*Table4*/
	.table_4 tbody tr td:first-child,
	.table_4 thead tr th:first-child {
		text-align: left;
		padding-left: 15px;
	}

	.table_4 td {
		text-align: center;

	}

	.table_4 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_4 thead tr th:nth-child(1),
	.table_4 tbody tr td:nth-child(1),
	.table_4 thead tr th:nth-child(2),
	.table_4 tbody tr td:nth-child(2),
	.table_4 thead tr th:nth-child(3),
	.table_4 tbody tr td:nth-child(3) {
		border-right: solid 1px #DFE1E1;

	}

	.table_4 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_4 tbody tr:first-child {
		border-top: none;
	}

	/*Table5*/
	.table_5 td {
		text-align: center;

	}

	.table_5 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_5 thead tr th:nth-child(1),
	.table_5 tbody tr td:nth-child(1),
	.table_5 thead tr th:nth-child(2),
	.table_5 tbody tr td:nth-child(2),
	.table_5 thead tr th:nth-child(3),
	.table_5 tbody tr td:nth-child(3),
	.table_5 thead tr th:nth-child(4),
	.table_5 tbody tr td:nth-child(4),
	.table_5 thead tr th:nth-child(5),
	.table_5 tbody tr td:nth-child(5) {
		border-right: solid 1px #DFE1E1;
	}

	.table_5 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_5 tbody tr:first-child {
		border-top: none;
	}

	.table_5 tbody tr td:first-child {
		padding-left: 15px;
		text-align: left;
	}

	/*Table6*/
	.table_6 tbody tr td:first-child,
	.table_6 thead tr th:first-child {
		text-align: left;
		padding-left: 15px;
	}

	.table_6 td {
		text-align: center;

	}

	.table_6 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_6 thead tr th:nth-child(1),
	.table_6 tbody tr td:nth-child(1),
	.table_6 thead tr th:nth-child(2),
	.table_6 tbody tr td:nth-child(2),
	.table_6 thead tr th:nth-child(3),
	.table_6 tbody tr td:nth-child(3),
	.table_6 thead tr th:nth-child(4),
	.table_6 tbody tr td:nth-child(4) {
		border-right: solid 1px #DFE1E1;

	}

	.table_6 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_6 tbody tr:first-child {
		border-top: none;
	}

	/*Table8*/
	.table_8 td {
		text-align: center;

	}

	.table_8 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_8 thead tr th:nth-child(1),
	.table_8 tbody tr td:nth-child(1),
	.table_8 thead tr th:nth-child(2),
	.table_8 tbody tr td:nth-child(2),
    .table_8 thead tr th:nth-child(3),
    .table_8 tbody tr td:nth-child(3),
    .table_8 thead tr th:nth-child(4),
    .table_8 tbody tr td:nth-child(4),
    .table_8 thead tr th:nth-child(5),
    .table_8 tbody tr td:nth-child(5),
    .table_8 thead tr th:nth-child(6),
    .table_8 tbody tr td:nth-child(6){
		border-right: solid 1px #DFE1E1;
	}

	.table_8 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_8 tbody tr:first-child {
		border-top: none;
	}

	.table_8 tbody tr td:first-child {
		padding-left: 15px;
		text-align: left;
	}

	/*Table9*/
	.table_9 tbody tr td:first-child,
	.table_9 thead tr th:first-child {
		text-align: left;
		padding-left: 15px;
	}

	.table_9 td {
		text-align: center;

	}

	.table_9 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_9 thead tr th:nth-child(1),
	.table_9 tbody tr td:nth-child(1),
	.table_9 thead tr th:nth-child(2),
	.table_9 tbody tr td:nth-child(2) {
		border-right: solid 1px #DFE1E1;
	}

	.table_9 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_9 tbody tr:first-child {
		border-top: none;
	}

	/*Table10*/
	.table_10 td {
		text-align: center;
	}

	.table_10 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 4px;
		text-align: center;
	}

	.table_10 thead tr th:nth-child(1),
	.table_10 tbody tr td:nth-child(1),
	.table_10 thead tr th:nth-child(2),
	.table_10 tbody tr td:nth-child(2),
	.table_10 thead tr th:nth-child(3),
	.table_10 tbody tr td:nth-child(3),
	.table_10 thead tr th:nth-child(4),
	.table_10 tbody tr td:nth-child(4),
	.table_10 thead tr th:nth-child(5),
	.table_10 tbody tr td:nth-child(5),
	.table_10 thead tr th:nth-child(6),
	.table_10 tbody tr td:nth-child(6),
	.table_10 thead tr th:nth-child(7),
	.table_10 tbody tr td:nth-child(7),
	.table_10 thead tr th:nth-child(8),
	.table_10 tbody tr td:nth-child(8){
		border-right: solid 1px #DFE1E1;
	}

	.table_10 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_10 tbody tr:first-child {
		border-top: none;
	}

	/*Table11*/
	.table_11 td {
		text-align: center;
	}

	.table_11 th {
		background-color: #F2F4F4;
		font-size: 15px;
		padding: 15px 5px;
		text-align: center;
	}

	.table_11 thead tr th:nth-child(1),
	.table_11 tbody tr td:nth-child(1),
	.table_11 thead tr th:nth-child(2),
	.table_11 tbody tr td:nth-child(2),
	.table_11 thead tr th:nth-child(3),
	.table_11 tbody tr td:nth-child(3),
	.table_11 thead tr th:nth-child(4),
	.table_11 tbody tr td:nth-child(4),
	.table_11 thead tr th:nth-child(5),
	.table_11 tbody tr td:nth-child(5) {
		border-right: solid 1px #DFE1E1;
	}

	.table_11 tbody tr {
		border-top: solid 1px #DFE1E1;
	}

	.table_11 tbody tr:first-child {
		border-top: none;
	}

	.table_11 tbody tr td:nth-child(1) {
		text-align: left;
	}

	/*---------Fin Table---------*/
	/*---------404---------*/
	.text_404 p:nth-child(1) {
		font-size: 44px;
		line-height: 50px;
	}

	.text_404 p:nth-child(2) {
		font-size: 36px;
	}

	.text_404 p:nth-child(3) {
		font-size: 24px;
	}

	/*---------Fin 404---------*/
	/*---------Bono F5---------*/
	.titul_cabecera_imagen {
		font-size: 60px;
		line-height: 64px;
	}

	.subtitul_cabecera_imagen {
		font-size: 18px;
	}

	/*---------Fin Bono F5---------*/
	/*---------Unlimited---------*/
	.titul_unlimited {
		font-size: 56px;
		line-height: 62px;
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.subtitul_unlimited {
		margin-bottom: 30px;
		font-size: 20px;
	}

	.precios_unlimited header {
		font-size: 30px;
		padding: 50px 0;
	}

	.precios_unlimited .btn-green {
		width: 220px;
	}

	/*---------Fin Unlimited---------*/
	/*---------ebook---------*/
	.titul_ebook {
		font-size: 60px;
		margin-top: 40px;
	}

	.subtitul_ebook {
		font-size: 22px;
		margin-bottom: 80px;
	}

	.ebook_destacado img {
		float: left;
		max-width: 50%;
	}

	.ebook_destacado .ebook_info {
		float: right;
		width: 50%;
		height: 327px;
		display: table;
		padding: 0 20px;
	}

	.ebook .titulo {
		min-height: 80px;

	}

	.ebook .texto {
		min-height: 120px;
	}

	/*---------Fin ebook---------*/
	.alto_cabecera_imagen {
		height: 350px;
		min-height: 350px;
	}

	.alto_cabecera_imagen_con_breadcrumb {
		height: 304px;
		min-height: 304px;
	}

	@media (min-width: 1100px) {
		/*---------Cabecera---------*/
		.logo {
			margin-left: 0px;
			text-align: left;
		}

		/*---------Fin Cabecera---------*/
	}


}
/*------------------------------------------------------- LG ------------------------------------------------------------------*/
@media (min-width: 1200px) {


	.menu_sub li a {
		min-height: 35px;
	}


	/*---------Slider home---------*/
	#owl-slider-home .item {
		height: 500px;
	}
	#owl-slider-home ul li {
		display: inline-block;
		font-size: 25px;
		line-height: 25px;
		padding: 5px 30px 0 30px;
		margin-bottom: 60px;
	}
	#owl-slider-home ul li:nth-child(2) {
		border-left: solid 1px #fff;
		border-right: solid 1px #fff;
	}
	.t-s {
		font-size: 13px;
	}
	.titu-slider {
		font-size: 48px;
		line-height: 48px;
		margin-top: 80px;
	}
	.sub-titu-slider {
		font-size: 20px;
		line-height: 30px;
	}
	.btn-slider {
		padding: 14px 40px;
		margin: 30px 0 45px 0;
	}
	/*---------Fin Slider home---------*/


	/*---------Slider testimoniales---------*/
	#owl-slider-testimoniales .owl-wrapper-outer {
		background-size: 100%;
	}
	#owl-slider-testimoniales .item {
		height: 450px;
	}
	#owl-slider-testimoniales .item h2 {
		padding: 80px 0 30px 0;
	}
	#owl-slider-testimoniales .item .txt {
		width: 590px;
	}
	/*---------Fin Slider testimoniales---------*/



	/*---------Slider home login---------*/
	#owl-slider-home-login .item {
		height: 330px;
		padding-top: 70px;
	}
	/*---------Fin Slider home login---------*/

	/*---------Destacado update---------*/
	.destacado_update {
		height: 330px;
	}
	/*---------Fin Destacado update---------*/

	/*---------Titulos---------*/

	.titulo_7 {
		font-size: 90px;
		line-height: 94px;
		margin-bottom: 30px;
	}
	.titulo_8 {
		font-size: 90px;
		line-height: 94px;
		margin-top: 50px;
		margin-bottom: 30px;
	}
	.subtitulo_3_1 {
		width: 80%;
		margin-bottom: 100px;
	}
	/*---------Fin Titulos---------*/



	/*---------Cabecera---------*/
	.logo {
		width: auto;
		padding-left: 60px;
	}
	/*.acceso {
        width: 220px;
    }*/
	.carrito, .favoritos {
		width: 85px;
	}

	.busc {
		width: 264px;
	}
	.img-usuario,
	.log {
		width: 90px;
	}
	.buscador { /* tiene que estar por debajo de .log para que este no le cambie la anchura */
		float: left;
		height: 68px;
		width: 327px;
		line-height: 68px;
		text-align: center;
		border-left: solid 1px #f2f4f4;
	}

	/*  Desplegables */

	.desple-mi-cuenta:after, .desple-mi-cuenta:before {
		left: 143px;
	}
	.desple-notificaciones:after, .desple-notificaciones:before {
		left: 324px;
	}
	.desple-carrito:after, .desple-carrito:before {
		left: 366px;
	}
	/* Fin Desplegables */



	/*---------Fin Cabecera---------*/



	/*---------Bloque Por donde Empiezo---------*/
	.empiezo>.edit {
		width: 300px;
		padding-left: 20px;
	}
	.p-donde .col-xs-12:nth-child(3) {
		padding-left: 25px;
	}
	.p-donde .col-xs-12:nth-child(4) {
		padding-left: 25px;
	}
	/*---------Fin Bloque Por donde Empiezo---------*/




	/*---------Bloque Temáticas---------*/
	.tematicas ul li h3 {
		padding: 0 20%;
	}
	/*---------Fin Bloque Temáticas---------*/



	/*---------Bloque Asesoramiento---------*/
	.asesoramiento .parrafo {
		font-size: 16px;
	}
	.asesoramiento .form-group label {
		max-width: 250px;
		width: 250px;
	}
	.asesoramiento .form-group label:nth-child(1) {
		margin-left: 40px;
	}
	.asesoramiento .form-group label:nth-child(1),
	.asesoramiento .form-group label:nth-child(2),
	.asesoramiento .form-group label:nth-child(3) {
		margin-right: 10px;
	}
	.interno .asesoramiento .form-group label:nth-child(1),
	.interno .asesoramiento .form-group label:nth-child(2),
	.interno .asesoramiento .form-group label:nth-child(3),
	.interno .asesoramiento .form-group label:nth-child(4) {
		float: left;
		margin-right: 20px;
	}
	.interno .asesoramiento .form-group label:nth-child(1),
	.interno .asesoramiento .form-group label:nth-child(3) {
		margin-left: 120px;

	}
	.column_asse_regis > li:nth-child(1) {
		width: calc(33% - 10px);
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.column_asse_regis > li:nth-child(2) {
		width: calc(66% - 10px);
	}
	.column_asse_regis label {
		float: left;
		width: 190px;
		margin-left: auto;
		margin-right: 20px;
	}
	.column_asse_regis .cont-select {
		float: left;
		width: 290px;
	}
	.column_asse_regis .cont-btn {
		float: left;
		width: 100%;
	}
	/*---------Fin Bloque Asesoramiento---------*/




	/*---------Footer---------*/
	.contacto .width170 {
		width: 150px;
	}
	.link-footer-a {
		font-size: 20px;
	}
	.colum-2 li {
		margin-bottom: 33px;
	}
	.metodos-pago {
		margin-right: 100px;
	}
	.menu-footer-azul {
		width: 1030px;
	}
	.menu-footer-azul ul {
		width: 830px;
	}
	.suscrito {
		padding-left: 70px;
		background-position: 30px center;
	}
	.colum-empresa img {
		margin-top: -2px;
		margin-left: 4px;
	}
	/*---------Fin Footer---------*/





	/*---------Bloque PorQue Foxize---------*/

	.modulo-PQFoxize1, .modulo-PQFoxize2, .modulo-PQFoxize3 {
		padding-left: 20px;
		padding-right: 20px;
	}
	.line-right {
		background-size: 1px 180px;
	}

	/*---------Fin Bloque PorQue Foxize---------*/



	/*---------Login---------*/
	.soy-usuario h1 {
		text-align: center;
	}
	.nuevo h2 {
		text-align: center;
	}
	/*---------Fin Login---------*/



	/*---------Mi cuenta ---------*/
	.mi-cuenta {
		height: 330px;
	}
	.txt-mi-cuenta {
		float: inherit;
		width: 100%;
		height: 240px;
	}
	.mi-cuenta .btn {
		display: block;
		width: 220px;
		margin: 0 auto 0 auto;
		float: inherit;
	}
	.mi_cuenta_section_1,
	.mi_cuenta_section_1_2 {
		float: left;
		width: 51%;
	}
	.mi_cuenta_section_2,
	.mi_cuenta_section_2_2 {
		float: left;
		width: 26%;
	}
	.mi_cuenta_section_3,
	.mi_cuenta_section_3_2 {
		float: left;
		width: 23%;
		padding: 15px;
	}
	.valorar .star {
		position: absolute;
		width: 115px;
		margin-bottom: 30px;
		background-color: #2B2E33;
		padding-left: 10px;
		padding-right: 10px;
		left: 50%;
		margin-left: -57px;
		z-index: 499;
	}
	.valorar .star a {
		font-size: 18px;
	}
	.linea_fondo2 {
		margin-bottom: 7px;
	}
	.btn_documentacion {
		font-size: 12px;
	}
	.table_4 .tabla_cursos_inscritos .desplegable_oculto {
		width: 820px;
		right: -400px;
	}
	.table_4 .tabla_cursos_asistidos .desplegable_oculto {
		width: 820px;
		right: -172px;
	}
	.table_4 .tabla_cursos_inscritos .desplegable_oculto:after,
	.table_4 .tabla_cursos_inscritos .desplegable_oculto:before {
		left: 37.5%;
	}
	.table_4 .tabla_cursos_asistidos .desplegable_oculto:after,
	.table_4 .tabla_cursos_asistidos .desplegable_oculto:before {
		left: 65.1%;
	}

	/*---------Fin Mi cuenta ---------*/

	/*---------Buscador cursos ---------*/
	.titulo_filtro_rapido {
		width: 225px;
	}
	.filtro_rapido .selct_busc > li {

	}
	.filtro_rapido .selct_busc > li:nth-child(1) {
		margin-right: 10px;
	}
	.filtro_rapido .selct_busc > li:nth-child(1),
	.filtro_rapido .selct_busc > li:nth-child(2) {
		width: 190px;
	}
	.filtro_rapido .selct_busc > li:nth-child(3) {
		width: 180px;
		padding-left: 20px;
		margin-left: 17px;
		border-left: solid 1px #A5A6A8;
	}
	.titu_filtro {
		float: left;
		width: 150px;
	}
	.cont_tematicas {
		float: left;
		width: calc(100% - 150px);
	}

	/*---------Fin Buscador cursos ---------*/


	/*---------Ficha curso ---------*/
	.compartir_redes_curso {
		overflow: hidden;
	}
	.compartir_redes_curso p {
		float: left;
		line-height: 30px;
		margin-bottom: 0;
		margin-right: 10px;
	}
	.redes_curso {
		margin-top: 0px;
		float: left;
		width: 480px;
	}
	.redes_curso li {
		float: left;
		margin-left: 10px;
	}
	.comentario {
		width: 640px;
	}
	.comentario_2 {
		width: 570px;
	}
	.pertenece_programa {
		padding: 15px 20px;
	}
	.pertenece_programa p {
		margin-bottom: 0;
	}
	.pertenece_programa a {
		margin-top: 0;
		display: inline-block;
		margin-left: 20px;
	}
	.cuartos {
		width: 650px;
	}
	.busq_programas_ciudad {
		padding: 15px 10px;
	}
	.busq_programas_ciudad > p {
		line-height: 40px;
		margin-right: 25px;
	}
	.busq_programas_ciudad .cont_select {
		display: inline-block;
		width: 200px;
	}
	.cont_select2 {
		display: inline-block;
		width: 450px;
		border-left: none;
		border-top: solid 1px #ccc;
		padding-right: 250px;
	}
	.busq_programas_ciudad2 > p {
		float: left;
		font-size: 14px;
		line-height: 42px;
		width: 260px;
		text-align: center;
	}
	.dt_left {
		width: 100%;
	}
	.datos_curso .profesor {
		width: 270px;
	}
	.img_partner {
		width: 240px;
	}
	.img_partner img {
		width: 150px;
		right: 45px;
	}
	.txt_resaltado {
		width: 171px;
	}
	.c_p_1 {
		float: left;
		width: 323px;
		border-right: solid 1px #ccc;
	}
	.t_p_altura {
		height: 96px;
	}
	.c_p_2 {
		width: 215px;
		border-top: none;
	}
	.c_p_3 {
		width: 170px;
		border-top: none;
	}
	/*---------Fin Ficha curso ---------*/


	/*---------Itinerario ---------*/

	.itinerario {
		background-position: 845px;
	}
	.img_itinerario {
		width: 360px;
	}
	.descripcion_itinerario {
		width: 470px;
		padding: 20px 20px 0 20px;
	}
	.disponibilidad_itinerario {
		padding: 20px 20px 20px 40px;
	}
	.disponibilidad_itinerario li {
		margin-left: 30px;
	}
	/*---------Fin Itinerario ---------*/

	/*---------Temática ---------*/
	.tematica {
		height: 380px;
	}
	.subtematica {
		height: 340px;
	}
	/*---------Fin Temática ---------*/


	/*---------Planes precio ---------*/
	.cuerpo_lista_precio {
		min-height: 380px;
	}
	/*---------Fin Planes precio ---------*/

	/*---------Como funciona ---------*/
	.mt_espcial {
		margin-top: 108px;
	}
	/*---------Fin Como funciona ---------*/



	/*---------Regala formación---------*/
	.regala_formacion article {
		width: 750px;
	}
	.regala_formacion article a {
		margin: 20px auto 0 auto;
	}
	/*---------Fin Regala formación---------*/

	/*---------Profesores---------*/
	.f-profesores {
		padding-top: 120px;
	}
	.select_tematica {
		float: left;
		max-width: 250px;
		width: 250px;
	}
	.prof_c_p_1 {
		float: left;
		width: 375px;
		border-right: solid 1px #ccc;
	}
	.prof_t_p_altura {
		height: 96px;
	}
	.prof_c_p_2 {
		width: 302px;
		border-top: none;
	}
	.prof_c_p_3 {
		width: 170px;
		border-top: none;
	}
	/*---------Fin Profesores---------*/

	/*---------Principios---------*/
	.principios video {
		width: 550px;
	}
	.nuestros_principios {
		float: left;
		width: 590px;
		padding: 20px;

	}
	.nuestros_principios li {
		font-size: 16px;
		margin-bottom: 28px;
	}
	.nuestros_principios p {
		float: left;
		width: 515px;
	}
	/*---------Fin Principios---------*/

	/*---------Compra---------*/
	.compra .datos {
		width: 510px;
	}
	/*---------Fin Compra---------*/

	/*---------Contacto---------*/
	.espacio_contact {
		margin-bottom: 50px;
	}
	.f_contacto input {
		width: 402px;
		margin-bottom: 25px;
	}
	.linea_left {
		border-left: solid 1px #DFE1E1;
		margin-left: -15px;
		padding-left: 30px;
	}
	.f_politica {
		margin: 10px 15px 0 385px;
	}
	#map {
		height: 400px;
	}
	/*---------Fin Contacto---------*/

	/*---------Biblioteca---------*/
	.img_destacado img {
		float: left;
		width: 370px;
	}
	.txt_destacado {
		float: right;
		width: 477px;
	}
	.ficha_biblioteca .col-left {
		float: left;
		width: 360px;
	}
	.ficha_biblioteca .col-right {
		float: left;
		width: 487px;
	}
	/*---------Fin Biblioteca---------*/

	/*---------Fin Libros---------*/
	.centrado {
		width: 960px;
	}
	.descarga_resumen .titulo_libro {
		min-height: 59px;
	}
	/*---------Fin Libros---------*/


	/*---------Update---------*/
	.update {
		width: 500px;
	}
	.apuntate_update p {
		width: 60%;
	}
	/*---------Fin Update---------*/


	/*---------Mi cuenta---------*/
	.linea_bottom {
		border-bottom: solid 1px #dfe1e1;
	}
	.foto_perfil {
		border-left: solid 1px #DFE1E1;
		padding-left: 20px;
	}
	.perfil_redes input {
		width: 100%;
		max-width: 400px;
	}
	.rangos_alumno li {
		width: 25%;
	}
	.rangos_alumno li:nth-child(2) .margen,
	.rangos_alumno li:nth-child(6) .margen {
		border-right: solid 1px #DFE1E1;
	}
	.banners_datos_4 li,
	.banners_datos_4_1 li {
		width: 25%;
		border-right: solid 1px #DFE1E1;
		border-bottom: none;
	}
	.banners_datos_4 li:nth-child(2),
	.banners_datos_4_1 li:nth-child(2) {
		border-right: solid 1px #DFE1E1;
	}
	.banners_datos_6 {
		padding: 20px 0;
	}
	.banners_datos_6 li {
		width: 16.6%;
		border-right: solid 1px #DFE1E1;
	}
	.banners_datos_6 li {
		border-bottom: none;
	}
	.banners_datos_6 li p {
		width: 60%;
		margin: 0 auto;
	}
	.banners_datos_6 li:nth-child(2),
	.banners_datos_6 li:nth-child(3) {
		border-right: solid 1px #DFE1E1;
	}
	.cont-tablas section:nth-child(1),
	.cont-tablas section:nth-child(2) {
		width: 240px;
		height: 170px;
		margin-right: 25px;
	}
	.cont-tablas section:nth-child(3) {
		width: 315px;
		height: 170px;
	}
	.tabla_preferencias .segundo {
		margin-top: 10px;
		padding-left: 10px;
	}
	.cont-tablas section:nth-child(3) ul {
		width: 50%;
	}
	.documentos li {
		float: left;
		width: 50%;
	}
	/*---------Fin Mi cuenta---------*/

	/*---------Bono F5---------*/

	.titul_cabecera_imagen {
		font-size: 72px;
		line-height: 74px;
	}
	.subtitul_cabecera_imagen {
		font-size: 20px;
	}
	.comprar_f5 {
		padding-bottom: 30px;
	}
	.comprar_f5 p:nth-child(1) {
		margin-top: 20px;
		font-size: 25px;
		margin-bottom: 15px;
	}
	.comprar_f5 p:nth-child(2) {
		font-size: 16px;
		margin-bottom: 30px;
	}
	.activar_f5 p:nth-child(1) {
		margin-top: 10px;
		font-size: 23px;
		margin-bottom: 15px;
	}
	.activar_f5 p:nth-child(2) {
		width: 95%;
		font-size: 16px;
		margin:0 auto 40px auto;
	}
	/*---------Fin Bono F5---------*/

	/*---------Unlimited---------*/
	.logo_landing{
		width: auto;
		margin: auto;
		margin-top: 50px;
		max-height: 60px;
	}
	.titul_unlimited {
		font-size: 77px;
		line-height: 77px;
		margin-top: 50px;
		margin-bottom: 30px;
	}
	.subtitul_unlimited {
		margin-bottom: 60px;
		font-size: 26px;
	}
	/*---------Fin Unlimited---------*/

	.filtro_extras ul {
		width: 50%;
		border-bottom: solid 1px #DFE1E1;
	}
	.filtro_extras ul:nth-child(2) {
		border-top: solid 1px #DFE1E1;
	}
	.filtro_extras ul:nth-child(2),
	.filtro_extras ul:nth-child(4) {
		border-left: solid 1px #DFE1E1;
		padding-left: 20px;
	}


	/*---------ebook---------*/

	.titul_ebook {
		font-size: 72px;
		margin-top: 80px;
	}
	.subtitul_ebook {
		font-size: 26px;
		margin-bottom: 140px;
	}
	.ebook_destacado .ebook_info {
		height: 397px;
	}
	.ebook .titulo {
		min-height: 60px;
	}
	.ebook .texto {
		min-height: 100px;
	}
	/*---------Fin ebook---------*/

}

.alto_cabecera_imagen {
	height: 350px;
	min-height: 350px;
}
.alto_cabecera_imagen_con_breadcrumb {
	height: 304px;
	min-height: 304px;
}
@media (min-width: 1320px) {

	/*---------Cabecera---------*/
	.logo {
		padding-left: 0px;
	}
	/*---------Fin Cabecera---------*/

}

/*---------Valoracion alumno---------*/
.container_valoracion {
	width: 100%;
	margin: 0 auto;
	margin-top: 35px;
	max-width: 600px;
	padding: 0 15px;
}
.linea_b {
	border-bottom: solid 1px #dfe1e1;
	margin: 0 -15px 20px -15px;
}
.cabecera_valoracion {
	margin: -50px 0 0 0;
	padding: 0 0 10px 0;
	line-height: 26px;
}
.cabecera_valoracion img {
	width: 145px;
}
.txt_opinion {
	font-size: 15px;
	margin: 0 0 20px 0;
}
.linea_valoracion {
	font-size: 15px;
	margin: 0 0 30px 0;
}
.linea_valoracion input[type=radio] {
	display: none;
}
.linea_valoracion label {
	font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
	font-size: 17px;
	min-width: 43px;
	width: auto;
	height: 43px;
	background-color: #F2F4F4;
	border: solid 1px #CCCCCC;
	text-align: center;
	line-height: 40px;
	padding: 0px 4px;
}
.linea_valoracion input[type=radio]:checked + label,
.linea_valoracion label:hover {
	background-color: #333;
	color: #fff;
}
.linea_valoracion ul {
	overflow: hidden;
}
.linea_valoracion li {
	float: left;
	margin-right: 17px;
}
.linea_valoracion li:nth-child(5) {
	margin-right: 0;
}

.linea_valoracion {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.linea_valoracion p, .linea_valoracion div ul {
	float: unset;
}
.texto_valoracion {
	width: 70%;
}
@media (max-width: 600px)
{
	.linea_valoracion {
		display: block;
		flex-direction: row;
		justify-content: space-between;
	}

	.linea_valoracion li:nth-child(5) {
		margin-right: 17px;
	}
}
.publica_comentario {
	margin: 20px 0 40px 0;
	font-family: 'robotoregular', Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 16px;
}
.publica_comentario input[type=checkbox] {
	display: none;
}
.publica_comentario label:before {
	font-family: 'Nucleo Outline', Arial, Helvetica, sans-serif;
	content: "";
	display: inline-block;
	width: 17px;
	height: 17px;
	margin: 0 4px 0 0;
	vertical-align: -4px;
	background-color: #fff;
	border: solid 1px #dfe1e1;
	border-radius: 3px;
}
.publica_comentario input[type=checkbox]:checked + label:before {
	background-image: url(../img/check_black.svg);
	background-repeat: no-repeat;
	background-position: 2px 3px;
	display: inline-block;
}
.p_comentario {
	margin: 10px 0 30px 0;
	padding: 10px;
	width: 100%;
	height: 160px;
	border: solid 1px #C8C8C8;
}
@media (min-width: 600px) {
	.container_valoracion {
		width: 100%;
		margin: 0 auto;
        margin-top: 35px;
        max-width: 600px;
		padding: 0 30px;
	}
	.linea_b {
		border-bottom: solid 1px #dfe1e1;
		margin: 0 -30px 20px -30px;
	}
	.cabecera_valoracion {
		padding: 0 0 20px 0;
	}
	.txt_opinion {
		font-size: 16px;
		margin: 0 0 30px 0;
	}
	.linea_valoracion {
		border: solid 1px #ccc;
		height: 32px;
		margin: 0 0 12px 0;
		overflow: hidden;
	}
	.linea_valoracion p {
		float: left;
		line-height: 30px;
		margin-left: 15px;
	}
	.linea_valoracion ul {
		float: right;
	}
	.linea_valoracion li {
		margin-right: 0;
	}
	.linea_valoracion label {
		font-size: 16px;
		min-width: 30px;
		width: auto;
		height: 30px;
		line-height: 30px;
		border: none;
		border-left: solid 1px #ccc;
		padding: 0px 4px;
	}
}
/*---------Fin Valoracion alumno---------*/


/* ----FICHA MATERIALES CURSO ONLINE---- */

.label-blk-foxize
{
	background-color: #000;
	color:#FFF;
}


 .breadcrumb a, footer a, .fecha_lugar_curso a
{
	color: #0b0b0b !important;
}

.multi-lang {
	position: relative;
	float: right;
	font-size: 24px;
	min-width: 61px;
	height: 68px;
	line-height: 68px;
	text-align: center;
	border-left: solid 1px #f2f4f4;
	max-width: 90px;
}

.desple-multi-lang  {
	display: none;
	position: absolute;
	width: 150px;
	top: 80%;
	right: 10%;
	background: #fff;
	z-index: 999;
	-webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
	text-align: left;
	line-height: 18px;
	font-size: 14px;
}

/* .multi-lang {
	max-width: 90px;
} */

.multilang {
	position: absolute;
	line-height: 64px;
	text-align: center;
	right: 0;
	padding-right: 37px;
	z-index: 1;
}

.multi-scroll {
	height: auto;
	max-height: 208px;
	overflow-x: auto;
	max-width: 134px;
	white-space: nowrap;
	scrollbar-color: #c3c6c9 #dcdfe3;
	scrollbar-width: thin;
}

.flecha{
	z-index: 999;
	position: absolute;
	top: 81.8%;
	left: 50%;
	transform: translate(-50%,-55%);
}

.flecha span{
	display: block;
	width: 10px;
	height: 10px;
	border-bottom: 2px solid #c3c6c9;
	border-right: 2px solid #c3c6c9;
	transform: rotate(45deg);
	animation: animate 2.5s infinite;
}

@keyframes animate {
	0%{
		opacity: 0;
		transform: rotate(45deg) translate(-20px,-20px);
	}
	50%{
		opacity: 0;
	}
	67%{
		opacity: 0;
	}
	100%{
		opacity: 1;
		transform: rotate(45deg) translate(20px,20px);
	}
}

.linea_valoracion > div > ul > li:first-child {
	display: none;
}

.control-img img{
	max-width: 100%;
	height: auto !important;
}

.equal-height{
	display: flex;
	height: 100%;
	flex-wrap: wrap;
}

.h-100{
	height: 100%;
}
.carrousel-pagination {
	display: block;
	position: relative;
}
.carrousel-pagination-pages {
	display: flex;
	position: absolute;
	right: 0;
	top: -40px;
}
.carrousel-pagination-pages li {
	margin-left: 5px;
}
.carrousel-pagination-pages li a {
	width: 50px;
	height: 10px;
	display: block;
	background-color: rgba(0,0,0,.3);
}
.carrousel-pagination-pages li.active a {
	background-color: rgba(0,0,0,.7);
}
.carrousel-pagination > li:first-child {
	position: absolute;
	left: -60px;
}
.carrousel-pagination > li:last-child {
	position: absolute;
	right: -60px;
}
.carrousel-pagination > li:first-child > a, .carrousel-pagination > li:first-child > span, .carrousel-pagination > li:last-child > a, .carrousel-pagination > li:last-child > span {
	border-radius: 0;
	background-color: rgba(0,0,0,.7);
	color:#fff;
	padding: 190px 0;
	width: 30px;
}
.carrousel-pagination > li:first-child > a > span, .carrousel-pagination > li:last-child > a > span {
	height: 0;
	display: block;
}
.carrousel-pagination i {
	font-size: 30px;
	line-height: 0;
}

.disabled-link {
	cursor: not-allowed !important;
	opacity: 0.65 !important;
}

.float-right {
	float: right;
}

.float-left {
	float: left;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}